<template>
  <div
    class="v-input v-textarea v-input--is-label-active v-input--is-dirty v-input--is-readonly theme--light v-text-field v-text-field--is-booted"
  >
    <div class="v-input__prepend-outer">
      <div>
        <v-icon>{{ icon }}</v-icon>
      </div>
    </div>
    <div class="v-input__control">
      <div class="v-input__slot">
        <div class="v-text-field__slot">
          <label
            class="v-label v-label--active theme--light"
            style="left: 0px; right: auto; position: absolute"
            >{{ title }}</label
          >
          <p
            v-html="convertMarkdownToHtml(text)"
            :style="'min-height: ' + minHeight"
          />
        </div>
      </div>
      <div class="v-text-field__details">
        <div class="v-messages theme--light">
          <div class="v-messages__wrapper"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import MarkdownIt from '../../node_modules/markdown-it';

  export default {
    components: {},
    name: 'TextAreaView',
    props: {
      title: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: 'mdi-message-text-outline'
      },
      minHeight: {
        type: String,
        default: '300px'
      }
    },
    mounted: function () {},
    methods: {
      convertMarkdownToHtml(details) {
        return new MarkdownIt().render(details);
      }
    }
  };
</script>
