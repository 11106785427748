<template>
  <v-card class="mr-5">
    <v-card-title>
      ユーザー情報
      <v-spacer />
      <v-btn color="info" @click="startEdit">パスワード変更</v-btn>
    </v-card-title>
    <v-card-text>
      <v-sheet>
        <v-container class="px-6 text-body-2">
          <template v-for="(item, key) in disp_list">
            <v-row :key="key">
              <v-col cols="3" md="6">{{ item.title }}</v-col>
              <v-col cols="9" md="6">{{ item.text }}</v-col>
            </v-row>
          </template>
        </v-container>
      </v-sheet>
    </v-card-text>
    <UserPasswordEditDialog v-model="editDialogVisible" />
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import fileOperation from '../../../utils/fileOperation';
import token from '../../../utils/token';
import UserPasswordEditDialog from './UserPasswordEditDialog';
export default {
  components: { UserPasswordEditDialog },
  data: () => ({
    name: 'userinformation',
    path: '/userinformation',
    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    disp_list: [],
    editDialogVisible: false
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    },
    editDialogVisible(val) {
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      usersList: state => state.user.usersList,
      usergroup: state => state.userGroup.usergroup,
      userConfig: state => state.userConfig.userconfig
    })
  },
  mixins: [fileOperation, token],
  methods: {
    ...mapActions([
      'fetchUserList',
      'fetchUserGroup',

      'fetchUserConfig',
      'insertUserConfig',
      'updateUserConfig'
    ]),
    init() {
      this.dispData();
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.iconSize = window.innerHeight * 0.1;
      this.calendarHeight = Math.max(this.windowSize.y - 140, 600);
      this.dialogLeft = Math.max((this.windowSize.x - 600) / 2, 0);
      let temp = this.$refs.card;
      this.cardSize = { x: temp.clientWidth, y: temp.clientHeight };

      this.selectedFullScreen = window.innerWidth < 600;
      this.editedFullScreen = window.innerWidth < 600;

      this.headers = this.getHeaders();
    },
    isMainPage() {
      return this.$route.path === '/' + this.name;
    },
    dispData() {
      const dispData = [];

      const p = [];
      p.push(this.fetchUserList());
      p.push(this.fetchUserGroup());

      Promise.all(p).then(() => {
        const user = this.usersList.find(x => x.id === this.getUserId());
        const group = this.usergroup;
        const group_names = group
          .filter(x => x.user_id.find(x => x === this.getUserId()))
          .map(x => x.group_name)
          .join(', ');

        dispData.push({ title: 'ユーザー名', text: user.login_id });
        dispData.push({
          title: '名前',
          text: user.last_name + ' ' + user.fast_name
        });
        dispData.push({
          title: '名前（フリガナ）',
          text: user.last_kana + ' ' + user.fast_kana
        });
        dispData.push({
          title: 'メールアドレス',
          text: user.e_mail
        });
        // dispData.push({ title: '電話番号（外線）', text: '099-210-7833' });
        // dispData.push({ title: '電話番号（内線）', text: '' });
        // dispData.push({ title: '電話番号（携帯）', text: '080-5243-0546' });
        // dispData.push({ title: '携帯メールアドレス', text: '' });
        dispData.push({ title: '部署', text: group_names });
        // dispData.push({ title: '役職', text: '' });
        dispData.push({ title: '社員コード', text: user.staff_code });
        // dispData.push({ title: '会社情報' });
        // dispData.push({
        //   title: '会社名',
        //   text: '株式会社ソフトウェア開発技術'
        // });
        // dispData.push({ title: '郵便番号', text: '〒 892-0825' });
        // dispData.push({
        //   title: '住所',
        //   text: '鹿児島県鹿児島市大黒町４－１１日宝いづろビル１００２号'
        // });
        // dispData.push({ title: '電話番号', text: '099-210-7833' });
        // dispData.push({ title: 'FAX番号', text: '099-210-7832' });

        this.disp_list = dispData;
      });
    },
    startEdit() {
      this.editDialogVisible = true;
    }
  }
};
</script>
