<template>
  <div>
    <v-dialog
      max-width="1200px"
      :position-y="0"
      v-model="isVisible"
      overlay-opacity="100%"
      :fullscreen="fullScreen"
      :persistent="persistent"
      scrollable
      v-resize="onResize"
    >
      <v-card
        ref="card"
        color="background_sub"
        id="info"
        flat
        v-resize="onResize"
      >
        <v-card-title class="pa-0 ma-0">
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title
              ><slot name="title">更新情報</slot></v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn @click="windowMaximizeBtnClick" v-show="!fullScreen" icon>
              <v-icon>mdi-window-maximize</v-icon>
            </v-btn>
            <v-btn @click="windowRestoreBtnClick" v-show="fullScreen" icon>
              <v-icon>mdi-window-restore</v-icon>
            </v-btn>
            <v-btn @click="windowCloseBtnClick" icon>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="px-0 ma-0 dialog-main" v-if="isVisible">
          <v-container>
            <v-row>
              <v-col cols="12" :md="disp_cols">
                <v-card
                  class="overflow-y-auto"
                  :min-height="dialogInnerCardHight"
                  :max-height="dialogInnerCardHight"
                >
                  <v-data-table
                    ref="datatable"
                    :headers="getHeaders()"
                    :items="listitem"
                    @click:row="showEvent"
                    :footer-props="{
                      'items-per-page-options': [
                        3, 5, 10, 20, 50, 100, 250, 500, -1
                      ],
                      'items-per-page-text': '行/ページ:'
                    }"
                    :items-per-page.sync="config[getItemsPerPage()]"
                    :sort-desc="true"
                    :hide-default-footer="true"
                    mobile-breakpoint="0"
                    :min-height="dialogInnerCardHight"
                    :max-height="dialogInnerCardHight"
                  >
                    <template v-slot:[`item.title`]="{ item }">
                      <div
                        style="
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          max-width: 100px;
                        "
                        class="text-caption"
                        :style="{ color: item.title_color }"
                      >
                        <v-icon v-if="isNewIconShow(item)" color="primary"
                          >mdi-new-box</v-icon
                        >
                        {{ item.title }}
                      </div>
                    </template>
                    <template v-slot:[`item.filename`]="{ item }"
                      ><div>
                        {{ item.filename }}
                      </div>
                    </template>
                    <template v-slot:[`item.directry_id`]="{ item }">
                      <div
                        style="
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          max-width: 90px;
                        "
                        class="text-caption"
                      >
                        {{ getDirectryName(item.directry_id) }}
                      </div>
                    </template>
                    <template v-slot:[`item.updatetime`]="{ item }">
                      <div
                        style="
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          max-width: 150px;
                        "
                        class="text-caption"
                      >
                        {{ getDateFormat(getUpdateTime(item)) }}
                      </div>
                    </template>
                    <template v-slot:[`item.updateuser`]="{ item }">
                      {{ getUserName(item.updateuser) }}
                    </template>
                    <template v-slot:[`item.filesize`]="{ item }">
                      {{ fileSizeFormat(item.filesize) }}
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
              <v-col cols="12" :md="data_cols">
                <v-card
                  class="overflow-y-auto"
                  :min-height="dialogInnerCardHight"
                  :max-height="dialogInnerCardHight"
                >
                  <v-card-text class="d-flex mb-n5">
                    {{ getDirectryName(selectedEvent.directry_id) }}
                    <v-spacer />
                    ID N{{ ('000' + selectedEvent.directry_id).slice(-3) }}-{{
                      ('00000000' + selectedEvent.id).slice(-6)
                    }}-{{ ('00' + 1).slice(-2) }}
                  </v-card-text>
                  <v-card-text class="d-flex my-n10">
                    <v-spacer />
                    公開日時: {{ fullDateFormat(getInsertTime(selectedEvent)) }}
                  </v-card-text>
                  <v-card-text class="d-flex my-n10">
                    <v-spacer />
                    更新日時: {{ fullDateFormat(getUpdateTime(selectedEvent)) }}
                  </v-card-text>
                  <v-card-title
                    class="mb-n3"
                    :style="{ color: selectedEvent.title_color }"
                  >
                    {{ selectedEvent.title }}
                  </v-card-title>
                  <v-divider class="mx-3"></v-divider>
                  <v-card-text>
                    <v-container class="ma-0 pa-0">
                      <p
                        v-html="convertMarkdownToHtml(selectedEvent.details)"
                      />
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-checkbox
                :outlined="getDispStyle(config.dispStyle).outlined"
                v-model="config.nextNotDispflg"
                label="次回以降表示しない"
              >
              </v-checkbox>
            </v-col>
            <v-col class="d-flex align-center">
              <v-btn color="accent" @click="closeBtnClick"> 閉じる </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { mapState, mapActions } from 'vuex';
  import dateformat from '../functions/DateFormat';
  import DateUtils from '../functions/DateUtils';
  import fileOperation from '../../utils/fileOperation';
  import token from '../../utils/token';
  import inputStyleUtils from '../../utils/inputStyleUtils';
  import directorySort from '../functions/DirectorySort';
  import scroll from '../../utils/scroll';
  import MarkdownIt from '../../node_modules/markdown-it';

  export default {
    components: {},
    data: () => ({
      name: 'newsdialog',
      config: {
        lastCheckDate: '',
        nextNotDispflg: false
      },
      dense: true,
      // isVisible: false,
      fullScreen: false,
      persistent: true,
      headers: [],
      listitem: [],
      fileindexlist: [],
      // directories: [],
      windowSize: {
        x: 0,
        y: 0
      },
      cardSize: {
        x: 0,
        y: 0
      },
      dialogInnerCardHight: 0,
      dialogLeft: 0,
      disp_cols: 4,
      data_cols: 8,
      selectedEvent: {},
      markdown: '',
      fileDatas: []
    }),
    mounted() {
      this.onResize();
    },
    created: function () {
      // ダミーの空データを設定する
      this.listitem = [{}, {}, {}];

      if (!this.initLoading) {
        this.init(false);
      }
    },
    watch: {
      isVisible(val) {
        if (val) {
          this.init(true);
        }
      },
      initLoading(val, old) {
        console.log('watch', val, old);
        if (!val) {
          this.init(false);
        }
      },
      reLoading() {
        this.reload();
      }
    },
    computed: {
      ...mapState({
        initLoading: state => state.initLoading,
        reLoading: state => state.reLoading,
        usersList: state => state.user.usersList,
        filedata: state => state.fileData.filedata,
        userConfig: state => state.userConfig.userconfig,
        newsdata: state => state.news.newsdata,
        newsDirectory: state => state.newsDirectory.newsDirectory,
        feature: state => state.feature.feature
      }),
      isVisible: {
        get() {
          return this.value;
        },
        set(val) {
          //v-modelに値を返す（なのでthis.valueも書き換わる）
          this.$emit('input', val);
        }
      }
    },
    mixins: [fileOperation, token, inputStyleUtils, scroll],
    props: {
      value: { type: Boolean, default: false }
    },
    methods: {
      ...mapActions([
        'fetchUserConfig',
        'upsertUserConfig',
        'fetchNewsData',
        'fetchNewsDirectory',
        'fetchFileData',
        'downloadFile'
      ]),
      init(isManual) {
        let p = [];
        p.push(this.fetchNewsDirectory());

        Promise.all(p).then(() => {
          let config = this.userConfig.find(
            item => item.user_id === this.getUserId()
          );

          this.config[this.getItemsPerPage()] =
            config[this.getItemsPerPage()] || 10;

          this.config.dispStyle = config.dispStyle || 1;
          this.config.user_id = config.user_id;
          this.config.lastCheckDate =
            config[`${this.name}_last_check_date`] ?? new Date('1900-01-01');
          this.config.nextNotDispflg = config[`${this.name}_next_not_dispflg`];

          // システム設定で無効に設定している場合、ダイアログは出さない
          const useNews = this.feature.some(
            x => x.enable && x.feature == 'News'
          );

          //システム設定で有効の場合のみ、手動表示または自動表示する
          this.isVisible =
            useNews && (isManual || this.isAutoShowDialog(config));

          this.dispNewsData();
        });
      },
      reload() {
        //データ取得
        this.dispNewsData();
      },
      windowMaximizeBtnClick() {
        this.fullScreen = true;
        this.onResize();
      },
      windowRestoreBtnClick() {
        this.fullScreen = false;
        this.onResize();
      },
      windowCloseBtnClick() {
        this.isVisible = false;
      },
      onResize() {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        this.dialogInnerCardHight =
          this.windowSize.y - (this.fullScreen ? 150 : 300);
      },
      getItemsPerPage() {
        return `${this.name}_items_per_page_${'main'}`;
      },
      getHeaders() {
        const directry_id = {
          text: 'カテゴリ',
          value: 'directry_id',
          width: '50px',
          sortable: false
        };
        const title = {
          text: 'タイトル',
          value: 'title',
          width: '100px',
          sortable: false
        };
        const updateuser = {
          text: '更新者',
          value: 'updateuser',
          width: '120px',
          sortable: false
        };
        const updatetime = {
          text: '更新日時',
          value: 'updatetime',
          align: 'end',
          sortable: false
        };
        // const dlcount = {
        //   text: 'DL数',
        //   value: 'dlcount',
        //   align: 'end',
        //   width: '100px'
        // };
        // const filesize = {
        //   text: '容量',
        //   value: 'filesize',
        //   align: 'end',
        //   width: '100px'
        // };
        if (this.cardSize.x < 400) {
          return [directry_id, title, updatetime];
        } else if (this.cardSize.x < 600) {
          return [directry_id, title, updatetime];
        } else if (this.cardSize.x < 1000) {
          return [directry_id, title, updateuser, updatetime];
        } else {
          return [directry_id, title, updateuser, updatetime];
        }
      },

      async showEvent(data) {
        this.selectedEvent = {};
        this.selectedEvent = JSON.parse(JSON.stringify(data));
        console.log(data);
        // ダイアログを開いたタイミングで配列に値を入れる
        const useFiles = data.files.map(x =>
          this.filedata.find(y => y.id == x)
        );

        // 前回表示した画像をアンロードする
        this.unloadFileDatas();

        // 読み込み
        this.fileDatas = await this.loadFiledata(useFiles);

        // 置き換え
        this.selectedEvent.details = this.replaceDetailURL(
          this.selectedEvent.details,
          this.fileDatas
        );

        this.selectedOpen = true;
      },
      // 読み込み
      async loadFiledata(useFiles) {
        const fileDatas = [];
        await Promise.all(
          useFiles.map(x => {
            return this.downloadFile(x).then(res => {
              const filedata = {
                url: window.URL.createObjectURL(new Blob([res.data])),
                filename: x.originalname
              };
              fileDatas.push(filedata);
            });
          })
        );
        return fileDatas;
      },
      /**
       * @text 置換対象の文字列(内容)
       * @fileDatas 置換対象を置き換えるためのデータ(添付ファイル)
       */
      replaceDetailURL(text, fileDatas) {
        // 置き換え
        fileDatas.map(x => {
          const serchword = `![` + x.filename + ']()';
          const newword = `![` + x.filename + '](' + x.url + ')';

          text = text.replaceAll(serchword, newword);
        });
        return text;
      },
      unloadFileDatas() {
        this.fileDatas.map(x => {
          window.URL.revokeObjectURL(x.url);
        });
      },
      dispDirectiesChange() {
        this.dispNewsData();
      },
      dispNewsData() {
        //ディレクトリ情報取得
        this.fetchNewsDirectory()
          .then(() => {
            const data = { directry_id: [] };

            //ディレクトリ一覧の更新
            let baseDir = this.getDispDirectories()[0];

            if (!baseDir) baseDir = { path: '-' };

            //階層の情報を取得
            data.directry_id = this.getDispDirectories()
              .filter(item => item.path.indexOf(baseDir.path) === 0)
              .map(item => item.id);

            //ファイルインデックス情報を取得
            Promise.all([
              this.fetchNewsData({
                allDisp: false
              }),
              this.fetchFileData()
            ]).then(() => {
              this.listitem = this.newsdata;
              this.selectedEvent = data;
              if (this.listitem.length > 0) {
                this.showEvent(this.listitem[0]);
              }
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      saveConfig() {
        //選択状態を保存する
        let promise = this.fetchUserConfig();
        promise = promise
          .then(() => {
            let config = this.userConfig.find(
              item => item.user_id === this.getUserId()
            );

            if (!config) {
              config = {};
              config.user_id = this.getUserId();
            }
            config[`${this.name}_last_check_date`] = this.config.lastCheckDate;
            config[`${this.name}_next_not_dispflg`] =
              this.config.nextNotDispflg;
            return this.upsertUserConfig(config);
          })
          .catch(err => {
            console.log(err);
          });
        promise.catch(err => {
          console.log(err);
        });
        return promise;
      },
      getDispDirectories() {
        // 表示ディレクトリ抽出
        const directories = [...this.newsDirectory];
        // ソート
        return directorySort.directorysort(directories);
      },
      isNewIconShow(item) {
        if (!item.updatetime) return false;
        const itemDate = DateUtils.getDate(this.getUpdateTime(item));
        const targetDate = DateUtils.addDay(DateUtils.getNow(), -7);
        //1週間前のものは新着扱い
        return itemDate > targetDate;
      },
      getDownloadLink(id) {
        let item = this.filedata.find(item => item.id === id);
        if (!item) return '';
        return process.env.VUE_APP_API_URL_FILE + '/download/' + item.filename;
      },
      getFileName(id) {
        if (!id) return '---';
        let item = this.filedata.find(item => item.id === id);
        if (!item) return '---';
        return (
          item.originalname + '(' + this.fileSizeFormat(item.filesize) + ')'
        );
      },
      getDirectryName(id) {
        if (!id) return '---';
        let item = this.getDispDirectories().find(item => item.id === id);
        if (!item) return '---';
        return item.disp_name;
      },
      getUserName(id) {
        if (!id) return '---';
        let item = this.usersList.find(item => item.id === id);
        if (!item) return '---';
        return item.disp_name;
      },
      getDateFormat(date) {
        if (!date) return '';

        return dateformat.autoFormat(date);
      },
      fullDateFormat(date) {
        return dateformat.fullFormat(date);
      },
      getFormattedPullDownDispName(disp_name) {
        const len = this.getCategoryDepthByUnderbar(disp_name);
        if (disp_name === '全て' || !len) {
          return disp_name;
        }
        let formatted_disp_name = '';
        // 表示名の先頭を_から全角スペースに置き換える(_のままだと表示した時に階層構造が分かりずらいため)
        for (let i = 0; i < len; i++) {
          formatted_disp_name += '\u{3000}';
        }
        // 元の表示名から先頭の_を消去したものを加える。
        formatted_disp_name += disp_name.slice(len);
        return formatted_disp_name;
      },
      /* フォルダの階層の深さをフォルダ名先頭のアンダーバー_の数で求める。*/
      getCategoryDepthByUnderbar(disp_name) {
        if (!disp_name) {
          return;
        }
        const ex = /^_*/g;
        const arr = disp_name.match(ex);
        if (!arr) {
          return;
        }
        return arr[0].length;
      },
      /* フォルダの階層の深さをパスのスラッシュの数で求める。*/
      getCategoryDepthBySlash(path) {
        if (!path) {
          return;
        }
        const ex = /\//g;
        const arr = path.match(ex);
        if (!arr) {
          return;
        }
        return arr.length;
      },
      // 与えられたディレクトリの親ディレクトリ情報を取得する
      getParentDetail(directory) {
        const splitPath = directory.path.split('/');
        // ルートの直下のディレクトリはそのまま返す
        if (splitPath.length > 2) {
          // パス名から、親ディレクトリ部分のパスを抽出
          const parentPath = splitPath.slice(0, splitPath.length - 1).join('/');
          // 親ディレクトリのプロパティを取得
          let parentDirectory = Object.assign(
            {},
            this.newsDirectory.find(d => d.path === parentPath)
          );
          return parentDirectory;
        } else {
          return directory;
        }
      },
      convertMarkdownToHtml(details) {
        return new MarkdownIt().render(details ?? '');
      },

      // 渡されたidをもとに、タイトルに対応する内容を取得する
      getDetailsBasedOnId(id) {
        // id と一致するデータを探す
        const matchingNewsdata = this.newsdata.find(detail => detail.id === id);

        if (matchingNewsdata) {
          // 一致するデータが見つかった場合はそれを内容欄に表示
          this.$refs.details.textContent = matchingNewsdata.title;
        } else {
          // 一致するデータが見つからなかった場合
          this.$refs.details.textContent = '';
        }
      },
      // 「ダイアログに表示している一番新しい更新日時」を取得後、saveConfigで「最終確認日」列に設定する
      closeBtnClick() {
        // this.listitem.sort(
        //   (a, b) => new Date(b.updatetime) - new Date(a.updatetime)
        // );
        if (this.listitem.length <= 0) {
          this.config.lastCheckDate = '1900-01-01';
        } else {
          this.config.lastCheckDate = this.listitem[0].updatetime;
        }
        this.saveConfig();
        this.isVisible = false;
      },
      //
      isAutoShowDialog(config) {
        const nextNotDispflg = config[`${this.name}_next_not_dispflg`] ?? false;
        const lastCheckDate = new Date(
          config[`${this.name}_last_check_date`] ?? '1900-01-01'
        );
        const maxUpdateTime = new Date(
          this.newsdata.length > 0 ? this.newsdata[0].updatetime : '1900-01-01'
        );

        if (nextNotDispflg) {
          return false;
        } else {
          if (lastCheckDate < maxUpdateTime) {
            return true;
          } else {
            return false;
          }
        }
        // if (config[`${this.name}_next_not_dispflg`]) {
        //     // 「次回以降表示しない」チェックあり
        //     // ダイアログを表示しない
        //     this.isVisible = false;
        //   } else {
        //     // 「次回以降表示しない」チェックなし
        //     // 「最終確認日」より「表示対象となるデータで一番新しい更新日時」の方が大きければダイアログを表示
        //     this.listitem.sort(
        //       (a, b) => new Date(b.updatetime) - new Date(a.updatetime)
        //     );
        //     if (
        //       this.listitem[0].updatetime >
        //       config[`${this.name}_last_check_date`]
        //     ) {
        //       // ダイアログを表示する
        //       this.isVisible = true;
        //     } else {
        //       // ダイアログを表示しない
        //       this.isVisible = false;
        //     }
        //   }
      },
      getInsertTime(eventData) {
        return eventData.inserttime < eventData.release_date
          ? eventData.release_date
          : eventData.inserttime;
      },
      getUpdateTime(eventData) {
        return eventData.updatetime < eventData.release_date
          ? eventData.release_date
          : eventData.updatetime;
      }
    }
  };
</script>
