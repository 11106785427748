<template>
  <v-card class="mr-5">
    <v-card-title>
      ユーザーグループ管理

      <v-btn color="info" class="pa-2 ml-6" @click="openCreateDialog()"
        >追加</v-btn
      >

      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="グループ検索"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <!-- ユーザーリスト表示テーブル -->
    <v-data-table
      :headers="headers"
      :items="groups"
      item-key="id"
      :search="search"
      class="ma-4"
      @click:row="onClickRow"
      :footer-props="{
        'items-per-page-options': [20, 50, 100, 250, 500, -1],
        'items-per-page-text': '行/ページ:'
      }"
      :mobile-breakpoint="0"
    >
      <template v-slot:item="{ item }">
        <tr @click="onClickRow(item)">
          <td>{{ item.group_name }}</td>
          <td>{{ getUserName(item.updateuser) }}</td>
          <td>{{ fullDateFormat(item.updatetime) }}</td>
        </tr>
      </template>
    </v-data-table>

    <dialog-frame v-model="listDialogFlag" @close="listDialogFlag = false">
      <template v-slot:title>グループメンバー</template>

      <template v-slot:dialogContent>
        <v-sheet>
          <div class="ml-2 mt-4 text-h6">{{ selectedGroup.group_name }}</div>
          <v-container class="px-6 text-body-2">
            <template v-for="(item, key) in userNames">
              <v-row :key="key + 'A'">
                <v-col cols="3" class="pr-0">{{
                  `メンバー${key + 1}：`
                }}</v-col>
                <v-col cols="9">{{ item }}</v-col>
              </v-row>
            </template>
          </v-container>
        </v-sheet>
      </template>
      <template v-slot:footer-buttons>
        <v-btn color="info" @click="openUpdateDialog">編集</v-btn>
        <v-btn color="warning" @click="deleteSelectedGroup">削除</v-btn>
        <v-btn color="accent" @click="listDialogFlag = false">キャンセル</v-btn>
      </template>
    </dialog-frame>

    <dialog-frame v-model="editDialogFlag" @form-clear="resetFormData">
      <template v-slot:title>{{
        isNewGroup ? 'グループ追加' : 'グループ更新'
      }}</template>
      <template v-slot:dialogContent>
        <v-container>
          <v-form ref="group_form">
            <v-text-field
              v-model="groupFormData.group_name"
              :rules="[rules.required]"
            >
              <template v-slot:label>
                グループ名<span style="color: red"> *</span>
              </template>
            </v-text-field>

            <div class="px-3 py-0">
              <multi-user-select-list
                :data="groupFormData.user_id"
                :label="'所属メンバー'"
                :dispNumber="true"
              />
            </div>
          </v-form>
        </v-container>
      </template>
      <template v-slot:footer-buttons>
        <v-btn
          color="info"
          @click="isNewGroup ? registerUserGroup() : updateUserGroupDetail()"
          >{{ isNewGroup ? '追加' : '更新' }}</v-btn
        >
        <v-btn color="accent" @click="closeEditDialog()">キャンセル</v-btn>
      </template>
    </dialog-frame>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import fileOperation from '../../../utils/fileOperation';
import token from '../../../utils/token';
import MultiUserSelectList from '../../components/MultiUserSelectList.vue';
import DialogFrame from './dialog_utils/DialogFrame.vue';
import dateformat from '../../functions/DateFormat';

export default {
  components: { MultiUserSelectList, DialogFrame },
  data: () => ({
    path: '/',
    search: '',
    // テーブルのヘッダー
    headers: [
      {
        text: 'グループ名',
        value: 'group_name'
      },
      { text: '更新者', value: 'updateuser' },
      { text: '更新日時', value: 'updatetime' }
    ],
    singleSelect: false,
    rules: {
      required: value => !!value || 'グループ名は必須です。',
      pathSepalatorLimit: value =>
        !/\//.test(value) || 'パスの区切り文字//は使用できません。',
      directoryStructureLimit: value =>
        !/^_+/.test(value) || 'カテゴリ名の先頭に_は使用できません。'
      // TODO: 同じパスが二つできる表示名は追加させない。
    },
    selectedGroup: {
      id: 0,
      group_name: '',
      user_id: [],
      insertuser: 0,
      inserttime: new Date(),
      updateuser: 0,
      updatetime: new Date()
    },
    groupFormData: {
      id: 0,
      group_name: '',
      user_id: [],
      insertuser: undefined,
      inserttime: new Date(),
      updateuser: undefined,
      updatetime: new Date()
    },
    groups: [],
    isNewGroup: false,
    listDialogFlag: false,
    editDialogFlag: false,
    deleteDialogFlag: false,
    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    isError: {},
    disp_list: [],
    editedFullScreen: false
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      usersList: state => state.user.usersList,
      usergroup: state => state.userGroup.usergroup,
      userConfig: state => state.userConfig.userconfig
    }),
    userNames: function () {
      if (!this.selectedGroup.id) {
        return [];
      }
      const selectedGroup = this.selectedGroup;
      return this.usersList
        .filter(x => selectedGroup.user_id.indexOf(x.id) !== -1)
        .map(x => `${x.last_name} ${x.fast_name}`);
    }
  },
  mixins: [fileOperation, token],
  methods: {
    ...mapActions([
      'fetchUserList',
      'fetchUserGroup',
      'insertUserGroup',
      'updateUserGroup',
      'deleteUserGroup',
      'fetchUserConfig',
      'insertUserConfig',
      'updateUserConfig'
    ]),
    init() {
      this.dispData();
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.iconSize = window.innerHeight * 0.1;
      this.calendarHeight = Math.max(this.windowSize.y - 140, 600);
      this.dialogLeft = Math.max((this.windowSize.x - 600) / 2, 0);
      let temp = this.$refs.card;
      this.cardSize = { x: temp.clientWidth, y: temp.clientHeight };

      this.selectedFullScreen = window.innerWidth < 600;
      this.editedFullScreen = window.innerWidth < 600;

      this.headers = this.getHeaders();
    },
    isMainPage() {
      return this.$route.path === '/' + this.path;
    },

    dispData() {
      const p = [];
      p.push(this.fetchUserList());
      p.push(this.fetchUserGroup());

      Promise.all(p).then(() => {
        this.groups = this.usergroup;
      });
    },
    getGroupDetailData() {
      if (!this.selectedGroup.id) {
        return;
      }
      const selectedGroup = this.selectedGroup;
      const groupUsers = this.usersList.filter(
        x => selectedGroup.user_id.indexOf(x.id) !== -1
      );

      return groupUsers;
    },
    startEdit() {
      console.log('🐈');
    },
    openUpdateDialog() {
      this.groupFormData = Object.assign({}, this.selectedGroup);
      this.groupFormData.user_id = Object.assign(
        [],
        this.selectedGroup.user_id
      );
      this.isNewGroup = false;
      this.listDialogFlag = false;
      this.editDialogFlag = true;
    },
    openCreateDialog() {
      this.isNewGroup = true;
      this.editDialogFlag = true;
    },

    closeEditDialog() {
      this.resetFormData();
      this.editDialogFlag = false;
    },
    updateUserGroupDetail() {
      if (!this.$refs.group_form.validate()) {
        return;
      }
      // 更新者情報の追加
      this.groupFormData.updateuser = this.getUserId();
      this.groupFormData.updatetime = new Date();
      this.updateUserGroup(this.groupFormData).then(res => {
        // 表示されているグループ情報を更新する
        this.groups = this.groups.map(g => {
          if (g.id === res.data.id) {
            g = res.data;
          }
          return g;
        });
        this.editDialogFlag = false;
      });
    },
    registerUserGroup() {
      if (!this.$refs.group_form.validate()) {
        return;
      }
      // 作成者情報の追加
      this.groupFormData.insertuser = this.getUserId();
      this.groupFormData.inserttime = new Date();
      this.insertUserGroup(this.groupFormData).then(res => {
        this.groups.push(res.data);
        this.editDialogFlag = false;
      });
    },
    deleteSelectedGroup() {
      if (!window.confirm(`このグループを削除してよろしいですか？`)) return;
      this.deleteUserGroup(this.selectedGroup).then(() => {
        this.groups = this.groups.filter(g => g.id !== this.selectedGroup.id);
        this.listDialogFlag = false;
      });
    },
    onClickRow(item) {
      this.selectedGroup = item;
      this.listDialogFlag = true;
    },
    // 入力内容と検証エラーをリセットするメソッド
    resetFormData() {
      this.$refs.group_form.reset();
    },
    getUserName(id) {
      if (!id) return '---';
      let item = this.usersList.find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    fullDateFormat(date) {
      return dateformat.fullFormat(date);
    }
  }
};
</script>
