import http from '../../utils/httpClient';

const state = {
  feature: []
};

const mutations = {
  setFeature(state, data) {
    state.feature = data;
  }
};

const actions = {
  async fetchFeature(context) {
    return await http.get('feature').then(res => {
      context.commit('setFeature', res.data);
    });
  },
  async updateFeature(context, data) {
    return await http.put(`feature/${data.id}`, data);
  },
  async insertFeature(context, data) {
    return await http.post('feature', data);
  },
  async deleteFeature(context, data) {
    await http.delete(`feature/${data.id}`);
  }
};

export default {
  state,
  mutations,
  actions
};
