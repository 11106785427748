<script>
export default {
  name: 'AutoLinkText',
  props: ['text'],
  render: function (createElement) {
    const urlRegex = new RegExp('(?=https?://\\S+)');
    var vnodes = [];
    let value = this.text;
    if (value === '') value = '\n';
    value.split('\n').forEach(line => {
      var splitText = line.split(urlRegex);
      vnodes = vnodes
        .concat(
          splitText.map(function (line, i) {
            console.log(i);
            if (urlRegex.test(line)) {
              return createElement(
                'a',
                { attrs: { href: line, target: '_blank' } },
                line
              );
            } else {
              return this._v(line);
            }
            // target="_blank"
            // if (i % 2) {
            //   return createElement('a', { attrs: { href: line } }, line);
            // } else {
            //   return this._v(line);
            //   //return createElement('span', {}, x)
            // }
          }, this)
        )
        .concat(createElement('br'));
    });
    return createElement('span', vnodes);
  }
};

// export default {
//   name: 'TextView2',
//   props: ['text'],
//   data() {
//     return {};
//   },
//   mounted() {
//     let value = this.text;
//     let bodyNode = this.$refs.body.$el;

//     let splitText = (value + '').split('\n');

//     splitText.forEach(item => {
//       let node = document.createTextNode(item);
//       bodyNode.appendChild(node);
//     });
//   },
//   methods: {
//     getFroamt() {
//       let value = this.text;
//       let htmltxt = '';
//       // const pattern2 = new RegExp('(\\n|\\r)');

//       const matchedURL = value.match(urlRegex({ exact: false }));
//       console.log(matchedURL);

//       const pattern = new RegExp('https?:\\/\\/\\S+');

//       let splitText = (value + '').split('\n');

//       splitText.forEach(item => {
//         //URLの記載があるか
//         let aa = item.split(pattern);
//         console.log(aa);
//         htmltxt += item + '<br/>';
//       });

//       return htmltxt;
//     },
//     render(createElement) {
//       const pattern = new RegExp(
//         "(https?:\\/\\/[\\w!?\\/\\+\\-_~=;\\.,*&@#$%\\(\\)\\'\\[\\]]+)"
//       );
//       var a = (this.text + '').split(pattern);
//       var vnodes = a.map(function (x, i) {
//         if (i % 2) {
//           return createElement('a', { attrs: { href: x } }, x);
//         } else {
//           return this._v(x);
//           //return createElement('span', {}, x)
//         }
//       }, this);
//       return createElement('span', vnodes);
//     }
//   }
// };
</script>