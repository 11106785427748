//
https://proglearn.com/2020/02/29/%E3%80%90%E3%82%B5%E3%83%B3%E3%83%97%E3%83%AB%E4%BB%98%E3%81%8D%E3%80%91vuetify-%E3%81%A7-datepicker%E4%BB%98%E3%81%8D%E3%81%AE-%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB/
<template>
  <v-menu v-model="menu" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-btn icon color="primary" dark elevation="0" v-on="on">
        <v-icon>mdi-calendar</v-icon>
      </v-btn>
    </template>
    <v-date-picker
      v-model="picker"
      locale="ja-jp"
      :day-format="date => new Date(date).getDate()"
      @click="menu = false"
    />
  </v-menu>
</template>
<script>
  export default {
    props: {
      value: {
        type: String,
        default: new Date().toISOString().substr(0, 10)
      }
    },
    data() {
      return {
        menu: false
      };
    },
    computed: {
      picker: {
        get() {
          return this.value;
        },
        set(val) {
          this.menu = false;
          this.$emit('input', val);
        }
      }
    }
  };
</script>
