import http from '../../utils/httpClient';

const state = {
  holidayData: []
};

const mutations = {
  setHolidayData(state, holidayData) {
    state.holidayData = holidayData;
  }
};

const actions = {
  async fetchHolidayData(context) {
    await http
      .get('holidaydata')
      .then(holidayData => context.commit('setHolidayData', holidayData.data));
  }
};

export default {
  state,
  mutations,
  actions
};
