import http from '../../utils/httpClient';

const state = {
  push_public_key: ''
};

const mutations = {
  setPushPublicKey(state, value) {
    state.push_public_key = value?.public_key || '';
  },
  setWorkflowTitle(state, value) {
    state.workflow_title = value?.workflow_title || false;
  },
  setWorkflowTag(state, value) {
    state.workflow_tag = value?.workflow_tag || false;
  }
};

const actions = {
  async fetchPushPublicKey(context) {
    await http.get('systemsettings/push_public_key').then(res => {
      context.commit('setPushPublicKey', res.data);
    });
  },
  async fetchWorkflowTitle(context) {
    await http.get('systemsettings/workflow_title').then(res => {
      context.commit('setWorkflowTitle', res.data);
    });
  },
  async fetchWorkflowTag(context) {
    await http.get('systemsettings/workflow_tag').then(res => {
      context.commit('setWorkflowTag', res.data);
    });
  },
  async updateSystemSettings(content, data) {
    return await http.put('systemsettings/systemsettings', data);
  }

  /////////////////////////////////////////////////////////////
};

export default {
  state,
  mutations,
  actions
};
