<template>
  <v-card class="mr-5">
    <v-card-title>
      アカウント設定
      <v-spacer />
      <v-btn color="info" @click="startEdit">{{ disp_list.length === 0 ? '追加': '編集' }}</v-btn>
    </v-card-title>
    <v-card-text>
      <v-sheet>
        <v-container class="px-6 text-body-2">
          <template v-if="disp_list.length !== 0">
            <div v-for="(item, key) in disp_list" :key="key">
              <v-row>
                <v-col cols="3" md="6">{{ item.label }}</v-col>
                <v-col v-if="item.label.match(/パスワード/)" cols="9" md="6">******</v-col>
                <v-col v-else-if="item.label !== '認証' && item.label !== '暗号化'" cols="9" md="6"><span style="white-space: pre-wrap;">{{ item.value }}</span></v-col>
                <v-col v-else cols="9" md="6">{{ item.value ? 'あり' : 'なし' }}</v-col>
              </v-row>
            </div>
          </template>
          <template v-else>
            <span>アカウントはありません。</span>
          </template>
        </v-container>
      </v-sheet>
    </v-card-text>
    <custom-dialog v-model="editDialogVisible" :persistent="true">
      <template v-slot:title>アカウント設定</template>
      <template v-slot:body>
        <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="error.isError" dense>
          <span style="white-space: pre;">{{ error.message.join('\n') }}</span>
        </v-alert>
        <v-container v-for="(input,index) in Object.values(forms).slice(0,7)" :key="input.label">
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                v-if="input.label.match(/パスワード/)"
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon
                    ? 'mdi-message-outline'
                    : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="input.value"
                hide-details="auto"
                :append-icon="forms.mail_password.show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="forms.mail_password.show ? 'text' : 'password'"
                @click:append="forms.mail_password.show = !forms.mail_password.show"
                >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    {{ input.label }}
                  </div>
                </template>
              </v-text-field>
              <v-text-field
                v-else
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon
                    ? 'mdi-message-outline'
                    : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="input.value"
                hide-details="auto"
                :readonly="index === 0 && disp_list.length !== 0"
                :disabled="index === 0 && disp_list.length !== 0"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    {{ input.label }}
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-for="(input) in Object.values(forms).slice(7,9)" 
          :key="input.label"
          class="py-1"
          >
          <v-row class="align-center py-1" no-gutters>
            <v-col class="d-flex"
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              ">
              <v-icon
                v-if="getDispStyle(config.dispStyle).useIcon"
                left
                style="align-items: flex-start"
                >mdi-message-outline</v-icon
              >
              <div>
                <span style="font-size: 0.9em;">{{ input.label }}</span>
                  <v-radio-group 
                    row
                    v-model="input.value"
                  >
                    <v-radio
                      v-for="n in [1,0]"
                      :key="n"
                      :label="n ? `あり`: `なし`"
                      :value="n"
                    ></v-radio>
                  </v-radio-group>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-for="(input) in Object.values(forms).slice(9)" 
          :key="input.label"
          class="py-1"
        >
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-textarea
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon
                    ? 'mdi-message-outline'
                    : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="input.value"
                hide-details="auto"
                auto-grow
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    {{ input.label }}
                  </div>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="register">{{ disp_list.length === 0 ? '追加': '更新' }} </v-btn>
        <v-btn v-if="disp_list.length !== 0" color="warning" @click="deleteData"> 削除 </v-btn>
        <v-btn color="accent" @click="cancel"> キャンセル </v-btn>
      </template>
    </custom-dialog>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import token from '../../../utils/token';
import CustomDialog from '../../components/CustomDialog.vue';
import inputStyleUtils from '../../../utils/inputStyleUtils';
import scroll from '../../../utils/scroll';
export default {
  components: { CustomDialog },
  data: () => ({
    name: 'mailAccountSetting',
    path: '/mailAccountSetting',
    config: {},
    dense: true,
    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    disp_list: [],
    editDialogVisible: false,
    forms: {},
    baseForms: {
      mail_account: {
        label: 'アカウント名',
        icon: '',
        value: null
      },
      display_name: {
        label: '名前',
        icon: '',
        value: null
      },
      smtp_server_name: {
        label: '送信メールサーバー名（SMTP）',
        icon: '',
        value: null
      },
      smtp_server_port: {
        label: '送信用ポート番号（SMTP）',
        icon: '',
        value: null
      },
      pop3_server_name: {
        label: '受信メールサーバー名（POP3）',
        icon: '',
        value: null
      },
      pop3_server_port: {
        label: '受信用ポート番号（POP3）',
        icon: '',
        value: null
      },
      mail_password: {
        label: 'パスワード',
        icon: '',
        value: null,
        show: false,
      },
      is_send_auth: {
        label: '認証',
        icon: '',
        value: null
      },
      is_crypt: {
        label: '暗号化',
        icon: '',
        value: null
      },
      signature: {
        label: '署名',
        icon: '',
        value: null
      },
    },
    error: {
      isError: false,
      message: []
    }
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      usersList: state => state.user.usersList,
      userConfig: state => state.userConfig.userconfig,
      mailaccount: state => state.mailaccount.mailaccount
    })
  },
  mixins: [token, inputStyleUtils, scroll],
  methods: {
    ...mapActions([
      'fetchMailAccount',
      'updateMailAccount',
      'insertMailAccount',
      'deleteMailAccount',
      'fetchUserConfig'
    ]),
    init() {
      this.dispData();
    },
    isMainPage() {
      return this.$route.path === '/' + this.name;
    },
    dispData() {
      const p = [];
      p.push(this.fetchMailAccount(this.getUserId()));

      Promise.all(p).then(() => {
        this.disp_list =  [];
        if (this.mailaccount) {
          Object.keys(this.baseForms).forEach(key => {
            this.baseForms[key].value = this.mailaccount[key]
            // Boolean値を数値変換
            if (key.includes('is_')) {
              this.baseForms[key].value = Number(this.mailaccount[key]);
            }
          })
          this.disp_list = Object.values(this.baseForms);
        }
      });
    },
    startEdit() {
      this.forms = JSON.parse(JSON.stringify(this.baseForms));
      this.editDialogVisible = true;
      this.errReset();
    },
    inputCheck() {
      let flag = true;
      const target = Object.values(this.forms);
      this.errReset();
      // 署名以外は入力必須
      if (target.filter(x => x.label !== '署名').map(y => y.value).some(z => z === null || z.toString().trim() === '')) {
        console.log('input has empty value!!!');
        this.error.isError = true;
        // 必須かつ未入力項目名を取得
        const emptyitems = target.filter(x => x.label !== '署名' && (x.value === null || x.value.toString().trim() === '')).map(y => y.label);
        if (emptyitems.some(x => x !== '認証' && x !== '暗号化')) {
          this.error.message.push(`${emptyitems.filter(x => x !== '認証' && x !== '暗号化').join('と\n')}が入力されていません。`)
        }
        if (emptyitems.some(x => x === '認証' || x === '暗号化')) {
          this.error.message.push(`${emptyitems.filter(x => x === '認証' || x === '暗号化').join('と\n')}が選択されていません。`)
        }
        flag = false;
      }

      // ポート番号は数字であること
      if (target.filter(x => (x.label).includes('ポート番号')).some(y => isNaN(Number(y.value)))) {
        console.log('port is not number!!!');
        this.error.isError = true;
        this.error.message.push('ポート番号は数字を入力してください。')
        flag = false;
      }
      return flag;
    },
    async register() {
      //入力チェック
      if (!this.inputCheck()) {
        this.doScrollTop();
        return;
      }
      // 登録・更新共通データ
      // 署名以外は両端の空白文字を削除
      const data = {
          user_id: this.getUserId(),
          display_name: this.forms.display_name.value.toString().trim(),
          signature: this.forms.signature.value || null,
          smtp_server_name: this.forms.smtp_server_name.value.toString().trim(),
          smtp_server_port: Number(this.forms.smtp_server_port.value.toString().trim()),
          is_send_auth: Boolean(this.forms.is_send_auth.value),
          is_crypt: Boolean(this.forms.is_crypt.value),
          pop3_server_name: this.forms.pop3_server_name.value.toString().trim(),
          pop3_server_port: Number(this.forms.pop3_server_port.value.toString().trim()),
          mail_password: this.forms.mail_password.value.toString().trim(),
        }

      // 登録件数が1件以上あれば更新
      // TODO: 複数アカウント設定可能である場合、修正が必要
      if (this.disp_list.length === 0) {
        data.mail_account = this.forms.mail_account.value.toString().trim();
        await this.insertMailAccount(data);
      } else {
        // パスパラメータに指定するため追加
        data.id = this.mailaccount.id;
        await this.updateMailAccount(data);
      }
      this.cancel();
      this.dispData();
    },
    cancel() {
      // 編集内容を初期化
      this.forms = {};
      this.editDialogVisible = false;
    },
    reset() {
      for (var key in this.baseForms) {
        this.baseForms[key].value = null;
      }
      this.cancel();
    },
    async deleteData() {
      //削除確認
      if (!window.confirm(`アカウントを削除してよろしいですか？`)) return;
      try {
        await this.deleteMailAccount(this.mailaccount.id);
        this.reset();
        this.dispData();
      } catch (err) {
        console.log(err);
      }
    },
    errReset() {
      for (var key in this.error) {
        // 配列ならば初期化・論理型はfalse
        // NOTE: error に設定するデータ型は配列か論理
        this.error[key] = Array.isArray(this.error[key]) ? [] : false;
      }
    }
  }
};
</script>
