import http from '../../utils/httpClient';

const state = {
  timecard: []
};

const mutations = {
  setTimeCard(state, timecard) {
    state.timecard = timecard;
  }
};

const actions = {
  async fetchTimeCardData(context, data) {
    await http.get('timecard', { params: data }).then(res => {
      context.commit('setTimeCard', res.data);
    });
  },
  async insertTimeCardData(context, data) {
    return await http.post(`timecard/`, data);
  },
  async updateTimeCardData(context, data) {
    return await http.put(`timecard/${data.id}`, data);
  },
  async setTimeCardClockIn(context, data) {
    return await http.post('timecard/clock_in', data);
  },
  async setTimeCardClockOut(context, data) {
    return await http.post('timecard/clock_out', data);
  },
  async setTimeCardOutgoing(context, data) {
    return await http.post('timecard/outgoing', data);
  },
  async setTimeCardComeback(context, data) {
    return await http.post('timecard/comeback', data);
  }
  /////////////////////////////////////////////////////////////
};

export default {
  state,
  mutations,
  actions
};
