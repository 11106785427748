import axios from 'axios';
import router from '../src/router';

const webApiUrl = process.env.VUE_APP_API_URL;
const timeout = process.env.VUE_APP_API_TIMEOUT_MSEC;

const http = axios.create({
  baseURL: webApiUrl,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json',
  timeout: timeout
});

// リクエストログ
http.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let refreshTokenPromise; // リフレッシュ処理の進行状況を保持するグローバル変数
// レスポンスログ
http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // アクセストークンの期限切れ時のリフレッシュトークンによるアクセストークン再発行処理
    if (error.response.status === 401 && !error.config.isRetry) {
      error.config.isRetry = true; // リフレッシュ処理失敗時にも401エラーが返ってくるので、これがないと無限ループになる

      // アクセストークンリフレッシュ処理の進行状況をチェックする
      // 他のAPIアクセス失敗により、すでにトークン再取得が進行中ならば、この処理はスキップ
      if (!refreshTokenPromise) {
        const refreshToken = localStorage.getItem('refreshToken');
        refreshTokenPromise = http
          .post('refresh', { refreshToken })
          .then(res => {
            console.info(`アクセストークンを再取得しました。`);

            localStorage.setItem('token', res.data.token);

            const exist = !!res.data.newRefreshToken;
            if (exist) {
              localStorage.setItem('refreshToken', res.data.newRefreshToken);
            }
            refreshTokenPromise = null;
            return res;
          })
          .catch(error => {
            return Promise.reject(error);
          });
      }

      return refreshTokenPromise
        .then(token => {
          console.info('取得したアクセストークンでAPIに再アクセスします。');
          error.config.headers.Authorization = `bearer ${token}`;
          return http.request(error.config);
        })
        .catch(() => {});
    } 
    else if (error.response.status === 403 || error.response.status === 408) {
      console.log('Wrong email account!! Please edit the account.');
      // メールの認証に失敗した場合
      window.alert(`送受信に失敗しました。`);
      return Promise.reject(error);
    }
    else {
      router.push('/login').catch(() => {}); // /loginにいるとき/loginに移動しようとするとNavigationDuplicatedエラーになるため、catchしておく。
      return Promise.reject(error);
    }
  }
);

/** Authorizationを付与 */
http.interceptors.request.use(config => {
  const accessToken = localStorage.getItem('token');
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

export default http;
