<template>
  <v-card class="mx-1 mt-3" color="background_sub">
    <v-container>
      <v-row class="pa-0" justify="center" align-content="center">
        <v-col>
          <v-toolbar-title class="pt-4 pl-5">{{ label }}</v-toolbar-title>
        </v-col>
        <v-col>
          <v-select
            class="pt-4 pr-5"
            :prepend-icon="useIcon ? 'mdi-puzzle' : ''"
            label="グループ"
            item-text="group_name"
            item-value="id"
            :items="GetFacilityGroup()"
            v-model="group_id"
            @change="group_change"
            :outlined="outlined"
            dense
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-row class="pa-0">
        <v-col cols="6">
          <v-list
            style="min-height: 200px; max-height: 200px"
            class="overflow-y-auto"
            :outlined="outlined"
            dense
          >
            <v-list-item
              v-for="(item, index) in view_sel_facility"
              :key="index"
              link
              @click="removeUserItem(item)"
            >
              <!-- <v-list-item-avatar>
              <v-icon left :color="item.iconColor">{{ item.icon }}</v-icon>
            </v-list-item-avatar> -->
              <v-list-item-content>
                <v-list-item-title v-if="dispNumber"
                  >{{ index + 1 }}.{{ item.disp_name }}</v-list-item-title
                >
                <v-list-item-title v-else>
                  {{ item.disp_name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="6">
          <v-list
            style="min-height: 200px; max-height: 200px; max-width: 600px"
            class="overflow-y-auto"
            :outlined="outlined"
            dense
          >
            <v-list-item
              v-for="(item, index) in this.view_src_facility"
              :key="index"
              link
              @click="selectedUserItem(item)"
            >
              <!-- <v-list-item-avatar>
              <v-icon left :color="item.iconColor">{{ item.icon }}</v-icon>
            </v-list-item-avatar> -->
              <v-list-item-content>
                <v-list-item-title>{{ item.disp_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import token from '../../utils/token';

export default {
  components: {},
  name: 'MultiFacilitySelectList',
  props: {
    data: [],
    label: {},
    dispNumber: Boolean,
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    useIcon: {
      type: Boolean,
      default: true
    },
    useTitleLabel: {
      type: Boolean,
      default: false
    },
    ismobile: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      view_sel_facility: [],
      view_src_facility: [],
      group_id: 0
    };
  },
  computed: {
    ...mapState({
      facilityList: state => state.facility.facility,
      facilitygroup: state => state.facilityGroup.facilityGroup,
      filedata: state => state.fileData.filedata,
      userconfig: state => state.userConfig.userconfig
    })
  },
  watch: {
    data: function () {
      this.view_sel_facility.splice(0);

      //初期選択のuser
      this.view_sel_facility.push(
        ...this.data
          .map(x => this.facilityList.find(y => y.id === x))
          .filter(x => x)
      );
    }
  },

  created: function () {},
  mounted: function () {
    //groupの初期選択
    this.group_id = -1;
    this.group_change();

    if (this.view_sel_facility.length === 0) {
      this.view_sel_facility.splice(0);

      //初期選択のuser
      this.data.forEach(facility_id => {
        let addItem = this.facilityList
          .filter(x => x.enable)
          .find(item => item.id === facility_id);
        if (addItem) {
          this.view_sel_facility.push(addItem);
        }
      });
    }
  },
  mixins: [token],
  methods: {
    ...mapActions([]),
    onResize() {},
    group_change() {
      let sel_group = this.GetFacilityGroup().find(
        item => item.id === this.group_id
      );
      this.view_src_facility = [];
      this.facilityList
        .filter(x => x.enable)
        .forEach(item => {
          if (sel_group.id <= 0) {
            //全て
            this.view_src_facility.push(item);
          } else {
            if (
              sel_group.facility_id.find(facility_id => facility_id === item.id)
            ) {
              this.view_src_facility.push(item);
            }
          }
        });
    },
    selectedUserItem(item) {
      let find_user = false;

      this.view_sel_facility.forEach(item2 => {
        find_user = find_user || item.id == item2.id;
      });
      if (!find_user) {
        this.view_sel_facility.push(item);
      }
      //選択データの同期
      this.unionData();
    },
    removeUserItem(item) {
      this.view_sel_facility.splice(this.view_sel_facility.indexOf(item), 1);
      // if (this.view_sel_facility.length === 0) {
      //   let addItem = this.facilityList
      //     .filter(x => x.enable)
      //     .find(item => item.id === this.getUserId());
      //   this.view_sel_facility.push(addItem);
      // }
      //選択データの同期
      this.unionData();
    },
    unionData() {
      this.data.splice(0);
      this.view_sel_facility.forEach(item => this.data.push(item.id));
    },
    GetFacilityGroup() {
      const ret = [];
      ret.push({ id: -1, group_name: '全て', facility_id: [] });
      ret.push(...this.facilitygroup);
      return ret;
    }
  }
};
</script>
