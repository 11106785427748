<template>
  <v-card class="mx-1 mt-3" color="background_sub">
    <v-container>
      <v-row class="pa-0" justify="center" align-content="center">
        <v-col>
          <v-toolbar-title class="pt-4 pl-5">{{ label }}</v-toolbar-title>
        </v-col>
        <v-col>
          <v-select
            class="pt-4 pr-5"
            :prepend-icon="useIcon ? 'mdi-puzzle' : ''"
            label="グループ"
            item-text="group_name"
            item-value="index"
            :items="GetGroup()"
            v-model="group_index"
            @change="group_change"
            :outlined="outlined"
            dense
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-row class="pa-0">
        <v-col cols="6">
          <v-list
            style="min-height: 200px; max-height: 200px"
            class="overflow-y-auto"
            :outlined="outlined"
            dense
          >
            <v-list-item
              v-for="(item, index) in view_sel_facility"
              :key="index"
              link
              @click="removeUserItem(item)"
            >
              <!-- <v-list-item-avatar>
              <v-icon left :color="item.iconColor">{{ item.icon }}</v-icon>
            </v-list-item-avatar> -->
              <v-list-item-content>
                <v-list-item-title v-if="dispNumber"
                  >{{ index + 1 }}.{{ item.disp_name }}</v-list-item-title
                >
                <v-list-item-title v-else>
                  {{ item.disp_name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="6">
          <v-list
            style="min-height: 200px; max-height: 200px; max-width: 600px"
            class="overflow-y-auto"
            :outlined="outlined"
            dense
          >
            <v-list-item
              v-for="(item, index) in this.view_src_facility"
              :key="index"
              link
              @click="selectedUserItem(item)"
            >
              <!-- <v-list-item-avatar>
              <v-icon left :color="item.iconColor">{{ item.icon }}</v-icon>
            </v-list-item-avatar> -->
              <v-list-item-content>
                <v-list-item-title>{{ item.disp_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import token from '../../utils/token';

export default {
  components: {},
  name: 'MultiFacilitySelectList',
  props: {
    data: [],
    data2: [],
    label: {},
    dispNumber: Boolean,
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    useIcon: {
      type: Boolean,
      default: true
    },
    useTitleLabel: {
      type: Boolean,
      default: false
    },
    ismobile: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      view_sel_facility: [],
      view_src_facility: [],
      view_src_data: [],
      group_index: 0
    };
  },
  computed: {
    ...mapState({
      facilityList: state => state.facility.facility,
      facilitygroup: state => state.facilityGroup.facilityGroup,
      usersList: state => state.user.usersList,
      usergroup: state => state.userGroup.usergroup,
      filedata: state => state.fileData.filedata,
      userconfig: state => state.userConfig.userconfig
    })
  },
  watch: {
    data: function () {
      this.view_sel_facility.splice(0);

      //初期選択のuser
      this.view_sel_facility.push(
        ...this.data.map(x =>
          this.view_src_data.find(y => y.type == 0 && y.id == x)
        )
      );
      this.view_sel_facility.push(
        ...this.data2.map(x =>
          this.view_src_data.find(y => y.type == 1 && y.id == x)
        )
      );
    }
  },

  created: function () {
    this.view_src_data = [
      ...this.usersList.map(x => {
        return { type: 0, id: x.id, disp_name: x.disp_name, enable: x.enable };
      }),
      ...this.facilityList.map(x => {
        return { type: 1, id: x.id, disp_name: x.disp_name, enable: x.enable };
      })
    ];
  },
  mounted: function () {
    //groupの初期選択
    this.group_index =
      this.GetGroup()
        .filter(x => x.type == 0)
        .find(item => item.facility_id.find(item => item === this.getUserId()))
        ?.index || 0;
    this.group_change();

    if (this.view_sel_facility.length === 0) {
      this.view_sel_facility.splice(0);

      //初期選択のuser
      this.view_sel_facility.push(
        ...this.data
          .map(x => this.view_src_data.find(y => y.type == 0 && y.id == x))
          .filter(x => x)
      );

      this.view_sel_facility.push(
        ...this.data2
          .map(x => this.view_src_data.find(y => y.type == 1 && y.id == x))
          .filter(x => x)
      );
    }
  },
  mixins: [token],
  methods: {
    ...mapActions([]),
    onResize() {},
    group_change() {
      let sel_group = this.GetGroup().find(
        item => item.index === this.group_index
      );
      this.view_src_facility = [];
      this.view_src_data
        .filter(x => x.enable && x.type == sel_group.type)
        .forEach(item => {
          if (sel_group.id <= 0) {
            //全て
            this.view_src_facility.push(item);
          } else {
            if (
              sel_group.facility_id.find(facility_id => facility_id === item.id)
            ) {
              this.view_src_facility.push(item);
            }
          }
        });
    },
    selectedUserItem(item) {
      let find_user = false;

      this.view_sel_facility.forEach(item2 => {
        find_user =
          find_user || (item.id == item2.id && item.type == item2.type);
      });
      if (!find_user) {
        this.view_sel_facility.push(item);
      }
      //選択データの同期
      this.unionData();
    },
    removeUserItem(item) {
      this.view_sel_facility.splice(this.view_sel_facility.indexOf(item), 1);
      if (this.view_sel_facility.length === 0) {
        let addItem = this.view_src_data
          .filter(x => x.enable && x.type == 0)
          .find(item => item.id === this.getUserId());
        this.view_sel_facility.push(addItem);
      }
      //選択データの同期
      this.unionData();
    },
    unionData() {
      this.data.splice(0);
      this.data.push(
        ...this.view_sel_facility.filter(x => x.type == 0).map(x => x.id)
      );
      this.data2.splice(0);
      this.data2.push(
        ...this.view_sel_facility.filter(x => x.type == 1).map(x => x.id)
      );
    },
    GetGroup() {
      const ret = [];
      ret.push({
        type: 0,
        id: -1,
        group_name: '全てのユーザー',
        facility_id: []
      });
      ret.push(
        ...this.usergroup.map(x => {
          return {
            type: 0,
            id: x.id,
            group_name: x.group_name,
            facility_id: x.user_id
          };
        })
      );
      ret.push({ type: 1, id: -1, group_name: '全ての設備', facility_id: [] });
      ret.push(
        ...this.facilitygroup.map(x => {
          return {
            type: 1,
            id: x.id,
            group_name: x.group_name,
            facility_id: x.facility_id
          };
        })
      );
      return ret.map((x, index) => {
        return { index, ...x };
      });
    }
  }
};
</script>
