<template>
  <v-app-bar v-resize="onResize" app>
    <div class="d-flex align-center">
      <router-link to="/">
        <v-img
          contain
          :src="getImageUrl()"
          transition="scale-transition"
          max-height="50"
          :max-width="company_log_width"
          :light="true"
          @click="logo_click"
        ></v-img>
      </router-link>
    </div>
    <template v-if="isDevMode()">
      <v-btn @click="devbtn_click">
        <v-icon class="mdi-spin" dark>mdi-cat</v-icon>
        開発環境
      </v-btn>
    </template>
    <v-spacer />
    <template v-if="windowSize.x < 1200">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on" @click="location.reload()">
            <!-- <v-icon >mdi-menu</v-icon> -->
            menu
          </v-btn>
        </template>
        <v-list>
          <template v-for="(item, index) in pages">
            <v-list-item v-if="item.type === 'btn' && item.visible" :key="index" :to="item.url">
              <v-list-item-icon><v-icon v-text="item.icon" /></v-list-item-icon>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item>
            <v-list-item
              v-else-if="item.type === 'link' && item.visible"
              :key="index + 'A'"
              :href="item.url"
              target="_blank"
            >
              <v-list-item-icon><v-icon v-text="item.icon" /></v-list-item-icon>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item>

            <v-list-item v-else-if="item.type === 'account' && item.visible" :key="index + 'B'" :to="item.url">
              <v-list-item-icon><v-icon v-text="item.icon" /></v-list-item-icon>
              <v-list-item-title v-text="user.disp_name"></v-list-item-title>
            </v-list-item>
            <v-list-item v-else-if="item.type === 'logout' && item.visible" :key="index + 'C'" @click="dologout">
              <v-list-item-icon><v-icon v-text="item.icon" /></v-list-item-icon>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item>
            <v-divider v-else-if="item.type === 'line' && item.visible" :key="index + 'D'" />
          </template>
        </v-list>
      </v-menu>
    </template>
    <template v-else>
      <template v-if="useNewMenu || isDevMode()">
        <!-- 外に展開するもの -->
        <template v-for="(item, key) in pages">
          <template v-if="item.visible && !item.in_menu">
            <activity v-if="(useNewMenu || isDevMode()) && item.type === 'activity'" :key="key + 'activity'" />
            <v-tooltip v-else-if="item.type === 'btn'" :key="key" open-delay="100" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" :to="item.url" v-bind="attrs" v-on="on" :disabled="!item.enabled" icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
            <v-btn
              v-else-if="item.type === 'link'"
              :key="key + 'E'"
              class="mr-2"
              :href="item.url"
              target="_blank"
              text
              :disabled="!item.enabled"
            >
              <span>{{ item.title }}</span>
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
            <v-divider v-else-if="item.type === 'line'" :key="key + 'G'" vertical />
            <v-menu v-else-if="item.type === 'account'" :key="key + 'F'" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" elevation="0" v-bind="attrs" v-on="on">
                  <v-badge
                    v-model="alert.visible"
                    color="deep-purple accent-4"
                    :content="alert.count"
                    offset-x="12"
                    offset-y="12"
                  >
                    <v-avatar color="grey darken-1" size="32">
                      <v-icon>mdi-account</v-icon>
                    </v-avatar>
                  </v-badge>
                  <span v-html="user.disp_name"></span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item :to="item.url">
                  <v-list-item-icon><v-icon v-text="'mdi-account'" /></v-list-item-icon>
                  <v-list-item-title v-text="'個人設定'" />
                </v-list-item>
                <v-list-item v-if="user.admin" to="/admin/settings">
                  <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
                  <v-list-item-title v-text="'管理設定'" />
                </v-list-item>
                <v-list-item @click="setDispNotificationDialog(true)">
                  <v-list-item-icon><v-icon v-text="'mdi-bell-ring'" /></v-list-item-icon>
                  <v-list-item-title v-text="'通知設定'" />
                </v-list-item>
                <v-list-item @click="dologout">
                  <v-list-item-icon><v-icon v-text="'mdi-logout'" /></v-list-item-icon>
                  <v-list-item-title v-text="'ログアウト'" />
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- aaa -->
          </template>
        </template>
        <!-- メニューに展開するもの -->
        <v-menu min-width="400px" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <!--
               <v-btn color="primary" v-bind="attrs" v-on="on">
              menu
            </v-btn>
           -->
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>

          <v-card min-width="120px">
            <v-card-text class="pa-0 ma-0">
              <v-row class="pa-1 ma-0"> 機能<v-spacer /> </v-row>
              <v-divider />
              <v-container>
                <v-row class="d-flex align-center py-1" no-gutters>
                  <template v-for="(item, key) in pages">
                    <v-col cols="4" v-if="item.visible && item.in_menu" :key="key" class="pa-1">
                      <v-hover v-slot="{ hover }">
                        <v-card v-if="item.type === 'activity'" :elevation="hover ? 12 : 2">
                          <v-row class="justify-center" no-gutters>
                            <activity />
                          </v-row>
                          <v-row class="justify-center" no-gutters>
                            <div class="text-subtitle-2">{{ item.title }}</div>
                          </v-row>
                        </v-card>
                        <v-card v-else-if="item.type === 'btn'" :elevation="hover ? 12 : 2" :to="item.url">
                          <v-row class="justify-center" no-gutters>
                            <v-icon large>{{ item.icon }}</v-icon>
                          </v-row>
                          <v-row class="justify-center" no-gutters>
                            <div class="text-subtitle-2">{{ item.title }}</div>
                          </v-row>
                        </v-card>
                        <v-card
                          v-else-if="item.type === 'link'"
                          :elevation="hover ? 12 : 2"
                          :href="item.url"
                          target="_blank"
                        >
                          <v-row class="justify-center" no-gutters>
                            <v-icon large>{{ item.icon }}</v-icon>
                          </v-row>
                          <v-row class="justify-center" no-gutters>
                            <div class="text-subtitle-2">{{ item.title }}</div>
                          </v-row>
                        </v-card>
                        <v-menu v-else-if="item.type === 'account'" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-card :elevation="hover ? 12 : 2" v-bind="attrs" v-on="on">
                              <v-row class="justify-center" no-gutters>
                                <v-icon large>{{ item.icon }}</v-icon>
                              </v-row>
                              <v-row class="justify-center" no-gutters>
                                <div class="text-subtitle-2">
                                  {{ user.disp_name }}
                                </div>
                              </v-row>
                            </v-card>
                          </template>
                          <v-list>
                            <v-list-item :to="item.url">
                              <v-list-item-icon><v-icon v-text="'mdi-account'" /></v-list-item-icon>
                              <v-list-item-title v-text="'個人設定'" />
                            </v-list-item>
                            <v-list-item v-if="user.admin" to="/admin/settings">
                              <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
                              <v-list-item-title v-text="'管理設定'" />
                            </v-list-item>
                            <v-list-item @click="setDispNotificationDialog(true)">
                              <v-list-item-icon><v-icon v-text="'mdi-bell-ring'" /></v-list-item-icon>
                              <v-list-item-title v-text="'通知設定'" />
                            </v-list-item>
                            <v-list-item @click="dologout">
                              <v-list-item-icon><v-icon v-text="'mdi-logout'" /></v-list-item-icon>
                              <v-list-item-title v-text="'ログアウト'" />
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-hover>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <template v-else>
        <!-- アイコン横並び -->
        <template v-for="(item, key) in pages">
          <template v-if="item.visible">
            <activity v-if="(useNewMenu || isDevMode()) && item.type === 'activity'" :key="key + 'activity'" />
            <v-tooltip v-else-if="item.type === 'btn'" :key="key" open-delay="100" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" :to="item.url" v-bind="attrs" v-on="on" :disabled="!item.enabled" icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
            <v-btn
              v-else-if="item.type === 'link'"
              :key="key + 'E'"
              class="mr-2"
              :href="item.url"
              target="_blank"
              text
              :disabled="!item.enabled"
            >
              <span>{{ item.title }}</span>
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
            <v-menu v-else-if="item.type === 'account'" :key="key + 'F'" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" elevation="0" v-bind="attrs" v-on="on">
                  <v-badge
                    v-model="alert.visible"
                    color="deep-purple accent-4"
                    :content="alert.count"
                    offset-x="12"
                    offset-y="12"
                  >
                    <v-avatar color="grey darken-1" size="32">
                      <v-icon>mdi-account</v-icon>
                    </v-avatar>
                  </v-badge>
                  <span v-html="user.disp_name"></span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item :to="item.url">
                  <v-list-item-icon><v-icon v-text="'mdi-account'" /></v-list-item-icon>
                  <v-list-item-title v-text="'個人設定'" />
                </v-list-item>
                <v-list-item v-if="user.admin" to="/admin/settings">
                  <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
                  <v-list-item-title v-text="'管理設定'" />
                </v-list-item>
                <v-list-item @click="setDispNotificationDialog(true)">
                  <v-list-item-icon><v-icon v-text="'mdi-bell-ring'" /></v-list-item-icon>
                  <v-list-item-title v-text="'通知設定'" />
                </v-list-item>
                <v-list-item @click="dologout">
                  <v-list-item-icon><v-icon v-text="'mdi-logout'" /></v-list-item-icon>
                  <v-list-item-title v-text="'ログアウト'" />
                </v-list-item>
              </v-list>
            </v-menu>

            <v-divider v-else-if="item.type === 'line'" :key="key + 'G'" vertical />
          </template>
        </template>
      </template>
    </template>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import token from '../../utils/token';
import Activity from '../components/Activity.vue';

export default {
  name: 'Header',
  components: {
    Activity
  },
  data: () => ({
    windowSize: {
      x: 0,
      y: 0
    },
    last_image_path: '',
    company_log_base_width: 0,
    company_log_width: 100,
    alert: { count: 99, visible: false },
    userName: '',
    user: { disp_name: '', admin: false },
    pages: [],
    menu: true,
    useNewMenu: false
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      reLoading: state => state.reLoading,
      usersList: state => state.user.usersList,
      userMenu: state => state.usermenu.usermenu,
      feature: state => state.feature.feature
    })
  },
  mixins: [token],
  methods: {
    ...mapActions([
      'auth',
      'fetchUserMenu',
      'setDispNotificationDialog',
      'setReLoading',
      'startDataBackup',
      'setInitLoading'
    ]),
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.company_log_width = Math.min(this.company_log_base_width, window.innerWidth / 2);
    },
    async init() {
      // this.userName = this.getUserName();
      const user = this.usersList.find(x => x.id === this.getUserId());
      this.user.disp_name = user.disp_name;
      this.user.admin = user.admin;
      this.pages = await this.createPages();
    },
    async createPages() {
      const pages = [];
      await this.fetchUserMenu({ user_id: this.getUserId() });

      if (this.userMenu && this.userMenu.length > 0) {
        pages.push(
          ...this.userMenu
            .filter(
              x =>
                x.feature.length == 0 || (this.feature.find(y => y.enable && y.feature == x.feature)?.enable ?? false)
            )
            .map(x => {
              return {
                id: x.id,
                in_menu: x.in_menu,
                type: x.type,
                title: x.title,
                url: x.url,
                icon: x.icon,
                enabled: x.enable,
                visible: x.visible
              };
            })
        );
      } else {
        pages.push({
          id: 1,
          in_menu: false,
          type: 'activity',
          title: 'アクティビティ',
          url: '',
          icon: '',
          enabled: true,
          visible: true
        });
        pages.push({
          id: 1,
          in_menu: true,
          type: 'btn',
          title: 'ホーム',
          url: '/',
          icon: 'mdi-home',
          enabled: true,
          visible: true
        });
        if (this.feature.find(x => x.enable && x.feature == 'Information'))
          pages.push({
            id: 2,
            in_menu: true,
            type: 'btn',
            title: 'お知らせ',
            url: '/information',
            icon: 'mdi-bell',
            enabled: true,
            visible: true
          });
        if (this.feature.find(x => x.enable && x.feature == 'Library'))
          pages.push({
            id: 3,
            in_menu: true,
            type: 'btn',
            title: 'ライブラリ',
            url: '/library',
            icon: 'mdi-folder',
            enabled: true,
            visible: true
          });
        if (this.feature.find(x => x.enable && x.feature == 'Schedule'))
          pages.push({
            id: 4,
            in_menu: true,
            type: 'btn',
            title: 'スケジュール',
            url: '/schedule',
            icon: 'mdi-calendar',
            enabled: true,
            visible: true
          });
        if (this.feature.find(x => x.enable && x.feature == 'TimeCard'))
          pages.push({
            id: 5,
            in_menu: true,
            type: 'btn',
            title: 'タイムカード',
            url: '/timecard',
            icon: 'mdi-timetable',
            enabled: true,
            visible: true
          });
        if (this.feature.find(x => x.enable && x.feature == 'Workflow'))
          pages.push({
            id: 6,
            in_menu: true,
            type: 'btn',
            title: 'ワークフロー',
            url: '/workflow',
            icon: 'mdi-clipboard-flow',
            enabled: true,
            visible: false
          });
        if (this.feature.find(x => x.enable && x.feature == 'Workflow'))
          pages.push({
            id: 7,
            in_menu: true,
            type: 'btn',
            title: 'ワークフロー',
            url: '/workflow3',
            icon: 'mdi-clipboard-flow',
            enabled: true,
            visible: true
          });
        if (this.feature.find(x => x.enable && x.feature == 'Mail')) {
          if (process.env.VUE_APP_USE_MAIL == 'true') {
            pages.push({
              id: 8,
              in_menu: true,
              type: 'btn',
              title: 'メール',
              url: '/mail',
              icon: 'mdi-email',
              enabled: true,
              visible: true
            });
          } else {
            pages.push({
              id: 8,
              in_menu: true,
              type: 'link',
              title: 'メール',
              url: 'https://sd-webmail15.rentalserver.jp/',
              icon: 'mdi-email',
              enabled: true,
              visible: true
            });
          }
        }

        pages.push({
          id: 9,
          in_menu: true,
          type: 'link',
          title: 'ヘルプ',
          url: 'https://help.saltapl.com/',
          icon: 'mdi-help-circle',
          enabled: true,
          visible: true
        });
      }

      pages.push({
        id: 997,
        in_menu: true,
        type: 'line',
        title: '境界線',
        icon: '',
        enabled: true,
        visible: true
      });
      pages.push({
        id: 998,
        in_menu: false,
        type: 'account',
        title: 'アカウント',
        url: '/usersettings',
        icon: 'mdi-account',
        enabled: true,
        visible: true
      });
      pages.push({
        id: 999,
        in_menu: true,
        type: 'logout',
        title: 'ログアウト',
        url: '',
        icon: 'mdi-logout',
        enabled: true,
        visible: true
      });
      return pages;
    },
    dologout() {
      // 認証および遷移
      this.auth({
        id: '',
        userId: '',
        userToken: ''
      }).then(() => {
        this.setInitLoading(true);
        this.$router.push('/login');
      });
    },
    logo_click() {
      // ロゴクリックで各機能のリロードをする
      this.setReLoading(!this.reLoading);
    },
    devbtn_click() {
      // var temp = this.reLoading;
      // console.log(temp);
      // this.setReLoading(!temp);

      this.startDataBackup();
      // console.log('devbtn_click-s');
      // this.wb
      //   .messageSW({
      //     type: 'wait',
      //     wait: 1
      //   })
      //   .then(result => {
      //     console.log('Service Worker :', result);
      //   });
      // console.log('devbtn_click-e');
    },
    devbtn_click2() {},

    getImageUrl() {
      var imagePath = this.$vuetify.theme.dark
        ? process.env.VUE_APP_HEADER_LOGO_DARK
        : process.env.VUE_APP_HEADER_LOGO_LIGHT;

      if (this.last_image_path == imagePath) {
        return imagePath;
      }
      this.last_image_path = imagePath;
      var img = new Image();
      img.onload = () => {
        this.company_log_base_width = (50 * img.width) / img.height;
        this.onResize();
      };
      img.src = imagePath;

      return imagePath;
    },
    isDevMode() {
      return location.hostname === 'localhost';
    }
  }
};
</script>

<style>
.theme--dark.v-btn.v-btn--has-bg {
  background: #404040;
}

.gaming {
  /* アニメーションの長さ */
  animation: gaming 2s linear infinite;
}
@keyframes gaming {
  /* 色の指定 */
  0% {
    background-color: Magenta;
  }
  33% {
    background-color: yellow;
  }
  66% {
    background-color: Cyan;
  }
  100% {
    background-color: Magenta;
  }
}

.gaming2 {
  /* アニメーションの長さ */
  animation: gaming2 2s linear infinite;
}
@keyframes gaming2 {
  /* 色の指定 */
  0% {
    background-color: Cyan;
  }
  33% {
    background-color: Magenta;
  }
  66% {
    background-color: yellow;
  }
  100% {
    background-color: Cyan;
  }
}
</style>
