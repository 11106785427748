import file from '../../utils/fileTransfer';

const state = {};

const mutations = {};

const actions = {
  async uploadFile(context, data) {
    file.responseType = 'json';
    return await file.post('file_upload', data);
  },
  async uploadFiles(context, data) {
    file.responseType = 'json';
    return await file.post('files_upload', data);
  },
  async deleteFile(context, data) {
    file.responseType = 'json';
    return await file.delete(`file_delete/${data.id}`);
  },
  async deleteFiles(context, data) {
    file.responseType = 'json';
    return await file.post('file_delete', data);
  },
  async downloadFile(context, data) {
    file.responseType = 'arraybuffer';
    return await file.get(`download/${data.filename}`, {
      responseType: 'arraybuffer'
    });
  }
};

export default {
  state,
  mutations,
  actions
};
