import http from '../../utils/httpClient';

const state = {
  positionData: []
};

const mutations = {
  setPositionData(state, positionData) {
    state.positionData = positionData;
  }
};

const actions = {
  async fetchPositionData(context) {
    await http
      .get('position')
      .then(positionData =>
        context.commit('setPositionData', positionData.data)
      );
  }
};

export default {
  state,
  mutations,
  actions
};
