<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <Header />
    <v-main>
      <push-message />
      <v-container fluid>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Header from './Header.vue';
import Footer from './Footer.vue';
import PushMessage from '../components/PushMessage.vue';
import { mapState, mapActions } from 'vuex';
import token from '../../utils/token';

export default {
  data: () => ({}),
  components: {
    Header,
    Footer,
    PushMessage
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
    ...mapState({
      initLoading: state => state.initLoading,
      colorThemes: state => state.colorTheme.colorThemes,
      userConfig: state => state.userConfig.userconfig
    })
  },
  mixins: [token],
  created() {
    this.setInitLoading(true);
    const p = [];
    // ユーザー一覧の情報を取得
    p.push(this.fetchUserList());
    // ユーザーグループ一覧の情報を取得
    p.push(this.fetchUserGroup());
    // 設備一覧の情報を取得
    p.push(this.fetchFacility());
    // 設備グループ一覧の情報を取得
    p.push(this.fetchFacilityGroup());
    // ユーザー設定を取得
    p.push(this.fetchUserConfig());
    //祝日データ取得
    p.push(this.fetchHolidayData());
    // カラーテーマを取得
    p.push(this.fetchColorThemes());
    // push通知用のKeyを取得
    p.push(this.fetchPushPublicKey());
    p.push(this.fetchWorkflowTitle());
    p.push(this.fetchWorkflowTag());
    // 機能の一覧を取得
    p.push(this.fetchFeature());
    // tagの一覧を取得
    p.push(this.fetchTagDefinition());
    // お知らせ情報
    p.push(
      this.fetchNewsData({
        allDisp: false
      })
    );
    Promise.all(p)
      .then(() => {
        this.setInitLoading(false);

        const myUserId = this.getUserId();

        //this.userConfigがobserverなので、文字列化した後、オブジェクトとして取り出す
        const userConfig = JSON.parse(JSON.stringify(this.userConfig));
        const myUserConfig = userConfig.filter(x => x.user_id === myUserId)[0];

        if (myUserConfig) {
          const myColorThemeId = myUserConfig.color_theme_id;
          const myColorSubThemeId = myUserConfig.color_sub_theme_id;
          // カラーテーマをカスタマイズしていれば、それを設定する
          if (myColorSubThemeId && myColorSubThemeId) {
            this.setTheme(myColorThemeId - 1, myColorSubThemeId - 1);
          } else {
            this.setTheme(0, 0);
          }
        } else {
          // デフォルトのカラーテーマを設定
          this.setTheme(0, 0);
        }
      })
      .catch(err => {
        console.log(err);
        // 認証および遷移
        this.auth({
          id: '',
          userId: '',
          userToken: ''
        }).then(() => {
          this.$router.push('/login');
        });
      });
  },
  methods: {
    ...mapActions([
      'fetchUserList',
      'fetchUserGroup',
      'fetchFacility',
      'fetchFacilityGroup',
      'fetchUserConfig',
      'fetchHolidayData',
      'fetchColorThemes',
      'fetchPushPublicKey',
      'fetchWorkflowTitle',
      'fetchWorkflowTag',
      'fetchTagDefinition',
      'fetchFeature',
      'setInitLoading',
      'fetchNewsData',
      'auth'
    ]),
    setTheme(theme_id, sub_theme_id) {
      const DARK_MODE = 1;
      const colorThemes = JSON.parse(JSON.stringify(this.colorThemes));
      if (!colorThemes[theme_id]) {
        return;
      }
      const theme = colorThemes[theme_id];
      const name = theme.name;
      const dark = theme.dark;
      const light = theme.light;
      // set themes
      Object.keys(dark).forEach(i => {
        this.$vuetify.theme.themes.dark[i] = dark[i];
      });
      Object.keys(light).forEach(i => {
        this.$vuetify.theme.themes.light[i] = light[i];
      });

      this.$vuetify.theme.themes.name = name;
      if (sub_theme_id == DARK_MODE) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
  }
};
</script>

<style>
.theme--dark .v-toolbar.v-sheet {
  background: #424242;
}

.theme--dark.v-app-bar.v-toolbar.v-sheet {
  background: #404040 !important;
}

.frame {
  border-radius: 7px 7px 7px 7px !important;
}

.logo-dark {
  background-color: #e0e0e0;
  border-radius: 7px;
  /* filter: drop-shadow(0 0 10px #e0e0e0); */
  box-shadow: 0 0 10px #bdbdbd;
}
.logo-light {
  background-color: #eee;
}
</style>
