<template>
  <footer style="text-align: right">
    <news-dialog v-model="isNewsDialogVisible" />
    <v-btn v-show="isNewsDialogDispBtn" @click="showNewsDialog" plain icon><v-icon>mdi-update</v-icon></v-btn>
    <small class="grey--text">{{ footer_text }}</small>
  </footer>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import NewsDialog from '../components/NewsDialog.vue';

export default {
  name: 'Footer',
  components: {
    NewsDialog
  },
  data: () => ({
    windowSize: {
      x: 0,
      y: 0
    },
    name: require('../../package.json').name,
    version: require('../../package.json').version,
    copyright: 'Copyright © 2022-2024 株式会社ソフトウェア開発技術 All rights Reserved.',
    footer_text: '',
    isNewsDialogVisible: false,
    isNewsDialogDispBtn: false
  }),

  mounted: function () {
    //Aipo 8.1.1.0 ～Customize By Software Development Technologies～Ver1.2
    let var_name = this.getAppName() + ' ' + this.getAppVersion() + ' ' + this.getAppCopyRight();

    this.footer_text = var_name;
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      feature: state => state.feature.feature
    })
  },

  methods: {
    ...mapActions([]),
    init() {
      this.isNewsDialogDispBtn = this.feature.some(x => x.enable && x.feature == 'News');
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    getAppName() {
      var value = process.env.VUE_APP_DISP_NAME ?? '';
      return value.length > 0 ? value : this.name;
    },
    getAppVersion() {
      var value = process.env.VUE_APP_DISP_VERSION ?? '';
      return value.length > 0 ? value : this.version;
    },
    getAppCopyRight() {
      var value = process.env.VUE_APP_DISP_COPYRIGHT ?? '';
      return value.length > 0 ? value : this.copyright;
    },
    showNewsDialog() {
      this.isNewsDialogVisible = true;
    }
  }
};
</script>
