import http from '../../utils/httpClient';

const state = {
  workflowTemplate: []
};

const mutations = {
  //#region ワークフローテンプレート
  setWorkflowTemplate(state, workflowTemplate) {
    state.workflowTemplate = workflowTemplate;
  }
  //#endregion
};

const actions = {
  //#region ワークフローテンプレート
  async fetchWorkflowTemplate(context) {
    await http.get('workflow_template').then(res => {
      context.commit('setWorkflowTemplate', res.data);
    });
  },
  async updateWorkflowTemplate(context, data) {
    await http.put(`workflow_template/${data.id}`, data);
  },
  async insertWorkflowTemplate(context, data) {
    await http.post('workflow_template', data);
  },
  async deleteWorkflowTemplate(context, data) {
    await http.delete(`workflow_template/${data.id}`);
  }
  //#endregion
};

export default {
  state,
  mutations,
  actions
};
