<template>
  <v-sheet class="frame" v-resize="onResize" id="library">
    <v-toolbar class="frame" flat>
      <v-btn :to="path" tile text class="pa-0">
        <v-icon v-if="getDispStyle(config.dispStyle).usePageIcon"
          >mdi-folder</v-icon
        >
        <div class="text-decoration-underline text-body-1" style="width: 7em;">ライブラリ</div>
      </v-btn>
      <div class="align-end mt-5">
        <v-col cols="12" :md="disp_cols" v-show="!isMainPage() && !ismobile">
          <v-select
            prepend-icon="mdi-bookmark"
            label="カテゴリ"
            item-text="disp_name"
            item-value="id"
            :items="getDispDirectories()"
            v-model="config[`${name}_directry_id`]"
            style="max-width: 250px"
            v-show="disp_cols === 12"
            @change="dispDirectiesChange"
          >
            <template v-slot:selection="{ item }">{{
              getFormattedPullDownDispName(item.disp_name)
            }}</template>
            <template v-slot:item="{ item }">{{
              getFormattedPullDownDispName(item.disp_name)
            }}</template>
          </v-select>
          <v-list v-show="disp_cols !== 12" dense>
            <v-list-item-group
              v-model="config[`${name}_directry_id`]"
              mandatory
              color="indigo"
            >
              <v-list-item
                v-for="(item, index) in this.getDispDirectories()"
                :key="index"
                :value="item.id"
                link
                @click="dispDirectiesChange"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                  getFormattedPullDownDispName(item.disp_name)
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="info" v-if="canAddEvent()" @click="startEdit" :small="!isMainPage() && !ismobile">追加</v-btn>
    </v-toolbar>
    <v-row class="fill-height" ref="card">
      <v-col cols="12" :md="disp_cols" v-show="isMainPage() || ismobile">
        <v-select
          class="mt-2 pl-4"
          prepend-icon="mdi-bookmark"
          label="カテゴリ"
          item-text="disp_name"
          item-value="id"
          :items="getDispDirectories()"
          v-model="config[`${name}_directry_id`]"
          style="max-width: 300px"
          v-show="disp_cols === 12"
          @change="dispDirectiesChange"
        >
          <template v-slot:selection="{ item }">{{
            getFormattedPullDownDispName(item.disp_name)
          }}</template>
          <template v-slot:item="{ item }">{{
            getFormattedPullDownDispName(item.disp_name)
          }}</template>
        </v-select>
        <v-list v-show="disp_cols !== 12" dense>
          <v-list-item-group
            v-model="config[`${name}_directry_id`]"
            mandatory
            color="indigo"
          >
            <v-list-item
              v-for="(item, index) in this.getDispDirectories()"
              :key="index"
              :value="item.id"
              link
              @click="dispDirectiesChange"
            >
              <v-list-item-content>
                <v-list-item-title>{{
                  getFormattedPullDownDispName(item.disp_name)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="12" :md="data_cols">
        <v-data-table
          ref="datatable"
          :headers="getHeaders()"
          :items="listitem"
          @click:row="showEvent"
          :footer-props="{
            'items-per-page-options': [5, 10, 20, 50, 100, 250, 500, -1],
            'items-per-page-text': '行/ページ:'
          }"
          :items-per-page.sync="config[getItemsPerPage()]"
          sort-by="updatetime"
          :sort-desc="true"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.title`]="{ item }">
            <div>
              <v-icon v-if="isNewIconShow(item)" color="primary"
                >mdi-new-box</v-icon
              >
              {{ item.title }}
            </div>
          </template>
          <template v-slot:[`item.filename`]="{ item }"
            ><div>
              {{ item.filename }}
            </div>
          </template>
          <template v-slot:[`item.directry_id`]="{ item }">
            {{ getDirectryName(item.directry_id) }}
          </template>
          <template v-slot:[`item.updatetime`]="{ item }">
            {{ getDateFormat(item.updatetime) }}
          </template>
          <template v-slot:[`item.updateuser`]="{ item }">
            {{ getUserName(item.updateuser) }}
          </template>
          <template v-slot:[`item.filesize`]="{ item }">
            {{ fileSizeFormat(item.filesize) }}
          </template>
        </v-data-table>

        <custom-dialog v-model="selectedOpen">
          <template v-slot:title>ライブラリ</template>
          <template v-slot:body>
            <v-container>
              <v-row class="d-flex align-center py-0" no-gutters>
                <v-container d-flex justify-content-end mt-n4 mb-n8 pt-0>
                  <v-spacer />
                  <div class="pa-0 ma-0 overline">
                    ID L{{ ('000' + selectedEvent.directry_id).slice(-3) }}-{{
                      ('00000000' + selectedEvent.id).slice(-6)
                    }}-{{ ('00' + 1).slice(-2) }}
                  </div>
                </v-container>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col
                  :cols="ismobile ? 12 : 4"
                  v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                >
                  <p class="mb-0">タイトル</p>
                </v-col>
                <v-col
                  :cols="
                    getDispStyle(config.dispStyle).useTitleLabel || ismobile
                      ? 12
                      : 8
                  "
                >
                  <v-text-field
                    :prepend-icon="
                      getDispStyle(config.dispStyle).useIcon
                        ? 'mdi-message-outline'
                        : ''
                    "
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="selectedEvent.title"
                    readonly
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                        タイトル
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col
                  :cols="ismobile ? 12 : 4"
                  v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                >
                  <p class="mb-0">ファイル</p>
                </v-col>
                <v-col
                  :cols="
                    getDispStyle(config.dispStyle).useTitleLabel || ismobile
                      ? 12
                      : 8
                  "
                >
                  <div>
                    <v-chip
                      v-for="(item, index) in selectedEvent.files"
                      :key="index"
                      @click="downloadCountUp(item)"
                      >{{ getFileName(item) }}</v-chip
                    >
                  </div>
                </v-col>
              </v-row>

              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col
                  :cols="ismobile ? 12 : 4"
                  v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                >
                  <p class="mb-0">カテゴリ</p>
                </v-col>
                <v-col
                  :cols="
                    getDispStyle(config.dispStyle).useTitleLabel || ismobile
                      ? 12
                      : 8
                  "
                >
                  <v-select
                    class="mt-2"
                    :prepend-icon="
                      getDispStyle(config.dispStyle).useIcon
                        ? 'mdi-bookmark'
                        : ''
                    "
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    item-text="disp_name"
                    item-value="id"
                    :items="getDispDirectories()"
                    v-model="selectedEvent.directry_id"
                    readonly
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                        カテゴリ
                      </div>
                    </template>

                    <template v-slot:selection="{ item }">{{
                      getFormattedPullDownDispName(item.disp_name)
                    }}</template>
                    <template v-slot:item="{ item }">{{
                      getFormattedPullDownDispName(item.disp_name)
                    }}</template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col
                  :cols="ismobile ? 12 : 4"
                  v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                >
                  <p class="mb-0">メモ</p>
                </v-col>
                <v-col
                  :cols="
                    getDispStyle(config.dispStyle).useTitleLabel || ismobile
                      ? 12
                      : 8
                  "
                >
                  <text-area-view
                    :icon="
                      getDispStyle(config.dispStyle).useIcon
                        ? 'mdi-message-text-outline'
                        : ''
                    "
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    :title="
                      getDispStyle(config.dispStyle).useTitleLabel ? 'メモ' : ''
                    "
                    :text="selectedEvent.details"
                    :minHeight="'50px'"
                  />
                </v-col>
              </v-row>
            </v-container>

            <v-card class="mx-1 mt-3" color="background_sub">
              <v-container class="px-6 text-body-2">
                <v-row class="pa-0">
                  <v-col class="pa-0 text-subtitle-1">更新情報</v-col>
                </v-row>
                <v-row class="pa-0">
                  <v-col class="pa-0" cols="2">作成者</v-col>

                  <v-col class="pa-0">{{
                    getUserName(selectedEvent.insertuser)
                  }}</v-col>
                  <v-col class="pa-0 text-right">{{
                    fullDateFormat(selectedEvent.inserttime)
                  }}</v-col>
                </v-row>
                <v-row><v-divider /></v-row>
                <v-row class="pa-0">
                  <v-col class="pa-0" cols="2">更新者</v-col>

                  <v-col class="pa-0">{{
                    getUserName(selectedEvent.updateuser)
                  }}</v-col>
                  <v-col class="pa-0 text-right">{{
                    fullDateFormat(selectedEvent.updatetime)
                  }}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </template>
          <template v-slot:footer>
            <v-btn
              color="info"
              v-show="canEditEvent(selectedEvent)"
              @click="startEditing"
              >編集</v-btn
            >
            <v-btn
              color="warning"
              v-show="canDeleteEvent(selectedEvent)"
              @click="deleteData"
              >削除</v-btn
            >
            <v-btn color="accent" @click="selectedOpen = false">
              キャンセル
            </v-btn>
          </template>
        </custom-dialog>

        <custom-dialog v-model="editedOpen" :persistent="true">
          <template v-slot:title>ファイル追加</template>
          <template v-slot:body>
            <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-if="isError.file"
              >『 ファイル 』を入力してください。
            </v-alert>

            <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-if="isError.directory"
              >『 カテゴリ 』を入力してください。
            </v-alert>

            <v-alert type="error" class="pa-0 px-4 py-2 ma-0" v-if="isError.title"
              >『 タイトル 』を入力してください。
            </v-alert>

            <v-container>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col
                  :cols="ismobile ? 12 : 4"
                  v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                >
                  <p class="mb-0 required">タイトル</p>
                </v-col>
                <v-col
                  :cols="
                    getDispStyle(config.dispStyle).useTitleLabel || ismobile
                      ? 12
                      : 8
                  "
                >
                  <v-text-field
                    :prepend-icon="
                      getDispStyle(config.dispStyle).useIcon
                        ? 'mdi-message-outline'
                        : ''
                    "
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="editedEvent.title"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div
                        v-if="getDispStyle(config.dispStyle).useTitleLabel"
                        class="required"
                      >
                        タイトル
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col
                  :cols="ismobile ? 12 : 4"
                  v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                >
                  <p class="mb-0 required">ファイル</p>
                </v-col>
                <v-col
                  :cols="
                    getDispStyle(config.dispStyle).useTitleLabel || ismobile
                      ? 12
                      : 8
                  "
                >
                  <file-input
                    ref="fileinput"
                    :params="{
                      label: getDispStyle(config.dispStyle).useTitleLabel
                        ? 'ファイル'
                        : ''
                    }"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    :prepend-icon="
                      getDispStyle(config.dispStyle).useIcon
                        ? 'mdi-paperclip'
                        : ''
                    "
                  />
                  <div class="py-0 px-3">
                    <v-chip
                      v-for="(item, index) in editedEvent.files"
                      :key="index"
                      close
                      close-icon="mdi-close-outline"
                      @click:close="
                        editedEvent.files = editedEvent.files.filter(
                          n => n !== item
                        )
                      "
                      >{{ getFileName(item) }}</v-chip
                    >
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col
                  :cols="ismobile ? 12 : 4"
                  v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                >
                  <p class="mb-0 required">カテゴリ</p>
                </v-col>
                <v-col
                  :cols="
                    getDispStyle(config.dispStyle).useTitleLabel || ismobile
                      ? 12
                      : 8
                  "
                >
                  <v-select
                    class="mt-2"
                    :prepend-icon="
                      getDispStyle(config.dispStyle).useIcon
                        ? 'mdi-bookmark'
                        : ''
                    "
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    item-text="disp_name"
                    item-value="id"
                    :items="getEditDirectories()"
                    v-model="editedEvent.directry_id"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div
                        v-if="getDispStyle(config.dispStyle).useTitleLabel"
                        class="required"
                      >
                        カテゴリ
                      </div>
                    </template>

                    <template v-slot:selection="{ item }">{{
                      getFormattedPullDownDispName(item.disp_name)
                    }}</template>
                    <template v-slot:item="{ item }">{{
                      getFormattedPullDownDispName(item.disp_name)
                    }}</template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col
                  :cols="ismobile ? 12 : 4"
                  v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                >
                  <p class="mb-0">メモ</p>
                </v-col>
                <v-col
                  :cols="
                    getDispStyle(config.dispStyle).useTitleLabel || ismobile
                      ? 12
                      : 8
                  "
                >
                  <v-textarea
                    :prepend-icon="
                      getDispStyle(config.dispStyle).useIcon
                        ? 'mdi-message-text-outline'
                        : ''
                    "
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="editedEvent.details"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                        メモ
                      </div>
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:footer>
            <v-btn color="info" @click="endEdit"> 登録 </v-btn>
            <v-btn color="accent" @click="cancelEdit"> キャンセル </v-btn>
          </template>
        </custom-dialog>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import FileInput from '../components/FileInput2.vue';
import CustomDialog from '../components/CustomDialog.vue';
import dateformat from '../functions/DateFormat';
import DateUtils from '../functions/DateUtils';
import TextAreaView from '../components/TextAreaView.vue';
import fileOperation from '../../utils/fileOperation';
import token from '../../utils/token';
import inputStyleUtils from '../../utils/inputStyleUtils';
import directorySort from '../functions/DirectorySort';
import scroll from '../../utils/scroll';

export default {
  components: {
    FileInput,
    TextAreaView,
    CustomDialog
  },
  data: () => ({
    name: 'library',
    path: '/library',
    config: {},
    dense: true,
    ismobile: false,
    headers: [],
    listitem: [],
    fileindexlist: [],
    // directories: [],
    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    dialogLeft: 0,
    disp_cols: 2,
    data_cols: 10,
    isError: { file: false, directory: false, title: false },
    selectedOpen: false,
    selectedFullScreen: false,
    selectedEvent: {},
    editedOpen: false,
    editedFullScreen: false,
    editedEvent: {},
    uploadCount: 0,
    uploadStartFile: [],
    uploadEndFile: []
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    },
    reLoading() {
      this.reload();
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      reLoading: state => state.reLoading,
      usersList: state => state.user.usersList,
      filedata: state => state.fileData.filedata,
      userConfig: state => state.userConfig.userconfig,
      librarydata: state => state.library.librarydata,
      libraryDirectory: state => state.libraryDirectory.libraryDirectory
    })
  },
  mixins: [fileOperation, token, inputStyleUtils, scroll],
  methods: {
    ...mapActions([
      'fetchUserConfig',
      'upsertUserConfig',
      'fetchLibraryData',
      'insertLibraryData',
      'updateLibraryData',
      'updateLibraryDataWithFile',
      'deleteLibraryData',
      'fetchLibraryDirectory',
      'fetchFileData',
      'downloadFile'
    ]),
    init() {
      let p = [];
      p.push(this.fetchLibraryDirectory());

      Promise.all(p).then(() => {
        let config = this.userConfig.find(
          item => item.user_id === this.getUserId()
        );

        this.config[`${this.name}_directry_id`] =
          config[`${this.name}_directry_id`] || this.getDispDirectories()[0].id;

        this.config[this.getItemsPerPage()] =
          config[this.getItemsPerPage()] || (this.isMainPage() ? 10 : 5);

        this.config.dispStyle = config.dispStyle || 1;

        if (
          !this.getDispDirectories().find(
            x => x.id === this.config[`${this.name}_directry_id`]
          )
        ) {
          this.config[`${this.name}_directry_id`] =
            this.getDispDirectories()[0].id;
        }

        this.dispLibraryData();
      });
    },
    reload() {
      //データ取得
      this.dispLibraryData();
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.iconSize = window.innerHeight * 0.1;
      this.calendarHeight = Math.max(this.windowSize.y - 140, 600);
      this.dialogLeft = Math.max((this.windowSize.x - 600) / 2, 0);
      let temp = this.$refs.card;
      this.cardSize = { x: temp.clientWidth, y: temp.clientHeight };

      this.ismobile = document.getElementById('library').clientWidth < 800;
      this.selectedFullScreen = this.ismobile;
      this.editedFullScreen = this.ismobile;

      this.headers = this.getHeaders();
      if (this.isMainPage()) {
        this.disp_cols = 2;
        this.data_cols = 10;
      } else {
        this.disp_cols = 12;
        this.data_cols = 12;
      }
    },
    isMainPage() {
      return this.$route.path === '/' + this.name;
    },
    getItemsPerPage() {
      return `${this.name}_items_per_page_${
        this.isMainPage() ? 'main' : 'sub'
      }`;
    },
    getHeaders() {
      const directry_id = {
        text: 'カテゴリ',
        value: 'directry_id'
      };
      const filename = {
        text: 'ファイル名',
        value: 'filename'
      };
      const title = {
        text: 'タイトル',
        value: 'title'
      };
      const updateuser = {
        text: '更新者',
        value: 'updateuser',
        width: '120px'
      };
      // const updatetime = {
      //   text: '更新日時',
      //   value: 'updatetime',
      //   align: 'end',
      //   width: '100px'
      // };
      const dlcount = {
        text: 'DL数',
        value: 'dlcount',
        align: 'end',
        width: '100px'
      };
      const filesize = {
        text: '容量',
        value: 'filesize',
        align: 'end',
        width: '100px'
      };
      if (this.cardSize.x < 400) {
        // return [filename, updatetime];
        return [title];
      } else if (this.cardSize.x < 600) {
        // return [filename, title, updatetime];
        return [title, filename];
      } else if (this.cardSize.x < 1000) {
        // return [directry_id, filename, title, updateuser, updatetime];
        return [directry_id, title, filename, updateuser];
      } else {
        return [
          directry_id,
          filename,
          title,
          updateuser,
          // updatetime,
          dlcount,
          filesize
        ];
      }
    },
    canAddEvent() {
      //新規登録可否
      return this.getPermission(
        this.TOKEN_FEATURE.LIBRARY,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.EDIT
      );
    },
    canEditEvent(event) {
      let result = false;
      if (!event.insertuser) return false;

      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.LIBRARY,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.EDIT
      );
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.LIBRARY,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.EDIT
      );

      if (event.insertuser === this.getUserId()) {
        result = mePermission;
      } else {
        result = otherPerMission;
      }
      //編集権限のないフォルダ
      if (!this.getEditDirectories().find(x => x.id === event.directry_id)) {
        result = false;
      }
      return result;
    },
    canDeleteEvent(event) {
      let result = false;
      if (!event.insertuser) return false;
      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.LIBRARY,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.DELETE
      );
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.LIBRARY,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.DELETE
      );
      if (event.insertuser === this.getUserId()) {
        result = mePermission;
      } else {
        result = otherPerMission;
      }
      //編集権限のないフォルダ
      if (!this.getEditDirectories().find(x => x.id === event.directry_id)) {
        result = false;
      }

      return result;
    },

    showEvent(data) {
      this.selectedEvent = data;
      this.selectedOpen = true;
      console.log(data);
    },
    downloadCountUp(id) {
      let item = this.filedata.find(item => item.id === id);
      if (!item) return '';
      this.downloadFile(item).then(res => {
        // 取得したファイルをダウンロードできるようにする
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', item.originalname);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });

      const data = this.selectedEvent;
      data.dlcount += 1;
      this.updateLibraryData(data).then(() => {
        //表示するスケジュールの一覧を取得
        this.dispLibraryData();
      });
    },
    startEdit() {
      const data = {
        id: -1,
        title: '',
        filename: '',
        files: [],
        directry_id: this.config[`${this.name}_directry_id`],
        details: '',
        dlcount: 0,
        filesize: 0,
        insertuser: this.getUserId(),
        inserttime: new Date(),
        updateuser: this.getUserId(),
        updatetime: new Date()
      };
      if (!this.getEditDirectories().find(x => x.id === data.directry_id)) {
        data.directry_id = -1;
      }

      this.errrorReset();
      if (this.$refs.fileinput) {
        this.$refs.fileinput.fileClear();
      }

      this.selectedOpen = false;
      this.selectedEvent = {};
      this.editedOpen = true;
      this.editedEvent = data;
    },
    startEditing() {
      this.errrorReset();
      if (this.$refs.fileinput) {
        this.$refs.fileinput.fileClear();
      }
      this.editedEvent = JSON.parse(JSON.stringify(this.selectedEvent));
      console.log(this.editedEvent);
      this.selectedOpen = false;
      this.editedOpen = true;
    },
    deleteData() {
      //削除確認
      if (!window.confirm(`このファイルを削除してよろしいですか？`)) return;

      let results = [];
      let promise = null;

      //ファイル削除
      let fileIds = this.selectedEvent.files;
      //データの削除
      if (promise == null) {
        promise = this.deleteLibraryData({
          id: this.selectedEvent.id,
          fileIds
        });
      } else {
        promise = promise.then(result => {
          results.push(result);
          return this.deleteLibraryData({
            id: this.selectedEvent.id,
            fileIds
          });
        });
      }
      promise.then(() => {
        //表示するLibraryDataの一覧を取得
        this.dispLibraryData();
        this.selectedOpen = false;
        this.editedOpen = false;
      });
    },
    errrorReset() {
      for (var key in this.isError) {
        this.isError[key] = false;
      }
    },
    inputCheck() {
      let files = this.$refs.fileinput.files;
      let files2 = this.editedEvent.files;
      //ファイル数チェック
      this.isError.file = files.length + files2?.length <= 0;
      //カテゴリチェック
      this.isError.directory = this.editedEvent.directry_id <= 0;

      for (var key in this.isError) {
        if (this.isError[key]) {
          return false;
        }
      }
      return true;
    },
    endEdit() {
      //入力チェック
      if (!this.inputCheck()) {
        this.doScrollTop();
        return;
      }

      let files = this.$refs.fileinput.files;

      let results = [];
      let promise = null;

      let mainFunc = () => {
        let formData = new FormData();
        if (files.length > 0) {
          files.forEach(file => {
            formData.append('files', file);
          });
        }

        const data = {
          title: this.editedEvent.title,
          filename: '',
          files: Array.from(this.editedEvent.files),
          directry_id: this.editedEvent.directry_id,
          details: this.editedEvent.details,
          dlcount: this.editedEvent.dlcount,
          filesize: 0,
          insertuser: this.editedEvent.insertuser,
          inserttime: this.editedEvent.inserttime,
          updateuser: this.getUserId(),
          updatetime: new Date()
        };

        let promise = null;
        if (this.editedEvent.id != -1) {
          const originalFileIds = this.selectedEvent.files; // 編集前のfileIds
          const editedFileIds = this.editedEvent.files; // 編集後のfileIds
          // 編集の結果、削除すべきファイルのid
          const fileIdsToDelete = originalFileIds.filter(
            id => editedFileIds.indexOf(id) === -1
          );
          // 編集の結果、残しておくべきファイルのid
          const noDeletedFileIds = originalFileIds.filter(
            id => editedFileIds.indexOf(id) !== -1
          );

          // リクエストのbodyに以下のデータを追加
          data.fileIdsToDelete = fileIdsToDelete;
          data.noDeletedFileIds = noDeletedFileIds;
          data.id = this.editedEvent.id; // Librarytデータのid
          formData.append('data', JSON.stringify(data));
          // Libraryデータの更新および付随するファイルデータのアップロード
          promise = this.updateLibraryDataWithFile(formData);
        } else {
          formData.append('data', JSON.stringify(data));
          // Libraryデータの新規登録および付随するファイルデータのアップロード
          promise = this.insertLibraryData(formData);
        }

        promise
          .then(() => {
            //表示するライブラリデータの一覧を取得
            this.dispLibraryData();
            this.editedOpen = false;
          })
          .catch(err => {
            console.log(err);
          });
      };

      if (promise == null) {
        mainFunc();
      } else {
        promise
          .then(result => {
            results.push(result);
            mainFunc();
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    cancelEdit() {
      this.$refs.fileinput.fileClear();
      this.editedOpen = false;
    },

    dispDirectiesChange() {
      this.dispLibraryData();
    },
    dispLibraryData() {
      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.LIBRARY,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.VIEW
      );
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.LIBRARY,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.VIEW
      );

      //ディレクトリ情報取得
      this.fetchLibraryDirectory()
        .then(() => {
          const data = { directry_id: [] };

          //ディレクトリ一覧の更新
          let baseDir = this.getDispDirectories().find(
            item => item.id == this.config[`${this.name}_directry_id`]
          );

          if (!baseDir) baseDir = { path: '-' };

          //階層の情報を取得
          data.directry_id = this.getDispDirectories()
            .filter(item => item.path.indexOf(baseDir.path) === 0)
            .map(item => item.id);

          //ファイルインデックス情報、ライブラリ情報を取得
          Promise.all([this.fetchFileData(), this.fetchLibraryData()]).then(
            () => {
              this.listitem = this.librarydata.filter(
                x =>
                  data.directry_id.indexOf(x.directry_id) >= 0 &&
                  ((x.insertuser === this.getUserId() && mePermission) ||
                    (x.insertuser !== this.getUserId() && otherPerMission))
              );

              //検索設定の保存
              this.saveConfig();
            }
          );
        })
        .catch(err => {
          console.log(err);
        });
    },
    saveConfig() {
      //選択状態を保存する
      let promise = this.fetchUserConfig();
      promise = promise
        .then(() => {
          let config = this.userConfig.find(
            item => item.user_id === this.getUserId()
          );

          if (!config) {
            config = {};
            config.user_id = this.getUserId();
          }
          config[`${this.name}_directry_id`] =
            this.config[`${this.name}_directry_id`];
          return this.upsertUserConfig(config);
        })
        .catch(err => {
          console.log(err);
        });
      promise.catch(err => {
        console.log(err);
      });
      return promise;
    },
    getDispDirectories() {
      // 表示ディレクトリ抽出
      const directories = this.libraryDirectory.filter(x =>
        this.getParentDisp(x)
      );
      // ソート
      return directorySort.directorysort(directories);
    },
    getEditDirectories() {
      // 表示ディレクトリ抽出
      const directories = this.libraryDirectory.filter(x =>
        this.getParentEdit(x)
      );
      // ソート
      return directorySort.directorysort(directories);
    },
    canDispDirectory(ldItem) {
      switch (ldItem.public_lebel) {
        case 0:
          // 全てのユーザーが閲覧／追加／編集／削除可。
          return true;
        case 1:
          // 全てのユーザーが閲覧可。所属メンバーのみ追加／編集／削除可。
          return true;
        case 2:
          // 所属メンバーのみ閲覧／追加／編集／削除可。
          return !!ldItem.user_id.find(x => x === this.getUserId());
        case 3:
          // 自分のみ閲覧／追加／編集／削除可。
          return ldItem.insertuser === this.getUserId();
        default:
          //？？？
          return false;
      }
    },
    canEditDirectory(ldItem) {
      switch (ldItem.public_lebel) {
        case 0:
          // 全てのユーザーが閲覧／追加／編集／削除可。
          return true;
        case 1:
          // 全てのユーザーが閲覧可。所属メンバーのみ追加／編集／削除可。
          return !!ldItem.user_id.find(x => x === this.getUserId());
        case 2:
          // 所属メンバーのみ閲覧／追加／編集／削除可。
          return !!ldItem.user_id.find(x => x === this.getUserId());
        case 3:
          // 自分のみ閲覧／追加／編集／削除可。
          return ldItem.insertuser === this.getUserId();
        default:
          //？？？
          return false;
      }
    },
    isNewIconShow(item) {
      const itemDate = DateUtils.getDate(item.updatetime);
      const targetDate = DateUtils.addDay(DateUtils.getNow(), -7);
      //1週間前のものは新着扱い
      return itemDate > targetDate;
    },
    getDownloadLink(id) {
      let item = this.filedata.find(item => item.id === id);
      if (!item) return '';
      return process.env.VUE_APP_API_URL_FILE + '/download/' + item.filename;
    },
    getFileName(id) {
      if (!id) return '---';
      let item = this.filedata.find(item => item.id === id);
      if (!item) return '---';
      return item.originalname + '(' + this.fileSizeFormat(item.filesize) + ')';
    },
    getDirectryName(id) {
      if (!id) return '---';
      let item = this.getDispDirectories().find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    getUserName(id) {
      if (!id) return '---';
      let item = this.usersList.find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    getDateFormat(date) {
      return dateformat.autoFormat(date);
    },
    fullDateFormat(date) {
      return dateformat.fullFormat(date);
    },
    getFormattedPullDownDispName(disp_name) {
      const len = this.getCategoryDepthByUnderbar(disp_name);
      if (disp_name === 'ルート' || !len) {
        return disp_name;
      }
      let formatted_disp_name = '';
      // 表示名の先頭を_から全角スペースに置き換える(_のままだと表示した時に階層構造が分かりずらいため)
      for (let i = 0; i < len; i++) {
        formatted_disp_name += '\u{3000}';
      }
      // 元の表示名から先頭の_を消去したものを加える。
      formatted_disp_name += disp_name.slice(len);
      return formatted_disp_name;
    },
    /* フォルダの階層の深さをフォルダ名先頭のアンダーバー_の数で求める。*/
    getCategoryDepthByUnderbar(disp_name) {
      if (!disp_name) {
        return;
      }
      const ex = /^_*/g;
      const arr = disp_name.match(ex);
      if (!arr) {
        return;
      }
      return arr[0].length;
    },
    /* フォルダの階層の深さをパスのスラッシュの数で求める。*/
    getCategoryDepthBySlash(path) {
      if (!path) {
        return;
      }
      const ex = /\//g;
      const arr = path.match(ex);
      if (!arr) {
        return;
      }
      return arr.length;
    },
    getParentDisp(directory) {
      if (this.canDispDirectory(directory)) {
        let DispFlg = true;
        const splitPath = directory.path.split('/');
        let parentDirectory = directory;
        for (let i = 1; i < splitPath.length; i++) {
          // パス名から、親ディレクトリ部分のパスを抽出
          parentDirectory = this.getParentDetail(parentDirectory);

          if (parentDirectory !== undefined) {
            DispFlg = DispFlg && this.canDispDirectory(parentDirectory);
          } else {
            DispFlg = false;
          }
        }
        return DispFlg;
      }
      return false;
    },

    getParentEdit(directory) {
      if (this.canEditDirectory(directory)) {
        let EditFlg = true;
        const splitPath = directory.path.split('/');
        let parentDirectory = directory;
        for (let i = 1; i < splitPath.length; i++) {
          // パス名から、親ディレクトリ部分のパスを抽出
          parentDirectory = this.getParentDetail(parentDirectory);

          if (parentDirectory !== undefined) {
            EditFlg = EditFlg && this.canEditDirectory(parentDirectory);
            if (parentDirectory.public_lebel === 1) {
              EditFlg = true;
            }
          } else {
            EditFlg = false;
          }
        }
        return EditFlg;
      }
      return false;
    },
    getParentDetail(directory) {
      const splitPath = directory.path.split('/');
      // ルートの一つ下のディレクトリはそのままcanEditDirectory判定
      if (splitPath.length > 2) {
        // パス名から、親ディレクトリ部分のパスを抽出
        const parentPath = splitPath.slice(0, splitPath.length - 1).join('/');
        // 親ディレクトリのプロパティを取得
        let parentDirectory = Object.assign(
          {},
          this.libraryDirectory.find(d => d.path === parentPath)
        );
        return parentDirectory;
      } else {
        return directory;
      }
    }
  }
};
</script>
<style scoped>
.required::after {
  content: '必須';
  color: red;
  font-size: 12px;
  font-weight: bold;
  min-width: 10px;
  padding: 0px 0px;
  margin: 0px 5px;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
}
.v-btn--active::before {
  opacity: 0 !important;
}
</style>
