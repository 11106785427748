<template>
  <v-row class="fill-height" v-resize="onResize" id="schedule">
    <v-col>
      <template v-if="ismobile">
        <v-sheet ref="header" class="schedule-frame">
          <v-toolbar class="schedule-frame" flat>
            <v-btn :to="path" tile text class="pl-0">
              <v-icon v-if="getDispStyle(config.dispStyle).usePageIcon">mdi-calendar</v-icon>
              <div class="text-decoration-underline text-body-1">スケジュール</div>
            </v-btn>
            <template v-if="$refs.calendar">
              <div class="text-body-1">
                {{ new Date(this.focus).getFullYear() + '年' + (new Date(this.focus).getMonth() + 1) + '月' }}
              </div>
            </template>
            <v-spacer />
            <v-btn outlined color="accent2" @click="onMobileMenuClick" dense>
              <v-icon> mdi-calendar-filter </v-icon>
            </v-btn>
          </v-toolbar>
          <v-container v-show="mobileMenu" class="pa-0">
            <v-row class="d-flex align-center py-1" no-gutters>
              <v-col>
                <v-card outlined>
                  <v-container class="py-0">
                    <v-row class="d-flex align-center py-1" no-gutters>
                      <v-col> 表示レイアウト </v-col>
                    </v-row>
                    <v-row class="d-flex align-center py-1" no-gutters>
                      <v-col cols="2.4" v-for="(item, index) in typeList" :key="index">
                        <v-btn
                          class="px-1"
                          :color="item.id === config.typeid ? 'primary' : ''"
                          @click="setType(item.type)"
                          block
                          outlined
                          >{{ item.disp_name }}</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row class="d-flex align-center py-1" no-gutters>
                      <v-col> 表示ユーザー </v-col>
                    </v-row>
                    <v-row class="d-flex align-center py-1" no-gutters>
                      <v-col>
                        <v-menu bottom right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn outlined color="accent2" v-bind="attrs" v-on="on" dense>
                              <span>{{
                                view_group_list.find(
                                  x => x.id === config.view_group_id && x.type === config.view_group_type
                                ).group_name
                              }}</span>
                              <v-icon right> mdi-menu-down </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(item, index) in view_group_list"
                              :key="index"
                              @click="selectGroup(item)"
                            >
                              <v-list-item-title>{{ item.group_name }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <v-btn
                          fab
                          text
                          small
                          color="accent2"
                          v-show="config.view_group_id === -1 && config.view_group_type === 0"
                          @click="selectUser"
                        >
                          <v-icon small> mdi-account-multiple </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex align-center py-1" no-gutters>
                      <v-col>
                        <div style="overflow: scroll; max-height: 200px; overflow-y: auto; overflow-x: hidden">
                          <v-chip
                            label
                            small
                            v-for="(item, index) in getDispUsers2()"
                            :key="index"
                            :color="colors[index]"
                            :text-color="textcolors[index]"
                            dark
                            >{{ getUserName2(item.type, item.id) }}</v-chip
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="pa-0">
            <v-row class="d-flex align-center py-1" no-gutters>
              <v-col>
                <v-toolbar flat>
                  <v-btn outlined class="mr-4" color="accent2" @click="setToday"> 今日 </v-btn>
                  <v-btn fab text small color="accent2" @click="prev2">
                    <v-icon small> mdi-chevron-double-left </v-icon>
                  </v-btn>
                  <v-btn fab text small color="accent2" @click="prev">
                    <v-icon small> mdi-chevron-left </v-icon>
                  </v-btn>
                  <v-btn fab text small color="accent2" @click="next">
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                  <v-btn fab text small color="accent2" @click="next2">
                    <v-icon small> mdi-chevron-double-right </v-icon>
                  </v-btn>
                </v-toolbar>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </template>
      <template v-else>
        <v-sheet ref="header" class="schedule-frame" height="60">
          <v-toolbar class="schedule-frame" flat>
            <v-btn :to="path" tile text class="pl-0">
              <v-icon v-if="getDispStyle(config.dispStyle).usePageIcon">mdi-calendar</v-icon>
              <div class="text-decoration-underline text-body-1">スケジュール</div>
            </v-btn>
            <v-btn outlined class="mr-2" color="accent2" @click="setToday"> 今日 </v-btn>
            <v-btn fab text small color="accent2" @click="prev2">
              <v-icon small> mdi-chevron-double-left </v-icon>
            </v-btn>
            <v-btn fab text small color="accent2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="accent2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-btn fab text small color="accent2" @click="next2">
              <v-icon small> mdi-chevron-double-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar" style="min-width: 120px">
              <!-- <v-btn outlined class="mr-4" color="accent2" @click="setToday"> -->
              {{ new Date(this.focus).getFullYear() + '年' + (new Date(this.focus).getMonth() + 1) + '月' }}
              <!-- </v-btn> -->
            </v-toolbar-title>

            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="accent2" v-bind="attrs" v-on="on" dense>
                  <span>{{
                    view_group_list.find(x => x.id === config.view_group_id && x.type === config.view_group_type)
                      .group_name
                  }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in view_group_list" :key="index" @click="selectGroup(item)">
                  <v-list-item-title>{{ item.group_name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
              fab
              text
              small
              color="accent2"
              v-show="config.view_group_id === -1 && config.view_group_type === 0"
              @click="selectUser"
            >
              <v-icon small> mdi-account-multiple </v-icon>
            </v-btn>
            <!-- <div>
            <v-chip :color="item.color">{{ item.disp_name + 'test' }}</v-chip>
          </div> -->
            <div style="overflow: scroll; max-height: 60px; overflow-y: auto; overflow-x: hidden" class="ml-2 mr-2">
              <v-chip
                label
                small
                v-for="(item, index) in getDispUsers2()"
                :key="index"
                :color="colors[index]"
                :text-color="textcolors[index]"
                dark
                >{{ getUserName2(item.type, item.id) }}</v-chip
              >
            </div>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="accent2" v-bind="attrs" v-on="on">
                  <span>{{ typeList.find(x => x.id === config.typeid).disp_name }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in typeList" :key="index" @click="setType(item.type)">
                  <v-list-item-title>{{ item.disp_name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
      </template>
      <v-sheet v-resize="onResize" class="schedule-frame-bottom" :height="calendarHeight">
        <v-calendar
          ref="calendar"
          v-model="focus"
          class="schedule-frame-bottom"
          color="primary"
          locale="ja-jp"
          :events="events"
          :event-color="getEventColor"
          :event-text-color="getEventTextColor"
          :type="config.type"
          :day-format="date => new Date(date.date).getDate()"
          :weekday-format="weekdayFormat"
          :month-format="date => new Date(date.date).getMonth() + 1 + ' /'"
          :interval-format="interval => interval.time"
          :weekdays="getWeekdays()"
          :categories="categories"
          :category-show-all="true"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @click:day="createSchedule"
          @click:time="createSchedule"
          @change="updateRange"
        >
          <template v-slot:day-body="{ date, week }">
            <div class="v-current-time" :class="{ first: date === week[0].date }" :style="{ top: nowY }"></div>
          </template>

          <template v-slot:day-label-header="item">
            <!-- 現在 -->
            <v-avatar
              :ref="'week_dom_' + getDayColor(item.date) + '_' + new Date(item.date).getDate()"
              color="primary"
              size="32"
              v-show="item.present"
            >
              <v-btn color="white" icon>{{ getDayFromat(item.date) }}</v-btn>
            </v-avatar>
            <!-- 過去or未来 -->
            <v-btn
              :ref="'week_dom_' + getDayColor(item.date) + '_' + new Date(item.date).getDate()"
              v-show="!item.present"
              :color="getDayColor(item.date)"
              icon
              >{{ getDayFromat(item.date) }}</v-btn
            >
            <div class="pa-0 ma-0 text-subtitle-2" style="color: red">
              {{ getDayName(item.date) }}
            </div>
          </template>
          <template v-slot:day-label="item">
            <!-- 現在 -->
            <v-avatar
              :ref="`week_dom_${getDayColor(item.date)}_${new Date(item.date).getDate()}`"
              color="primary"
              size="32"
              v-show="item.present"
            >
              <v-btn color="white" icon>{{ getDayFromat(item.date) }}</v-btn>
            </v-avatar>
            <!-- 過去or未来 -->
            <v-btn
              :ref="`week_dom_${getDayColor(item.date)}_${new Date(item.date).getDate()}`"
              v-show="!item.present"
              :color="getDayColor(item.date)"
              icon
              >{{ getDayFromat(item.date) }}</v-btn
            >
            <div class="pa-0 ma-0 text-subtitle-2" style="color: red">
              {{ getDayName(item.date) }}
            </div>
          </template>
          <template v-slot:event="item">
            <div class="pl-1">
              <span class="v-event-summary">
                <strong>{{ item.event.name }}</strong>
                {{ item.event.timed ? ' ' + item.event.start_time + '-' + item.event.end_time : '' }}
              </span>
            </div>
          </template>
          <!-- <template v-slot:day-month="{}">う</template> -->
          <!-- <template v-slot:day="{}">月の日の下側</template> -->
          <!-- <template v-slot:day-body="{}">お</template> -->
          <!-- <template v-slot:day-header="{}">日のdayの下</template> -->
          <!-- <template v-slot:weekday="{}">き</template> -->
        </v-calendar>
        <custom-dialog v-model="selectedOpen">
          <template v-slot:title>{{ selectedEvent.name }}</template>
          <template v-slot:body>
            <v-container>
              <v-row class="d-flex align-center py-0" no-gutters>
                <v-container d-flex justify-content-end mt-n4 mb-n8 pt-0>
                  <v-spacer />
                  <div class="pa-0 ma-0 overline">
                    ID S{{ ('000' + selectedEvent.repeat_pattern).slice(-3) }}-{{
                      ('00000000' + selectedEvent.id).slice(-6)
                    }}-{{ ('00' + 1).slice(-2) }}
                  </div>
                </v-container>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters v-if="selectedEvent.repeat">
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">繰り返しパターン</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-select
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-repeat' : ''"
                    :items="getRepeatPattern()"
                    item-text="name"
                    item-value="id"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="selectedEvent.repeat_pattern"
                    hide-details="auto"
                    readonly
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">繰り返しパターン</div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row
                class="d-flex align-center py-1"
                no-gutters
                v-if="selectedEvent.repeat && selectedEvent.repeat_pattern == 3"
              >
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">対象週</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-select
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-view-stream' : ''"
                    :items="getRepeatWeek()"
                    item-text="name"
                    item-value="id"
                    multiple
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="selectedEvent.repeat_week"
                    hide-details="auto"
                    readonly
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">対象週</div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row
                class="d-flex align-center py-1"
                no-gutters
                v-if="selectedEvent.repeat && (selectedEvent.repeat_pattern == 2 || selectedEvent.repeat_pattern == 3)"
              >
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">曜日</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-select
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-view-column' : ''"
                    :items="getRepeatWeekDay()"
                    item-text="name"
                    item-value="id"
                    multiple
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="selectedEvent.repeat_weekdays"
                    hide-details="auto"
                    readonly
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">曜日</div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row
                class="d-flex align-center py-1"
                no-gutters
                v-if="selectedEvent.repeat && (selectedEvent.repeat_pattern == 4 || selectedEvent.repeat_pattern == 5)"
              >
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">対象日</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-row>
                    <v-col cols="1"> </v-col>
                    <v-col cols="4" v-if="selectedEvent.repeat_pattern == 5">
                      <v-select
                        item-text="name"
                        item-value="id"
                        :items="getMonthList()"
                        v-model="selectedEvent.repeat_month"
                        suffix="月"
                        :outlined="outlined"
                        :dense="dense"
                        hide-details="auto"
                        readonly
                      >
                        <template v-slot:label>
                          <div v-if="useTitleLabel" class="required">対象月</div>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        item-text="name"
                        item-value="id"
                        :items="getDayList()"
                        v-model="selectedEvent.repeat_day"
                        suffix="日"
                        :outlined="outlined"
                        :dense="dense"
                        hide-details="auto"
                        readonly
                      >
                        <template v-slot:label>
                          <div v-if="useTitleLabel" class="required">対象月</div>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">予定日</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        class="mt-4"
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-calendar' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="selectedEvent.start_date"
                        :readonly="true"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel">開始日</div>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        class="mt-4"
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? '' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="selectedEvent.end_date"
                        v-show="selectedEvent.spand || selectedEvent.repeat"
                        :readonly="true"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel">終了日</div>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="d-flex align-center py-1" v-if="selectedEvent.timed && !selectedEvent.spand" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">予定時刻</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-clock-outline' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="selectedEvent.start_time"
                        :readonly="true"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel">開始時刻</div>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? '' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="selectedEvent.end_time"
                        :readonly="true"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel">終了時刻</div>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">件名</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-text-field
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="selectedEvent.title"
                    :readonly="true"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">件名</div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">場所</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-text-field
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-map-marker' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="selectedEvent.place"
                    :readonly="true"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">場所</div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">内容</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <text-area-view
                    :icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-text-outline' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    :title="getDispStyle(config.dispStyle).useTitleLabel ? '内容' : ''"
                    :text="selectedEvent.details"
                    :minHeight="'0px'"
                  />
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">参加者</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-select
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-account-circle' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    item-text="disp_name"
                    item-value="id"
                    :items="usersList"
                    v-model="selectedEvent.user_data"
                    multiple
                    return-object
                    :readonly="true"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">参加者</div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">設備</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-select
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-puzzle' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    item-text="disp_name"
                    item-value="id"
                    :items="facilityList"
                    v-model="editedEvent.facility_data"
                    multiple
                    return-object
                    :readonly="true"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">設備</div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>

            <v-card class="mx-1 mt-3" color="background_sub">
              <v-container class="px-6 text-body-2">
                <v-row class="pa-0">
                  <v-col class="pa-0 text-subtitle-1">更新情報</v-col>
                </v-row>
                <v-row class="pa-0">
                  <v-col class="pa-0" cols="2">作成者</v-col>

                  <v-col class="pa-0">{{ getUserName(selectedEvent.insertuser) }}</v-col>
                  <v-col class="pa-0 text-right">{{ fullDateFormat(selectedEvent.inserttime) }}</v-col>
                </v-row>
                <v-row><v-divider /></v-row>
                <v-row class="pa-0">
                  <v-col class="pa-0" cols="2">更新者</v-col>

                  <v-col class="pa-0">{{ getUserName(selectedEvent.updateuser) }}</v-col>
                  <v-col class="pa-0 text-right">{{ fullDateFormat(selectedEvent.updatetime) }}</v-col>
                </v-row>
              </v-container>
            </v-card>
          </template>
          <template v-slot:footer>
            <v-btn color="info" v-show="canEditEvent(selectedEvent)" @click="copyEditing">
              {{ selectedEvent.repeat ? 'この日編集' : 'コピー編集' }}
            </v-btn>

            <v-btn color="info" v-show="canEditEvent(selectedEvent)" @click="startEditing">編集</v-btn>
            <v-btn
              v-if="selectedEvent.parent_id == -1"
              color="warning"
              v-show="canDeleteEvent(selectedEvent)"
              @click="deleteData"
              >削除</v-btn
            >
            <v-btn
              v-if="selectedEvent.repeat"
              color="warning"
              v-show="canDeleteEvent(selectedEvent)"
              @click="deleteData2"
              >この日削除</v-btn
            >
            <v-btn
              v-if="selectedEvent.parent_id >= 0"
              color="warning"
              v-show="canDeleteEvent(selectedEvent)"
              @click="deleteData3"
              >削除</v-btn
            >
            <v-btn color="accent" @click="selectedOpen = false"> キャンセル </v-btn>
          </template>
        </custom-dialog>

        <custom-dialog v-model="editedOpen" :persistent="true">
          <template v-slot:title>{{ editedEvent.id == -1 ? 'スケジュール入力' : 'スケジュール編集' }}</template>
          <template v-slot:body>
            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.repeat_pattern" dense
              >『 繰り返しパターン 』を入力してください。
            </v-alert>
            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.repeat_week" dense
              >『 対象週 』を入力してください。
            </v-alert>
            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.repeat_weekdays" dense
              >『 曜日 』を入力してください。
            </v-alert>
            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.repeat_month" dense
              >『 月 』を入力してください。
            </v-alert>
            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.repeat_day" dense
              >『 日 』を入力してください。
            </v-alert>
            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.repeat_range" dense
              >予定の入る日が含まれる様に『 期間 』を指定してください。
            </v-alert>
            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.file" dense
              >『 ファイル 』を入力してください。
            </v-alert>
            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.date" dense
              >『 終了日 』は『 開始日 』以降の日付を指定してください。
            </v-alert>
            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.time" dense
              >『 終了時刻 』は『 開始時刻 』以降の日付を指定してください。
            </v-alert>
            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.title" dense
              >『 件名 』を入力してください。
            </v-alert>
            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.user" dense
              >『 参加者 』を入力してください。
            </v-alert>
            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.start_date" dense
              >『 開始日 』を入力してください。
            </v-alert>
            <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.end_date" dense
              >『 終了日 』を入力してください。
            </v-alert>
            <v-container>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">入力種別</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-row>
                    <v-col cols="4">
                      <v-checkbox
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-show="!editedEvent.repeat"
                        v-model="editedEvent.spand"
                        label="期間指定"
                        hide-details="auto"
                        :disabled="editedEvent.parent_id >= 0"
                      >
                      </v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-show="!editedEvent.spand"
                        v-model="editedEvent.timed"
                        label="時刻指定"
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-show="!editedEvent.spand"
                        v-model="editedEvent.repeat"
                        label="繰り返し"
                        hide-details="auto"
                        :disabled="editedEvent.parent_id >= 0"
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="d-flex align-center py-1" no-gutters v-if="editedEvent.repeat">
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">繰り返しパターン</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-select
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-repeat' : ''"
                    :items="getRepeatPattern()"
                    item-text="name"
                    item-value="id"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="editedEvent.repeat_pattern"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">繰り返しパターン</div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row
                class="d-flex align-center py-1"
                no-gutters
                v-if="editedEvent.repeat && editedEvent.repeat_pattern == 3"
              >
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">対象週</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-select
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-view-stream' : ''"
                    :items="getRepeatWeek()"
                    item-text="name"
                    item-value="id"
                    multiple
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="editedEvent.repeat_week"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">対象週</div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row
                class="d-flex align-center py-1"
                no-gutters
                v-if="editedEvent.repeat && (editedEvent.repeat_pattern == 2 || editedEvent.repeat_pattern == 3)"
              >
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">曜日</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-select
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-view-column' : ''"
                    :items="getRepeatWeekDay()"
                    item-text="name"
                    item-value="id"
                    multiple
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="editedEvent.repeat_weekdays"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">曜日</div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row
                class="d-flex align-center py-1"
                no-gutters
                v-if="editedEvent.repeat && (editedEvent.repeat_pattern == 4 || editedEvent.repeat_pattern == 5)"
              >
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">対象日</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-row>
                    <v-col cols="1"> </v-col>
                    <v-col cols="4" v-if="editedEvent.repeat_pattern == 5">
                      <v-select
                        item-text="name"
                        item-value="id"
                        :items="getMonthList()"
                        v-model="editedEvent.repeat_month"
                        suffix="月"
                        :outlined="outlined"
                        :dense="dense"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="useTitleLabel" class="required">対象月</div>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        item-text="name"
                        item-value="id"
                        :items="getDayList()"
                        v-model="editedEvent.repeat_day"
                        suffix="日"
                        :outlined="outlined"
                        :dense="dense"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="useTitleLabel" class="required">対象月</div>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">予定日</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        class="mt-4"
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-calendar' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="editedEvent.start_date"
                        :readonly="true"
                        @keydown.up="StartDate_UpKeyDown"
                        @keydown.down="StartDate_DownKeyDown"
                        hide-details="auto"
                        clearable
                      >
                        <template v-slot:label>
                          <div
                            v-if="getDispStyle(config.dispStyle).useTitleLabel"
                            v-bind:class="editedEvent.repeat ? '' : 'required'"
                          >
                            開始日
                          </div>
                        </template>

                        <template v-slot:append-outer>
                          <date-picker v-model="editedEvent.start_date" v-if="editedEvent.parent_id == -1" />
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        class="mt-4"
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? '' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="editedEvent.end_date"
                        v-show="editedEvent.spand || editedEvent.repeat"
                        :readonly="true"
                        @keydown.up="EndDate_UpKeyDown"
                        @keydown.down="EndDate_DownKeyDown"
                        hide-details="auto"
                        clearable
                      >
                        <template v-slot:label>
                          <div
                            v-if="getDispStyle(config.dispStyle).useTitleLabel"
                            v-bind:class="editedEvent.repeat ? '' : 'required'"
                          >
                            終了日
                          </div>
                        </template>
                        <template v-slot:append-outer>
                          <date-picker v-model="editedEvent.end_date" v-if="editedEvent.parent_id == -1" />
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters v-if="editedEvent.timed && !editedEvent.spand">
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">予定時刻</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-clock-outline' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="editedEvent.start_time"
                        :readonly="true"
                        @keydown.up="StartTime_UpKeyDown"
                        @keydown.down="StartTime_DownKeyDown"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">開始時刻</div>
                        </template>
                        <template v-slot:append-outer>
                          <time-picker v-model="editedEvent.start_time" />
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? '' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="editedEvent.end_time"
                        :readonly="true"
                        @keydown.up="EndTime_UpKeyDown"
                        @keydown.down="EndTime_DownKeyDown"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">終了時刻</div>
                        </template>
                        <template v-slot:append-outer>
                          <time-picker v-model="editedEvent.end_time" />
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">件名</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-text-field
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="editedEvent.title"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">件名</div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">場所</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-text-field
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-map-marker' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="editedEvent.place"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">場所</div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">内容</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-textarea
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-text-outline' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    v-model="editedEvent.details"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">内容</div>
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">公開レベル</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-select
                    :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-lock' : ''"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    item-text="disp_name"
                    item-value="id"
                    :items="public_level_list"
                    v-model="editedEvent.public_flg"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">公開レベル</div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0 required">参加者</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-row>
                    <v-col :cols="editedDispUsers ? 7 : 8">
                      <v-select
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-account-circle' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        item-text="disp_name"
                        item-value="id"
                        :items="usersList"
                        v-model="editedEvent.user_data"
                        multiple
                        return-object
                        :readonly="true"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel" class="required">参加者</div>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col :cols="editedDispUsers ? 5 : 4"
                      ><v-btn @click="editedDispUsers = !editedDispUsers">{{
                        !editedDispUsers ? '参加者選択' : '選択画面を隠す'
                      }}</v-btn></v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col cols="12">
                  <multi-user-select-list
                    :data="editedEvent.user_data"
                    :label="'参加者選択'"
                    :dispNumber="false"
                    v-show="editedDispUsers"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    :ismobile="ismobile"
                    :useIcon="getDispStyle(config.dispStyle).useIcon"
                    :useTitleLabel="getDispStyle(config.dispStyle).useTitleLabel"
                  />
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
                  <p class="mb-0">設備</p>
                </v-col>
                <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
                  <v-row>
                    <v-col :cols="editedDispFacility ? 7 : 8">
                      <v-select
                        :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-puzzle' : ''"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        item-text="disp_name"
                        item-value="id"
                        :items="facilityList"
                        v-model="editedEvent.facility_data"
                        multiple
                        return-object
                        :readonly="true"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div v-if="getDispStyle(config.dispStyle).useTitleLabel">設備</div>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col :cols="editedDispFacility ? 5 : 4"
                      ><v-btn @click="editedDispFacility = !editedDispFacility">{{
                        !editedDispFacility ? '設備選択' : '選択画面を隠す'
                      }}</v-btn></v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col>
                  <multi-facility-select-list
                    :data="editedEvent.facility_data"
                    :label="'設備選択'"
                    :dispNumber="false"
                    v-show="editedDispFacility"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    :ismobile="ismobile"
                    :useIcon="getDispStyle(config.dispStyle).useIcon"
                    :useTitleLabel="getDispStyle(config.dispStyle).useTitleLabel"
                  />
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:footer>
            <v-btn color="info" @click="endEdit" v-html="editedEvent.id == -1 ? '登録' : '更新'"> </v-btn>
            <v-btn color="accent" @click="editedOpen = false"> キャンセル </v-btn>
          </template>
        </custom-dialog>

        <custom-dialog v-model="selectUserOpen">
          <template v-slot:title>ユーザー選択</template>
          <template v-slot:body>
            <multi-user-facility-select-list
              :data="config.view_sel_users"
              :data2="config.view_sel_facility"
              :label="'閲覧ユーザー'"
              :dispNumber="false"
              :outlined="getDispStyle(config.dispStyle).outlined"
              :dense="dense"
              :ismobile="ismobile"
              :useIcon="getDispStyle(config.dispStyle).useIcon"
              :useTitleLabel="getDispStyle(config.dispStyle).useTitleLabel"
            />
          </template>
          <template v-slot:footer>
            <v-btn color="accent" @click="selectUserClose"> 閉じる </v-btn>
          </template>
        </custom-dialog>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import DatePicker from '../components/DatePicker';
import TimePicker from '../components/TimePicker';
import TextAreaView from '../components/TextAreaView.vue';
import CustomDialog from '../components/CustomDialog.vue';
import dateformat from '../functions/DateFormat';
import dateUtils from '../functions/DateUtils';
import fileOperation from '../../utils/fileOperation';
import token from '../../utils/token';
import inputStyleUtils from '../../utils/inputStyleUtils';

import { mapState, mapActions } from 'vuex';
import MultiUserSelectList from '../components/MultiUserSelectList.vue';
import MultiFacilitySelectList from '../components/MultiFacilitySelectList.vue';
import MultiUserFacilitySelectList from '../components/MultiUserFacilitySelectList.vue';
import scroll from '../../utils/scroll';

export default {
  components: {
    DatePicker,
    TimePicker,
    TextAreaView,
    MultiUserSelectList,
    MultiFacilitySelectList,
    MultiUserFacilitySelectList,
    CustomDialog
  },
  data: () => ({
    name: 'schedule',
    path: '/schedule',
    config: {
      dispStyle: 1,
      typeid: 2,
      type: 'week',
      view_group_id: -1,
      view_group_type: 0,
      view_sel_users: [],
      view_sel_facility: []
    },
    dense: true,
    ismobile: false,
    mobileMenu: false,
    focus: new Date(),
    weekStart: 0,
    typeList: [
      { id: 1, type: 'month', disp_name: '月', offset: 7 },
      { id: 2, type: 'week', disp_name: '週', offset: 7 },
      { id: 3, type: 'day', disp_name: '日', offset: 1 },
      { id: 4, type: '4day', disp_name: '4日', offset: 4 },
      { id: 5, type: 'category', disp_name: '個別', offset: 1 }
    ],
    asneedscroll: true,
    windowSize: {
      x: 0,
      y: 0
    },
    dialogLeft: 0,
    calendarHeight: 600,
    isError: {
      repeat_pattern: false,
      repeat_week: false,
      repeat_weekdays: false,
      repeat_month: false,
      repeat_day: false,
      repeat_range: false,
      file: false,
      date: false,
      time: false,
      title: false,
      user: false,
      start_date: false,
      end_date: false
    },
    view_start: null,
    view_end: null,
    view_group_list: [{ id: -1, type: 0, group_name: 'ユーザー選択' }],
    public_level_list: [
      { id: 0, disp_name: '公開' },
      { id: 1, disp_name: '非公開' },
      { id: 2, disp_name: '非表示' }
    ],
    clickEvent: {},
    selectedEvent: {},
    selectedOpen: false,
    selectedFullScreen: false,
    canEdit: false,
    canDelete: false,
    editedEvent: {},
    editedElement: null,
    editedOpen: false,
    editedFullScreen: false,
    editedDispUsers: false,
    editedDispFacility: false,
    newScheduleTimeUnit: 30,
    selectUserOpen: false,
    dispEventsData: {},
    events: [],
    repeatEvents: [],
    textcolors: [],
    colors: [],
    names: [],
    categories: [],
    initDate: '1900-01-01',
    reday: false
  }),
  mounted() {
    // 時刻の線の同期開始
    this.reday = true;
    this.updateTime();
    this.$refs.calendar.checkChange();
  },
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    },
    reLoading() {
      this.reload();
    },
    $route(to, from) {
      // ルートの変更の検知...
      console.log({ to, from });
      if (to.query.id) {
        this.dispScheduleItem(to.query.id);
      }
    }
  },
  computed: {
    cal() {
      return this.reday ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px';
    },

    ...mapState({
      initLoading: state => state.initLoading,
      reLoading: state => state.reLoading,
      user: state => state.user.user,
      usersList: state => state.user.usersList,
      usergroup: state => state.userGroup.usergroup,
      facilityList: state => state.facility.facility,
      facilitygroup: state => state.facilityGroup.facilityGroup,
      holidayData: state => state.holiday.holidayData,
      userConfig: state => state.userConfig.userconfig,
      schedulesList: state => state.schedules.schedulesList
    })
  },
  mixins: [fileOperation, token, inputStyleUtils, scroll],
  methods: {
    ...mapActions([
      'fetchScheduleList',
      'fetchScheduleItem',
      'fetchUserList',
      'fetchUserGroup',
      'fetchFacility',
      'fetchFacilityGroup',
      'updateSchedule',
      'insertSchedule',
      'getDuplexFacilitySchedule',
      'fetchUserConfig',
      'upsertUserConfig'
    ]),
    init() {
      {
        let config = this.userConfig.find(item => item.user_id === this.getUserId());

        this.config.typeid = config.schedules_sel_mode || this.typeList.find(x => x.type === 'week').id;
        this.config.type = this.typeList.find(x => x.id === this.config.typeid).type;

        this.config.view_sel_users = config.schedules_sel_user_id ?? [this.getUserId()];
        this.config.view_sel_facility = config.schedules_sel_facility_id ?? [];

        if (this.config.view_sel_users.length == 0 && this.config.view_sel_facility.length == 0) {
          this.config.view_sel_users = [this.getUserId()];
        }
        this.config.view_group_id = config.schedules_sel_group_id ?? -1;
        this.config.view_group_type = config.schedules_sel_group_type ?? 0;

        this.config.dispStyle = config.dispStyle ?? 1;
      }
      this.view_group_list = [];
      this.view_group_list.push({
        id: -2,
        type: 0,
        group_name: 'ログインユーザー'
      });
      this.view_group_list.push({
        id: -1,
        type: 0,
        group_name: 'ユーザー選択'
      });
      this.view_group_list.push({ id: 0, type: 0, group_name: 'ユーザー一覧' });
      if (Array.isArray(this.usergroup)) {
        const addArray = this.usergroup.map(x => {
          return { id: x.id, type: 0, group_name: x.group_name };
        });
        this.view_group_list.push(...addArray);
      }
      this.view_group_list.push({ id: 0, type: 1, group_name: '設備一覧' });
      if (Array.isArray(this.facilitygroup)) {
        const addArray = this.facilitygroup.map(x => {
          return { id: x.id, type: 1, group_name: x.group_name };
        });
        this.view_group_list.push(...addArray);
      }
      //存在しないグループを指定している場合は初期化する
      if (
        !this.view_group_list.find(x => x.id === this.config.view_group_id && x.type === this.config.view_group_type)
      ) {
        this.config.view_group_id = -1;
        this.config.view_group_type = 0;
      }

      //カラーパレット生成
      this.createColorPallet();

      //データ取得
      this.updateRange({ start: this.view_start, end: this.view_end });

      if (this.$route.query.id) {
        this.dispScheduleItem(this.$route.query.id);
      }
    },
    reload() {
      //データ取得
      this.updateRange({ start: this.view_start, end: this.view_end });
    },
    viewDay({ date }) {
      this.focus = date;
      this.setType('day');
    },
    mounted() {
      this.$refs.calendar.checkChange();
    },
    getEventColor(event) {
      return event.color;
    },
    getEventTextColor(event) {
      return event.textcolor;
    },
    weekdayFormat(event) {
      let weekday = ['日', '月', '火', '水', '木', '金', '土'];
      if (this.ismobile) {
        return weekday[event.weekday];
      } else {
        return weekday[event.weekday] + '曜日';
      }
    },
    setType(value) {
      this.asneedscroll = this.config.type === 'month' || this.config.type === 'category';
      this.config.type = value;
      this.config.typeid = this.typeList.find(x => x.type === value).id;

      //選択状態を保存する
      this.saveConfig().then(() => {
        this.updateRange({ start: this.view_start, end: this.view_end });
      });
    },
    setToday() {
      this.focus = new Date();
    },
    prev() {
      // this.$refs.calendar.prev();
      let newDate = new Date(this.focus);
      if (this.config.type == 'month') {
        newDate = dateUtils.addMonth(newDate, -1);
      } else {
        newDate = dateUtils.addDay(newDate, -1);
      }
      this.focus = newDate;
    },
    prev2() {
      // this.$refs.calendar.prev();
      let newDate = new Date(this.focus);
      if (this.config.type == 'month') {
        newDate = dateUtils.addYear(newDate, -1);
      } else {
        newDate = dateUtils.addDay(newDate, -7);
      }
      this.focus = newDate;
    },
    next() {
      // this.$refs.calendar.next();
      let newDate = new Date(this.focus);
      if (this.config.type == 'month') {
        newDate = dateUtils.addMonth(newDate, 1);
      } else {
        newDate = dateUtils.addDay(newDate, 1);
      }
      this.focus = newDate;
    },
    next2() {
      // this.$refs.calendar.next();
      let newDate = new Date(this.focus);
      if (this.config.type == 'month') {
        newDate = dateUtils.addYear(newDate, 1);
      } else {
        newDate = dateUtils.addDay(newDate, 7);
      }
      this.focus = newDate;
    },
    getWeekdays() {
      let weekDays = [0, 1, 2, 3, 4, 5, 6];
      if (this.config.type == 'month') {
        //
      } else if (this.config.type == 'week') {
        for (let i = 0; i < new Date(this.focus).getDay(); i++) {
          weekDays.push(weekDays.shift());
        }
      } else if (this.config.type == 'day') {
        //
      } else if (this.config.type == '4day') {
        let days = 1000 * 60 * 60 * 24;
        let dayCount = new Date(this.focus).getTime() / days;
        for (let i = 0; i < dayCount % 4; i++) {
          weekDays.push(weekDays.shift());
        }
      } else {
        //
      }
      return weekDays;
    },
    isDevMode() {
      // return false;
      return location.hostname === 'localhost';
    },
    createColorPallet() {
      const baseColor = [
        'red',
        'indigo',
        'green',
        'pink',
        'blue',
        // 'teal',
        'purple',
        // 'light-blue',
        // 'light-green',
        'orange',
        'deep-purple',
        // 'deep-orange',
        'brown',
        'blue-grey'
      ];
      const extColor = [
        ' darken-2',
        '',
        ' lighten-3',
        ' darken-4',
        ' lighten-5',
        ' darken-1',
        ' lighten-2',
        'darken-3',
        'lighten-4',
        'darken-5',
        'lighten-1'
      ];
      let colors = [];
      for (let i = 0; i < 3; i++) {
        colors.push(
          ...extColor.reduce((a, b) => {
            return [
              ...a,
              ...baseColor.reduce((a2, b2) => {
                return [...a2, b2 + b];
              }, [])
            ];
          }, [])
        );
      }

      this.colors = colors;
      this.textcolors = colors.map(x => (x.indexOf('lighten') >= 0 ? 'grey darken-4' : 'white'));
    },
    getRepeatPattern() {
      const ret = [];
      ret.push({ id: 0, name: 'なし' });
      ret.push({ id: 1, name: '毎日' });
      ret.push({ id: 2, name: '毎週' });
      ret.push({ id: 3, name: '特定の週' });
      ret.push({ id: 4, name: '毎月' });
      ret.push({ id: 5, name: '毎年' });
      return ret;
    },
    getRepeatWeek() {
      const ret = [];
      ret.push({ id: 0, name: '第一' });
      ret.push({ id: 1, name: '第二' });
      ret.push({ id: 2, name: '第三' });
      ret.push({ id: 3, name: '第四' });
      ret.push({ id: 4, name: '第五' });
      return ret;
    },
    getRepeatWeekDay() {
      const ret = [];
      ret.push({ id: 0, name: '日曜日' });
      ret.push({ id: 1, name: '月曜日' });
      ret.push({ id: 2, name: '火曜日' });
      ret.push({ id: 3, name: '水曜日' });
      ret.push({ id: 4, name: '木曜日' });
      ret.push({ id: 5, name: '金曜日' });
      ret.push({ id: 6, name: '土曜日' });
      return ret;
    },
    getMonthList() {
      let result = [];
      for (let month = 1; month < 13; month++) {
        result.push({ id: month, name: '　' + month });
      }
      return result;
    },
    getDayList() {
      let result = [];
      for (let day = 1; day < 32; day++) {
        result.push({ id: day, name: '　' + day });
      }
      return result;
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.clickEvent = event;
        if (this.selectedEvent.repeat) {
          const findEvents = this.repeatEvents.filter(x => x.id == this.selectedEvent.parent_id);
          if (findEvents.length > 0) {
            this.selectedEvent = findEvents[0];
          }
        }

        this.selectedElement = nativeEvent?.target;
        this.editedEvent = JSON.parse(JSON.stringify(this.selectedEvent));

        this.canEdit = this.canEditEvent(this.selectedEvent);
        this.canDelete = this.canDeleteEvent(this.selectedEvent);

        //非公開の予定は表示しない
        if (this.selectedEvent.public_flg >= 1) {
          if (!this.selectedEvent.user_data.find(x => x == this.getUserId())) {
            //alert('NG');
            return;
          }
        }

        // 開始日・終了日が指定なし(1900-01-01)の場合は日付を表示しない（ダイアログボックス閲覧表示時）
        if (this.selectedEvent.start_date == this.initDate) {
          this.selectedEvent.start_date = null;
        }
        if (this.selectedEvent.end_date == this.initDate) {
          this.selectedEvent.end_date = null;
        }

        // 開始日・終了日が指定なし(1900-01-01)の場合は日付を初期表示しない（ダイアログボックス編集表示時）
        if (this.editedEvent.start_date == this.initDate) {
          this.editedEvent.start_date = null;
        }
        if (this.editedEvent.end_date == this.initDate) {
          this.editedEvent.end_date = null;
        }

        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
      };

      this.editedOpen = false;
      this.selectedOpen = false;
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent?.stopPropagation();
    },
    canAddEvent() {
      //新規登録可否
      return this.getPermission(this.TOKEN_FEATURE.SCHEDULE, this.TOKEN_OWNER.ME, this.TOKEN_ACTION.EDIT);
    },
    canEditEvent(event) {
      let result = false;
      if (!event.user_data) return false;

      const mePermission = this.getPermission(this.TOKEN_FEATURE.SCHEDULE, this.TOKEN_OWNER.ME, this.TOKEN_ACTION.EDIT);
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.SCHEDULE,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.EDIT
      );

      //スケジュールの当事者が自分かどうか
      if (event.user_data.find(item => item === this.getUserId())) {
        //自分のスケジュール
        result = mePermission;
      } else {
        //他人のスケジュール
        result = otherPerMission;
      }
      return result;
    },
    canDeleteEvent(event) {
      let result = false;
      if (!event.user_data) return false;

      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.SCHEDULE,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.DELETE
      );
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.SCHEDULE,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.DELETE
      );

      //スケジュールの当事者が自分かどうか
      if (event.user_data.find(item => item === this.getUserId())) {
        //自分のスケジュール
        result = mePermission;
      } else {
        //他人のスケジュール
        result = otherPerMission;
      }
      return result;
    },
    createSchedule(event) {
      console.log(event);

      if (!this.canAddEvent()) {
        return;
      }

      let startEndTime = this.getStartEndTime(event);

      const data = {
        id: -1,
        parent_id: -1,
        user_id: [this.getUserId()],
        facility_id: [],
        title: '',
        place: '',
        details: '',
        spand: false,
        timed: event.time != '',
        start_date: event.date,
        end_date: event.date,
        public_flg: 0,
        repeat: false,
        repeat_pattern: 0,
        repeat_week: [],
        repeat_weekdays: [],
        repeat_month: 0,
        repeat_day: 0,

        insertuser: this.getUserId(),
        inserttime: new Date(),
        updateuser: this.getUserId(),
        updatetime: new Date()
      };
      if (event.time != '') {
        data.start_date += 'T' + startEndTime.start_time + '+09:00';
        data.end_date += 'T' + startEndTime.end_time + '+09:00';
        // data.start_date += 'T' + event.time;
        // data.end_date += 'T' + event.time ;
      }

      let temp = this.convtoEventsItem(data, 0);
      this.editedEvent = temp[0];
      this.startEditing();
    },
    getStartEndTime(e) {
      if (e.time !== '') {
        let time = this.timeSplit(e.time);

        time.mm = time.mm - (time.mm % this.newScheduleTimeUnit);
        let start_time = this.timeJoin(time.hh, time.mm);

        time.mm += this.newScheduleTimeUnit;
        if (time.mm >= 60) {
          time.hh += Math.floor(time.mm / 60);
          time.mm = time.mm % 60;
          if (time.hh >= 24) {
            time.hh = 23;
            time.mm = 59;
          }
        }

        let end_time = this.timeJoin(time.hh, time.mm);

        return { start_time: start_time, end_time: end_time };
      }
      return { start_time: '', end_time: '' };
    },
    copyEditing() {
      // クリックしたイベントをコピー
      this.editedEvent = JSON.parse(JSON.stringify(this.clickEvent));
      if (this.editedEvent.repeat) {
        this.editedEvent.repeat = false;
        this.editedEvent.repeat_pattern = 0;
      } else {
        this.editedEvent.parent_id = -1;
      }
      this.editedEvent.id = -1;

      this.startEditing();
    },

    startEditing() {
      this.errrorReset();
      this.selectedOpen = false;
      this.editedDispUsers = false;
      this.editedDispFacility = false;
      this.editedOpen = true;
    },
    errrorReset() {
      for (var key in this.isError) {
        this.isError[key] = false;
      }
    },
    inputCheck() {
      this.errrorReset();
      // 開始日＆終了日チェック
      if (!this.editedEvent.repeat) {
        this.isError.start_date = this.editedEvent.start_date == null;
        if (this.editedEvent.spand) {
          this.isError.end_date = this.editedEvent.end_date == null;
        }
      }
      //タイトル
      this.isError.title = this.editedEvent.title == '';
      //参加者
      this.isError.user = this.editedEvent.parent_id == -1 && this.editedEvent.user_data.length === 0;
      //日付の相関
      if (this.editedEvent.spand) {
        this.isError.date = new Date(this.editedEvent.start_date) > new Date(this.editedEvent.end_date);
      }
      //時間の相関
      if (this.editedEvent.timed && !this.editedEvent.spand) {
        let start_h = Number(this.editedEvent.start_time.split(':')[0]);
        let start_m = Number(this.editedEvent.start_time.split(':')[1]);
        let end_h = Number(this.editedEvent.end_time.split(':')[0]);
        let end_m = Number(this.editedEvent.end_time.split(':')[1]);
        this.isError.time = start_h > end_h || (start_h === end_h && start_m > end_m);
      }
      //繰り返し指定の場合
      // 繰り返しパターン
      if (this.editedEvent.repeat) {
        this.isError.repeat_pattern = this.editedEvent.repeat_pattern == 0;

        if (this.editedEvent.repeat_pattern == 1) {
          //毎日
        } else if (this.editedEvent.repeat_pattern == 2) {
          //毎週

          this.isError.repeat_weekdays = this.editedEvent.repeat_weekdays.length == 0;
        } else if (this.editedEvent.repeat_pattern == 3) {
          //特定の週

          this.isError.repeat_week = this.editedEvent.repeat_week.length == 0;
          this.isError.repeat_weekdays = this.editedEvent.repeat_weekdays.length == 0;
        } else if (this.editedEvent.repeat_pattern == 4) {
          //毎月
          this.isError.repeat_day = this.editedEvent.repeat_week.repeat_day == 0;
        } else if (this.editedEvent.repeat_pattern == 5) {
          //毎年
          this.isError.repeat_month = this.editedEvent.repeat_week.repeat_month == 0;
          this.isError.repeat_day = this.editedEvent.repeat_week.repeat_day == 0;
        }
        if (this.editedEvent.start_date !== null && this.editedEvent.end_date !== null) {
          //チェックに使用する日付
          const rangeDate = dateUtils.getDateRange(this.editedEvent.start_date, this.editedEvent.end_date);
          console.log(rangeDate);

          this.isError.repeat_range =
            rangeDate.filter(x => {
              const month = dateUtils.getMonth(x);
              const day = dateUtils.getDay(x);
              const weekDay = dateUtils.getWeekday(x);
              const week = Math.ceil(day / 7) - 1;
              //範囲に該当する予定がない
              if (this.editedEvent.repeat_pattern == 1) {
                //毎日
                return true; //無条件でOK
              } else if (this.editedEvent.repeat_pattern == 2) {
                //毎週
                return this.editedEvent.repeat_weekdays.filter(y => y == weekDay).length > 0;
              } else if (this.editedEvent.repeat_pattern == 3) {
                return (
                  this.editedEvent.repeat_week.filter(y => y == week).length > 0 &&
                  this.editedEvent.repeat_weekdays.filter(y => y == weekDay).length > 0
                );
                //特定の週
              } else if (this.editedEvent.repeat_pattern == 4) {
                //毎月
                return this.editedEvent.repeat_day == day;
              } else if (this.editedEvent.repeat_pattern == 5) {
                //毎年
                return this.editedEvent.repeat_month == month && this.editedEvent.repeat_day == day;
              }

              return false;
            }).length == 0;
        }
      }

      for (var key in this.isError) {
        if (this.isError[key]) {
          return false;
        }
      }
      return true;
    },

    endEdit() {
      //入力チェック
      if (!this.inputCheck()) {
        this.doScrollTop();
        return;
      }

      let start_datevalue;
      // 開始日なしの場合、1900年1月1日を開始日に設定する
      if (this.editedEvent.start_date === null) {
        start_datevalue = new Date(this.initDate + 'T' + this.editedEvent.start_time + '+09:00');
      } else {
        start_datevalue = new Date(this.editedEvent.start_date + 'T' + this.editedEvent.start_time + '+09:00');
      }

      let end_datevalue;
      // 終了日なしの場合、1900年1月1日を終了日に設定する
      if (this.editedEvent.end_date === null) {
        end_datevalue = new Date(
          (this.editedEvent.spand || this.editedEvent.repeat ? this.initDate : this.editedEvent.start_date) +
            'T' +
            this.editedEvent.end_time +
            '+09:00'
        );
      } else {
        end_datevalue = new Date(
          (this.editedEvent.spand || this.editedEvent.repeat
            ? this.editedEvent.end_date
            : this.editedEvent.start_date) +
            'T' +
            this.editedEvent.end_time +
            '+09:00'
        );
      }
      const data = {
        id: this.editedEvent.id,
        parent_id: this.editedEvent.parent_id,
        user_id: [],
        facility_id: [],
        title: this.editedEvent.title,
        place: this.editedEvent.place,
        details: this.editedEvent.details,
        spand: this.editedEvent.spand,
        timed: this.editedEvent.timed && !this.editedEvent.spand,
        start_date: start_datevalue,
        end_date: end_datevalue,
        repeat_pattern: this.editedEvent.repeat ? this.editedEvent.repeat_pattern : 0,
        public_flg: this.editedEvent.public_flg || 0,
        workflow_id: this.editedEvent.workflow_id || -1,
        repeat_week1: this.editedEvent.repeat_week.filter(x => x == 0).length > 0,
        repeat_week2: this.editedEvent.repeat_week.filter(x => x == 1).length > 0,
        repeat_week3: this.editedEvent.repeat_week.filter(x => x == 2).length > 0,
        repeat_week4: this.editedEvent.repeat_week.filter(x => x == 3).length > 0,
        repeat_week5: this.editedEvent.repeat_week.filter(x => x == 4).length > 0,
        repeat_sunday: this.editedEvent.repeat_weekdays.filter(x => x == 0).length > 0,
        repeat_monday: this.editedEvent.repeat_weekdays.filter(x => x == 1).length > 0,
        repeat_tuesday: this.editedEvent.repeat_weekdays.filter(x => x == 2).length > 0,
        repeat_wednesday: this.editedEvent.repeat_weekdays.filter(x => x == 3).length > 0,
        repeat_thursday: this.editedEvent.repeat_weekdays.filter(x => x == 4).length > 0,
        repeat_friday: this.editedEvent.repeat_weekdays.filter(x => x == 5).length > 0,
        repeat_saturday: this.editedEvent.repeat_weekdays.filter(x => x == 6).length > 0,
        repeat_month: this.editedEvent.repeat_month,
        repeat_day: this.editedEvent.repeat_day,
        insertuser: this.editedEvent.insertuser,
        inserttime: this.editedEvent.inserttime,
        updateuser: this.getUserId(),
        updatetime: new Date()
      };
      this.editedEvent.user_data.forEach(item => {
        data.user_id.push(item);
      });
      this.editedEvent.facility_data.forEach(item => {
        data.facility_id.push(item);
      });
      //設備の重複チェック
      this.getDuplexFacilitySchedule(data).then(result => {
        if (result.data.length > 0) {
          const msg = 'すでに同じ時間帯に設備が予約されています。スケジュールを登録しますか？';
          if (!window.confirm(msg)) return;
        }

        if (this.editedEvent.id != -1) {
          this.updateSchedule(data).then(() => {
            this.updateRange({ start: this.view_start, end: this.view_end });
          });
        } else {
          data.id = undefined;
          data.insertuser = this.editedEvent.updateuser;
          data.inserttime = this.editedEvent.updatetime;

          this.insertSchedule(data).then(() => {
            this.updateRange({ start: this.view_start, end: this.view_end });
          });
        }

        this.selectedOpen = false;
        this.editedOpen = false;
      });
    },
    cancelEdit() {},
    deleteData() {
      if (!window.confirm('このスケジュールを削除してよろしいですか？')) return;

      const data = {
        id: this.editedEvent.id
      };
      this.$store.dispatch('deleteSchedule', data).then(() => {
        this.updateRange({ start: this.view_start, end: this.view_end });
        this.selectedOpen = false;
        this.editedOpen = false;
      });
    },
    deleteData2() {
      if (!window.confirm('この日のスケジュールを削除してよろしいですか？')) return;

      // クリックしたイベントをコピー
      this.editedEvent = JSON.parse(JSON.stringify(this.clickEvent));
      this.editedEvent.repeat = false;
      this.editedEvent.repeat_pattern = 0;
      this.editedEvent.workflow_id = -1;
      this.editedEvent.id = -1;

      this.editedEvent.user_data = [];
      this.editedEvent.facility_data = [];

      this.endEdit();
    },
    deleteData3() {
      if (!window.confirm('このスケジュールを削除してよろしいですか？')) return;
      // クリックしたイベントを更新
      this.editedEvent = JSON.parse(JSON.stringify(this.clickEvent));
      this.editedEvent.repeat = false;
      this.editedEvent.repeat_pattern = 0;
      this.editedEvent.workflow_id = -1;

      this.editedEvent.user_data = [];
      this.editedEvent.facility_data = [];
      this.endEdit();
    },
    selectUser() {
      this.selectUserOpen = true;
    },
    selectUserClose() {
      this.selectUserOpen = false;
      //選択状態を保存する
      this.saveConfig().then(() => {
        this.updateRange({ start: this.view_start, end: this.view_end });
      });
    },
    selectGroup(group) {
      this.config.view_group_id = group.id;
      this.config.view_group_type = group.type;

      //選択状態を保存する
      this.saveConfig().then(() => {
        this.updateRange({ start: this.view_start, end: this.view_end });
      });
    },
    getDispUsers2() {
      const users = this.getDispUsers();
      const facility = this.getDispFacility();

      return [
        ...users.map(x => {
          return { type: 0, id: x };
        }),
        ...facility.map(x => {
          return { type: 1, id: x };
        })
      ];
    },
    getDispUsers() {
      const mePermission = this.getPermission(this.TOKEN_FEATURE.SCHEDULE, this.TOKEN_OWNER.ME, this.TOKEN_ACTION.VIEW);
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.SCHEDULE,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.VIEW
      );

      const group_id = this.config.view_group_id;
      const loginUserId = this.getUserId();
      let result = [];
      if (this.config.view_group_type === 0) {
        if (group_id === -2) {
          //自分
          result = [this.getUserId()];
        } else if (group_id === -1) {
          result = this.config.view_sel_users.filter(x => this.isUserIdEnable(x));
        } else if (group_id === 0) {
          //全て
          result = this.usersList.filter(x => this.isUserIdEnable(x.id)).map(x => x.id);
        } else if (this.usergroup.find(x => x.id === group_id)) {
          result = this.usergroup.find(x => x.id === group_id).user_id.filter(x => this.isUserIdEnable(x));
        } else {
          result = [];
        }
      } else {
        result = [];
      }

      // グループにログインユーザーが所属している場合は、配列の先頭に置き、一番最初に表示されるようにする。
      if (result.find(id => id === loginUserId)) {
        result = result.filter(id => id !== loginUserId);
        result.unshift(loginUserId);
      }
      return result.filter(x => (mePermission && x === loginUserId) || (otherPerMission && x !== loginUserId));
    },
    getDispFacility() {
      const group_id = this.config.view_group_id;
      if (this.config.view_group_type === 0) {
        if (group_id === -1) {
          return this.config.view_sel_facility?.filter(x => this.isFacilityIdEnable(x));
        }
      } else if (this.config.view_group_type === 1) {
        if (group_id === -1) {
          return [];
        } else if (group_id === 0) {
          //全て
          return this.facilityList.filter(x => this.isFacilityIdEnable(x.id)).map(x => x.id);
        } else if (this.facilitygroup.find(x => x.id === group_id)) {
          return this.facilitygroup.find(x => x.id === group_id).facility_id.filter(x => this.isFacilityIdEnable(x));
        } else {
          return [];
        }
      }
      return [];
    },
    saveConfig() {
      //選択状態を保存する
      let promise = this.fetchUserConfig();
      promise = promise
        .then(() => {
          let config = this.userConfig.find(item => item.user_id === this.getUserId());
          if (!config) {
            config = {};
            config.user_id = this.getUserId();
          }

          config.schedules_sel_group_id = this.config.view_group_id;
          config.schedules_sel_group_type = this.config.view_group_type;
          config.schedules_sel_user_id = this.config.view_sel_users;
          config.schedules_sel_facility_id = this.config.view_sel_facility;
          config.schedules_sel_mode = this.config.typeid;
          return this.upsertUserConfig(config);
        })
        .catch(err => {
          console.log(err);
        });
      promise.catch(err => {
        console.log(err);
      });
      return promise;
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.iconSize = window.innerHeight * 0.1;
      let calendar_top = this.$refs.calendar.$el.getBoundingClientRect().top + window.pageYOffset;
      //footer分を足す
      calendar_top += 40;

      this.ismobile = document.getElementById('schedule').clientWidth < 800;

      if (this.ismobile && this.isMainPage()) {
        this.calendarHeight = Math.max(this.windowSize.y - calendar_top, 200);
      } else {
        this.calendarHeight = Math.max(this.windowSize.y - calendar_top, 600);
      }
      this.dialogLeft = Math.max((this.windowSize.x - 600) / 2, 0);

      this.selectedFullScreen = this.ismobile;
      this.editedFullScreen = this.ismobile;
    },
    isMainPage() {
      return this.$route.path === '/' + this.name;
    },
    onMobileMenuClick() {
      this.mobileMenu = !this.mobileMenu;

      setTimeout(this.onResize, 1);
    },
    updateRange({ start, end }) {
      const events = [];
      if (this.initLoading) {
        //画面初期化中
        return;
      }
      this.view_start = start;
      this.view_end = end;
      const offset = this.typeList.find(x => x.id == this.config.typeid).offset;

      const data = {
        start_date: dateUtils.addDay(dateUtils.getDate(start?.date || dateUtils.getToday()), -offset),
        end_date: dateUtils.addDay(dateUtils.getDate(end?.date || dateUtils.getToday()), offset + 1),
        user_id: this.getDispUsers(),
        facility_id: this.getDispFacility()
      };
      //検索条件を保持
      this.dispEventsData = data;

      console.log(data);
      /*
      'fetchUserList',
      'fetchUserGroup',
      'fetchFacility',
      'fetchFacilityGroup',
      */
      //表示に使用するデータ群を取得
      const p = [];
      // // ユーザー一覧の情報を取得
      // p.push(this.fetchUserList());
      // // ユーザーグループ一覧の情報を取得
      // p.push(this.fetchUserGroup());
      // // 設備一覧の情報を取得
      // p.push(this.fetchFacility());
      // // 設備グループ一覧の情報を取得
      // p.push(this.fetchFacilityGroup());
      // スケジュールを取得
      p.push(this.fetchScheduleList(data));

      Promise.all(p)
        .then(() => {
          //検索条件が変更されていたら
          if (this.dispEventsData != data) {
            //処理をしない
            console.log('skip');
            return;
          }

          const users = this.usersList;
          const facilities = this.facilityList;

          const categories = [];
          //表示するユーザー情報を取得
          this.getDispUsers().forEach(disp_user_id => {
            users.forEach(userItem => {
              if (userItem.id == disp_user_id) {
                categories.push(userItem.disp_name);
              }
            });
          });
          //表示する設備情報を取得
          this.getDispFacility().forEach(disp_facility_id => {
            facilities.forEach(facilityItem => {
              if (facilityItem.id == disp_facility_id) {
                categories.push(facilityItem.disp_name);
              }
            });
          });

          this.categories = categories;

          //表示するスケジュールの一覧を取得
          this.schedulesList.forEach(item => {
            if (this.getDispUsers().find(x => item.user_id.find(y => y === x))) {
              // カテゴリ（個別）表示時、スケジュールのユーザー数分イベントを生成
              let user_category = this.convtoEventsItem(item, 0);
              if (this.config.type == 'category') {
                if (user_category != undefined) {
                  for (var i = 0; i < user_category.length; i++) {
                    events.push(user_category[i]);
                  }
                }
              } else {
                // 同一の予定をはじく （カテゴリ表示以外の時）
                if (this.sameScheduleCheck(events, user_category[0])) {
                  events.push(user_category[0]);
                }
              }
            }
            if (this.getDispFacility().find(x => item.facility_id.find(y => y === x))) {
              // カテゴリ（個別）表示時、スケジュールの設備数分イベントを生成
              let facility_category = this.convtoEventsItem(item, 1);
              if (this.config.type == 'category') {
                if (facility_category != undefined) {
                  for (var j = 0; j < facility_category.length; j++) {
                    events.push(facility_category[j]);
                  }
                }
              } else {
                // 同一の予定をはじく （カテゴリ表示以外の時）
                if (this.sameScheduleCheck(events, facility_category[0])) {
                  events.push(facility_category[0]);
                }
              }
            }
          });
          this.setWeekColors(this.$refs.calendar);
          if (this.asneedscroll || this.config.type == 'category') {
            // this.$refs.calendar.scrollToTime('8:00');
            // type='category'時にscrollToTimeが動作しないため、新規メソッド_scrollToTimeに差替
            // 参照：https://github.com/vuetifyjs/vuetify/issues/14589
            this._scrollToTime('8:00');
            this.asneedscroll = false;
          }
          //TODO:繰り返し予定の本体は表示しない
          this.repeatEvents = events.filter(x => x.repeat && x.id > 0);
          this.events = events.filter(x => !x.repeat || x.id == -1).filter(x => x.visible);
        })
        .catch(err => {
          console.log(err);
        });
    },
    async dispScheduleItem(id) {
      try {
        await this.$router.push(this.name);
        const result = await this.fetchScheduleItem({ id: id });

        let temp = this.convtoEventsItem(result.data, 1);
        const event = temp[0];

        this.selectedEvent = event;
        this.editedEvent = JSON.parse(JSON.stringify(this.selectedEvent));
        this.canEdit = this.canEditEvent(this.selectedEvent);
        this.canDelete = this.canDeleteEvent(this.selectedEvent);
        this.selectedOpen = true;
      } catch (err) {
        console.log(err);
      }
    },
    convtoEventsItem(item, mode) {
      var moment = require('moment');

      const users = this.usersList;
      const facilities = this.facilityList;
      let categories = [];
      const user_data = [];
      const facility_data = [];
      const result = [];

      if (mode === 0) {
        users.forEach(userItem => {
          item.user_id.forEach(userId => {
            if (userId == userItem.id) {
              this.getDispUsers().forEach(disp_user_id => {
                if (userId == disp_user_id) {
                  categories.push(userItem.disp_name);
                }
              });
            }
          });
        });
      } else {
        facilities.forEach(facilityItem => {
          item.facility_id.forEach(facility_id => {
            if (facility_id == facilityItem.id) {
              this.getDispFacility().forEach(disp_facility_id => {
                if (facility_id == disp_facility_id) {
                  categories.push(facilityItem.disp_name);
                }
              });
            }
          });
        });
      }
      if (categories.length == 0) {
        categories.push('');
      }
      // 追加列の対応
      item.public_flg = item.public_flg || 0;

      user_data.push(...(item.user_id ?? []));
      facility_data.push(...(item.facility_id ?? []));

      // 完全非公開の予定の場合、ユーザーを空に設定して見えないようにする
      const hideFlg = item.public_flg == 2 && !user_data.find(x => x == this.getUserId());

      const scheduleName =
        (item.public_flg >= 1 ? '🔒' : '') +
        (user_data.length >= 2 ? '🤝' : '') +
        (item.public_flg == 0 || user_data.find(x => x == this.getUserId()) ? item.title : '予定あり') +
        (facility_data.length > 0 ? '🧰' : '') +
        (item.repeat_pattern > 0 ? '🔄️' : '') +
        '';

      const repeat_week = [];
      if (item.repeat_week1) repeat_week.push(0);
      if (item.repeat_week2) repeat_week.push(1);
      if (item.repeat_week3) repeat_week.push(2);
      if (item.repeat_week4) repeat_week.push(3);
      if (item.repeat_week5) repeat_week.push(4);

      const repeat_weekdays = [];
      if (item.repeat_sunday) repeat_weekdays.push(0);
      if (item.repeat_monday) repeat_weekdays.push(1);
      if (item.repeat_tuesday) repeat_weekdays.push(2);
      if (item.repeat_wednesday) repeat_weekdays.push(3);
      if (item.repeat_thursday) repeat_weekdays.push(4);
      if (item.repeat_friday) repeat_weekdays.push(5);
      if (item.repeat_saturday) repeat_weekdays.push(6);

      for (var i = 0; i < categories.length; i++) {
        result.push({
          id: item.id,
          parent_id: item.parent_id,
          name: scheduleName,
          user_data: user_data,
          facility_data: facility_data,
          title: item.title,
          place: item.place,
          start_date: moment(new Date(item.start_date)).format('YYYY-MM-DD'),
          start_time: moment(new Date(item.start_date)).format('HH:mm'),
          start: new Date(item.start_date),
          end_date: moment(new Date(item.end_date)).format('YYYY-MM-DD'),
          end_time: moment(new Date(item.end_date)).format('HH:mm'),
          end: new Date(item.end_date),
          color: this.colors[this.categories.findIndex(a => a == categories[i])],
          textcolor: this.textcolors[this.categories.findIndex(a => a == categories[i])],
          spand: item.spand,
          timed: item.timed,
          category: categories[i],
          details: item.details,
          public_flg: item.public_flg,
          repeat: item.repeat_pattern > 0,
          repeat_pattern: item.repeat_pattern,
          workflow_id: item.workflow_id,
          repeat_week: repeat_week,
          repeat_weekdays: repeat_weekdays,
          repeat_month: item.repeat_month,
          repeat_day: item.repeat_day,
          insertuser: item.insertuser,
          inserttime: item.inserttime,
          updateuser: item.updateuser,
          updatetime: item.updatetime,
          visible: !hideFlg
        });
      }
      return result;
    },
    facilityCheck(item) {
      return this.schedulesList.filter(x => this.bookingCheck(x, item)).length > 0;
    },
    bookingCheck(x, y) {
      //同じ予定
      if (x.id === y.id) return false;
      //同じ設備を使用していない
      if (!x.facility_id.find(x => y.facility_id.find(y => y === x))) return false;

      if (x.timed && y.timed) {
        const x_start_date = new Date(x.start_date);
        const x_end_date = new Date(x.end_date);
        const y_start_date = new Date(y.start_date);
        const y_end_date = new Date(y.end_date);

        //時間指定の時
        if (x_start_date >= y_start_date && x_start_date < y_end_date) {
          return true;
        } else if (x_end_date > y_start_date && x_end_date <= y_end_date) {
          return true;
        } else {
          return false;
        }
      } else {
        //片方が時刻指定が無い場合は日付のみで比較

        //時刻を切り落とし
        const x_start_date = dateUtils.getDate(x.start_date);
        const x_end_date = dateUtils.getDate(x.end_date);
        const y_start_date = dateUtils.getDate(y.start_date);
        const y_end_date = dateUtils.getDate(y.end_date);

        if (x_start_date >= y_start_date && x_start_date <= y_end_date) {
          return true;
        } else if (x_end_date >= y_start_date && x_end_date <= y_end_date) {
          return true;
        } else {
          return false;
        }
      }
    },
    StartDate_UpKeyDown(e) {
      //上キー
      let event = this.editedEvent;
      if (event.parent_id == -1) {
        event.start_date = this.dateDecrement(event.start_date);
        e.preventDefault(); //イベントcancel
      }
    },
    StartDate_DownKeyDown(e) {
      //下キー
      let event = this.editedEvent;
      if (event.parent_id == -1) {
        event.start_date = this.dateIncrement(event.start_date);
        e.preventDefault(); //イベントcancel
      }
    },
    EndDate_UpKeyDown(e) {
      //上キー
      let event = this.editedEvent;
      event.end_date = this.dateDecrement(event.end_date);
      e.preventDefault(); //イベントcancel
    },
    EndDate_DownKeyDown(e) {
      //下キー
      let event = this.editedEvent;
      event.end_date = this.dateIncrement(event.end_date);
      e.preventDefault(); //イベントcancel
    },
    StartTime_UpKeyDown(e) {
      //上キー
      let event = this.editedEvent;
      event.start_time = this.timeDecrement(event.start_time);
      e.preventDefault(); //イベントcancel
    },
    StartTime_DownKeyDown(e) {
      //下キー
      let event = this.editedEvent;
      event.start_time = this.timeIncrement(event.start_time);
      e.preventDefault(); //イベントcancel
    },
    EndTime_UpKeyDown(e) {
      //上キー
      let event = this.editedEvent;
      event.end_time = this.timeDecrement(event.end_time);
      e.preventDefault(); //イベントcancel
    },
    EndTime_DownKeyDown(e) {
      //下キー
      let event = this.editedEvent;
      event.end_time = this.timeIncrement(event.end_time);
      e.preventDefault(); //イベントcancel
    },
    dateDecrement(datestr) {
      return dateformat.format(dateUtils.addDay(datestr, -1), 'YYYY-MM-DD');
    },
    dateIncrement(datestr) {
      return dateformat.format(dateUtils.addDay(datestr, 1), 'YYYY-MM-DD');
    },
    timeDecrement(timestr) {
      let time = this.timeSplit(timestr);
      let allmm = time.hh * 60 + time.mm;
      allmm += this.newScheduleTimeUnit;
      allmm = allmm % (24 * 60);
      time.hh = Math.floor(allmm / 60);
      time.mm = allmm % 60;
      return this.timeJoin(time.hh, time.mm);
    },
    timeIncrement(timestr) {
      let time = this.timeSplit(timestr);
      let allmm = time.hh * 60 + time.mm;
      allmm += 24 * 60;
      allmm -= this.newScheduleTimeUnit;
      allmm = allmm % (24 * 60);
      time.hh = Math.floor(allmm / 60);
      time.mm = allmm % 60;
      return this.timeJoin(time.hh, time.mm);
    },
    timeSplit(time) {
      let hhmm = time.split(':');
      let hh = parseInt(hhmm[0]);
      let mm = parseInt(hhmm[1]);
      return { hh: hh, mm: mm };
    },
    timeJoin(hh, mm) {
      return ('00' + hh).slice(-2) + ':' + ('00' + mm).slice(-2);
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    getDayFromat(date) {
      var moment = require('moment');
      return moment(new Date(date)).format('D');
    },
    getDayColor(date) {
      var moment = require('moment');
      let dtString = moment(new Date(date)).format('YYYYMMDD');
      if (
        Array.isArray(this.holidayData) &&
        this.holidayData?.some(item => dtString === moment(new Date(item.day_date)).format('YYYYMMDD'))
      ) {
        return 'red';
      }
      switch (new Date(date).getDay()) {
        case 0:
          return 'red';
        case 6:
          return 'blue';
        default:
          return '';
      }
    },
    getDayName(date) {
      var moment = require('moment');
      let dtString = moment(new Date(date)).format('YYYYMMDD');
      if (Array.isArray(this.holidayData)) {
        let dayData = this.holidayData?.find(item => dtString === moment(new Date(item.day_date)).format('YYYYMMDD'));
        if (dayData) {
          return dayData.day_name;
        }
      }
    },
    setWeekColors(calendar) {
      for (var day = 1; day < 32; day++) {
        ['red', 'blue'].forEach(color => {
          let week_dom_red = this.$refs[`week_dom_${color}_${day}`];
          if (week_dom_red) {
            let element = week_dom_red.$el;
            let parent_node = element.parentNode.parentNode;
            parent_node.style.color = color;
            [...parent_node.childNodes].forEach(item => {
              item.style.color = color;
            });
          }
        });
      }
      if (this.config.type === 'month') {
        let calendar_node = calendar.$el;
        let header_node = [...calendar_node.childNodes].find(item => item.className == 'v-calendar-weekly__head');
        [...header_node.childNodes].forEach(item => {
          // item.style.backgroundColor = 'red';
          if (item.innerText === '日曜日') {
            item.style.color = 'red';
          } else if (item.innerText === '土曜日') {
            item.style.color = 'blue';
          } else {
            item.style.color = 'black';
          }
        });
      }
    },
    getUserName2(type, id) {
      if (type == 0) {
        //ユーザー
        return this.getUserName(id);
      } else {
        //設備
        return this.getFacilityName(id);
      }
    },
    getUserName(id) {
      if (!id) return '---';
      let item = this.usersList.find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    getFacilityName(id) {
      if (!id) return '---';
      let item = this.facilityList.find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    fullDateFormat(date) {
      return dateformat.fullFormat(date);
    },
    isUserIdEnable(user_id) {
      return !!this.usersList.find(y => y.id === user_id && y.enable);
    },
    isFacilityIdEnable(facility_id) {
      return !!this.facilityList.find(y => y.id === facility_id && y.enable);
    },
    // type='category'時にscrollToTimeが動作しないため、新規メソッド_scrollToTimeを作成
    // 参照：https://github.com/vuetifyjs/vuetify/issues/14589
    _scrollToTime(time) {
      if (this.config.type == 'category') {
        const y = this.$refs.calendar.timeToY(time);
        if (y === false || !this.$refs.calendar.$el) {
          return false;
        }
        this.$refs.calendar.$el.scrollTop = y;
      } else {
        this.$refs.calendar.scrollToTime(time);
      }
      return !!this.$refs.calendar.$el.scrollTop;
    },
    updateTime() {
      setInterval(() => this.cal?.updateTimes(), 60 * 1000);
    },
    sameScheduleCheck(events, item) {
      return !events.find(
        x => x.id == item.id && x.parent_id == item.parent_id && x.start.getTime() == item.start.getTime()
      );
    }
  }
};
</script>
<style scoped>
.required::after {
  content: '必須';
  color: red;
  font-size: 12px;
  font-weight: bold;
  min-width: 10px;
  padding: 0px 0px;
  margin: 0px 5px;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
}
.v-btn--active::before {
  opacity: 0 !important;
}

.schedule-frame {
  border-radius: 7px 7px 0px 0px;
}

.schedule-frame-bottom {
  border-radius: 0px 0px 7px 7px;
}
.v-current-time {
  height: 1px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}
</style>
