export default {
  methods: {
    /**
     * バイトを文字としてフォーマットする
     * @param {number} bytes バイト数
     * @param {boolean} si 国際単位を使用するか (閾値を10^3とするか)
     * @param {number} dp 小数点の桁数
     * @returns
     */
    fileSizeFormat(bytes, si = true, dp = 1) {
      const thresh = si ? 1000 : 1024;
      const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

      // nullまたはundefined
      if (bytes == null) {
        return '---';
      }

      if (Math.abs(bytes) < thresh) {
        return bytes + 'B';
      }

      let unitIndex = -1;
      const r = 10 ** dp;
      // 1度は処理をおこないたい為do while
      do {
        bytes /= thresh;
        ++unitIndex;
      }
      while (Math.round(Math.abs(bytes) * r) / r >= thresh && unitIndex < units.length - 1);

      return bytes.toFixed(dp) + units[unitIndex];
    }
  }
}