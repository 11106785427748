import axios from 'axios';

const webApiUrl = process.env.VUE_APP_API_URL_FILE;
const timeout = process.env.VUE_APP_API_TIMEOUT_MSEC;

const file = axios.create({
  baseURL: webApiUrl,
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  timeout: timeout
});

// リクエストログ
file.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    console.error(error);
    return Promise.reject(error);
  }
);

// レスポンスログ
file.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.error(error);
    return Promise.reject(error);
  }
);

/** Authorizationを付与 */
file.interceptors.request.use(config => {
  const accessToken = localStorage.getItem('token');
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

export default file;
