<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="2">
        <v-sheet rounded="lg">
          <v-list class="mx-2">
            <v-list-item-group v-model="selected" color="indigo">
              <v-btn color="primary" block dark>新規申請</v-btn>
              <v-divider class="my-3"></v-divider>
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>
      </v-col>

      <v-col cols="12" md="10">
        <v-sheet rounded="lg" height="80vh">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="タイトル"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
            :page.sync="page"
            hide-default-footer
            class="ma-2"
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-dialog
                v-model="dialog"
                :retain-focus="false"
                persistent
                max-width="800px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on">{{ item.name }}</a>
                </template>
                <v-card>
                  <v-card-title
                    :style="{
                      background: $vuetify.theme.themes.light.primary
                    }"
                  >
                    <span style="color: white" class="text-h5">
                      {{ item.name }}
                    </span>
                  </v-card-title>
                  <v-card-text class="px-0 ma-0">
                    <!-- 週報のフォーマット -->
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="開始日"
                            v-model="startDate"
                            :readonly="true"
                          >
                            <template v-slot:append-outer>
                              <DatePicker v-model="startDate" />
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="終了日"
                            v-model="endDate"
                            :readonly="true"
                          >
                            <template v-slot:append-outer>
                              <DatePicker v-model="endDate" />
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-textarea
                        prepend-inner-icon="mdi-comment"
                        name="txt1"
                        label="参画プロジェクト"
                        outlined
                        required
                      ></v-textarea>
                      <v-textarea
                        prepend-inner-icon="mdi-comment"
                        name="txt2"
                        label="プロジェクト状況"
                        outlined
                        required
                      ></v-textarea>

                      <v-textarea
                        prepend-inner-icon="mdi-comment"
                        name="txt3"
                        label="今週実績"
                        outlined
                        required
                      ></v-textarea>

                      <v-text-field
                        prepend-icon="mdi-timer-outline"
                        suffix="h"
                        label="作業工数"
                      />

                      <v-textarea
                        prepend-inner-icon="mdi-comment"
                        name="txt4"
                        label="次週予定"
                        outlined
                        required
                      ></v-textarea>

                      <v-textarea
                        prepend-inner-icon="mdi-comment"
                        name="txt5"
                        label="その他"
                        outlined
                        required
                      ></v-textarea>

                      <v-textarea
                        prepend-inner-icon="mdi-comment"
                        name="txt6"
                        label="事後申請理由"
                        outlined
                        required
                      ></v-textarea>

                      <v-file-input
                        chips
                        multiple
                        label="添付ファイル"
                      ></v-file-input>

                      <v-select
                        prepend-icon="mdi-account-circle"
                        label="参加者"
                        item-text="disp_name"
                        item-value="login_id"
                        :items="[]"
                        multiple
                        return-object
                      />
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog = false" class="mr-2">閉じる</v-btn>
                    <v-btn color="primary" @click="dialog = false">提出</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <v-chip :color="getColor(item.status)" dark>
                {{ getStatus(item.status) }}
              </v-chip>
            </template>
          </v-data-table>

          <v-pagination
            class="mt-2"
            v-model="page"
            :length="pageCount"
            :total-visible="7"
            circle
          ></v-pagination>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from '../components/DatePicker.vue';
import moment from 'moment';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      dialog: false,
      page: 1,
      pageCount: 0,
      startDate: moment()
        .startOf('isoWeek')
        .add(1, 'd')
        .toISOString()
        .substring(0, 10),
      endDate: moment().endOf('isoWeek').toISOString().substring(0, 10),
      items: [
        {
          text: '受信した申請'
        },
        {
          text: '送信した申請'
        }
      ],
      selected: 0,
      search: '',
      headers: [
        { text: 'タイトル', align: 'start', value: 'name' },
        { text: '状況', value: 'status', filterable: false, width: '10%' },
        {
          text: '決裁者',
          value: 'authorizer',
          filterable: false,
          width: '15%'
        },
        {
          text: '申請日',
          value: 'requestDate',
          filterable: false,
          width: '10%'
        }
      ],
      desserts: [
        {
          id: 1,
          name: '出勤明細兼交通費申請',
          status: 1,
          authorizer: '〇〇〇さん',
          requestDate: '2022年5月2日'
        },
        {
          id: 2,
          name: '出勤明細兼交通費申請',
          status: 3,
          authorizer: '〇〇〇さん',
          requestDate: '2022年5月2日'
        }
      ],
      status: { 1: '進行中', 2: '差し戻し', 3: '完了', 4: '否認' }
    };
  },
  methods: {
    aa(e) {
      console.log(e);
    },
    getColor(calories) {
      switch (calories) {
        case 1:
          return 'blue';
        case 2:
          return 'orange';
        case 3:
          return 'green';
        case 4:
          return 'red';
      }
    },
    getStatus(calories) {
      return this.status[calories];
    }
  }
};
</script>

<style lang="scss">
// .v-data-table {
//   td {
//     background: #f7fafd;
//   }
//   tr:nth-child(odd) td {
//     background: #fff;
//   }

//   tr:hover td {
//     background-color: #eee;
//   }
// }
</style>
