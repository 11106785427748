<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-btn large icon v-on="on">
        <v-icon size="30" color="#757575">mdi-palette</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-row justify="space-between">
          <v-btn class="accent ma-2" @click="closeMenu()">戻る</v-btn>
          <v-btn class="info ma-2" @click="registerColorTheme()">登録</v-btn>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content
          ><v-list-item-title class="font-weight-bold">
            Dark Mode</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-action
          ><v-switch v-model="$vuetify.theme.dark" />
        </v-list-item-action>
      </v-list-item>
      <v-divider />
      <v-card-text>
        <v-card
          class="my-2"
          @click="setTheme(theme)"
          hover
          outlined
          v-for="(theme, index) in themes"
          :key="index"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ theme.name }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action>
              <v-avatar
                color="success"
                size="30"
                v-model="selectedTheme"
                v-if="selectedTheme.name === theme.name"
              >
                <v-icon>mdi-check</v-icon>
              </v-avatar>
            </v-list-item-action>
          </v-list-item>
          <div class="my-2">
            <v-chip
              class="mx-1"
              label
              :color="theme.light[key]"
              v-for="(key, index) in Object.keys(theme.dark)"
              :key="index"
            >
              {{ key }}</v-chip
            >
          </div>
          <div class="my-2">
            <v-chip
              class="mx-1"
              label
              :color="theme.dark[key]"
              v-for="(key, index) in Object.keys(theme.light)"
              :key="index"
            >
              {{ key }}</v-chip
            >
          </div>
        </v-card>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import token from '../../../utils/token';

export default {
  name: 'ThemeChanger',
  data: () => ({
    menu: false,
    selectedTheme: {},
    themes: []
    // （開発用）シードデータに登録するカラーテーマの配色を画面上で調整するために使う
    // themes: [
    //   {
    //     name: 'Theme 1',
    //     light: {
    //       primary: '#1565C0',
    //       secondary: '#424242',
    //       accent: '#A0A0A0',
    //       accent2: '#616161',
    //       success: '#4CAF50',
    //       info: '#1565C0',
    //       warning: '#C62828',
    //       error: '#C62828',
    //       background: '#e0e0e0',
    //       background_sub: '#F5F5F5'
    //     },
    //     dark: {
    //       primary: '#484848',
    //       secondary: '#03DAC6',
    //       accent: '#A1887F',
    //       accent2: '#BCAAA4',
    //       success: '#4CAF50',
    //       info: '#26A69A',
    //       warning: '#C62828',
    //       error: '#B00020',
    //       background: '#616161',
    //       background_sub: '#757575'
    //     }
    //   },
    //   {
    //     name: 'Theme 2',
    //     light: {
    //       primary: '#e58917',
    //       secondary: '#ff9800',
    //       accent: '#A0A0A0',
    //       accent2: '#616161',
    //       success: '#4CAF50',
    //       info: '#e58917',
    //       warning: '#C62828',
    //       error: '#C62828',
    //       background: '#e0e0e0',
    //       background_sub: '#F5F5F5'
    //     },
    //     dark: {
    //       primary: '#FFA726',
    //       secondary: '#ff9800',
    //       accent: '#A1887F',
    //       accent2: '#BCAAA4',
    //       success: '#4CAF50',
    //       info: '#e58917',
    //       warning: '#C62828',
    //       error: '#B00020',
    //       background: '#616161',
    //       background_sub: '#757575'
    //     }
    //   }
    // ]
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      usersList: state => state.user.usersList,
      usergroup: state => state.userGroup.usergroup,
      userConfig: state => state.userConfig.userconfig,
      position: state => state.position.positionData,
      colorThemes: state => state.colorTheme.colorThemes
    })
  },
  mixins: [token],
  methods: {
    ...mapActions([
      'fetchUserList',
      'fetchColorThemes',
      'fetchUserConfig',
      'insertUserConfig',
      'updateUserConfig'
    ]),
    init() {
      const p = [];
      p.push(this.fetchColorThemes());
      Promise.all(p).then(() => {
        this.themes = this.colorThemes;
      });
    },
    setTheme(theme) {
      this.selectedTheme = theme;
      const name = theme.name;
      const dark = theme.dark;
      const light = theme.light;
      // set themes
      Object.keys(dark).forEach(i => {
        this.$vuetify.theme.themes.dark[i] = dark[i];
      });
      Object.keys(light).forEach(i => {
        this.$vuetify.theme.themes.light[i] = light[i];
      });

      this.$vuetify.theme.themes.name = name;
    },
    registerColorTheme() {
      const selectedTheme = JSON.parse(JSON.stringify(this.selectedTheme));
      if (Object.keys(selectedTheme).length === 0) {
        window.confirm('カラーテーマが選択されていません。');
        return;
      }
      const DARK_MODE_ID = 2;
      const LIGHT_MODE_ID = 1;
      const data = {
        id: this.getUserId(),
        color_theme_id: selectedTheme.theme_id,
        color_sub_theme_id: this.$vuetify.theme.dark
          ? DARK_MODE_ID
          : LIGHT_MODE_ID
      };

      this.updateUserConfig(data);
      this.menu = false;
    },
    closeMenu() {
      this.menu = false;
    }
  }
};
</script>
