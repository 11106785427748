<template>
  <div class="pa-0 ma-0">
    <v-row
      class="d-flex align-center py-1"
      v-for="(item, index) in rows"
      :key="index"
      no-gutters
    >
      <v-col :cols="disp_col" v-show="!useTitleLabel">
        <div :class="!readonly && item.required ? 'required' : ''">
          {{ item.name }}
        </div>
      </v-col>
      <v-col :cols="useTitleLabel ? 12 : data_col">
        <template v-if="item.type === 'date_span'">
          <v-row>
            <v-col>
              <v-text-field
                :ref="item.name + '_start'"
                class="py-0"
                v-model="data[index].value"
                :prepend-icon="useIcon ? item.icon : ''"
                :readonly="true"
                :outlined="outlined"
                :dense="dense"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="useTitleLabel">
                    {{ item.name + '(開始日)' }}
                  </div>
                  <div v-else>(開始日)</div>
                </template>
                <template v-slot:append-outer v-if="!readonly">
                  <date-picker v-model="data[index].value" />
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :ref="item.name + '_end'"
                class="py-0"
                v-model="data[index].value2"
                :readonly="true"
                :outlined="outlined"
                :dense="dense"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="useTitleLabel">
                    {{ item.name + '(終了日)' }}
                  </div>
                  <div v-else>(終了日)</div>
                </template>

                <template v-slot:append-outer v-if="!readonly">
                  <date-picker v-model="data[index].value2" />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-else-if="item.type === 'VTextField'">
          <component
            :ref="item.name"
            :is="item.type"
            :counter="item.counter > 0 ? item.counter : false"
            v-model="data[index].value"
            :suffix="item.unit"
            :style="`max-width: ${Math.max(
              item.counter > 0 ? item.counter * 3 : 100,
              30
            )}%;`"
            :class="item.validation === 'number' ? 'right-input' : 'left-input'"
            :prepend-icon="useIcon ? item.icon : ''"
            :readonly="readonly"
            :outlined="outlined"
            :dense="dense"
            hide-details="auto"
          >
            <template v-slot:label>
              <div
                v-if="useTitleLabel"
                :class="!readonly && item.required ? 'required' : ''"
              >
                {{ item.name }}
              </div>
            </template>
          </component>
        </template>
        <template v-else-if="item.type === 'VTextarea'">
          <component
            v-if="!readonly"
            :ref="item.name"
            :is="item.type"
            :counter="item.counter > 0 ? item.counter : false"
            :rows="item.rows"
            v-model="data[index].value"
            :prepend-icon="useIcon ? item.icon : ''"
            :outlined="outlined"
            :dense="dense"
            hide-details="auto"
          >
            <template v-slot:label>
              <div
                v-if="useTitleLabel"
                :class="!readonly && item.required ? 'required' : ''"
              >
                {{ item.name }}
              </div>
            </template>
          </component>

          <text-area-view
            v-else
            :text="data[index].value"
            :minHeight="'0px'"
            :outlined="outlined"
            :icon="useIcon ? item.icon : ''"
            :dense="dense"
            :title="useTitleLabel ? item.name : ''"
            hide-details="auto"
          />
        </template>
        <template v-else-if="item.type === 'VSelect'">
          <v-select
            :ref="item.name"
            :items="getSelectList(item)"
            item-text="name"
            item-value="id"
            v-model="data[index].value"
            :prepend-icon="useIcon ? item.icon : ''"
            :readonly="readonly"
            :outlined="outlined"
            :dense="dense"
            hide-details="auto"
          >
            <template v-slot:label>
              <div
                v-if="useTitleLabel"
                :class="!readonly && item.required ? 'required' : ''"
              >
                {{ item.name }}
              </div>
            </template>
          </v-select>
        </template>
        <template v-else-if="item.type === 'DatePicker'">
          <v-row>
            <v-col cols="6">
              <template v-if="!readonly">
                <v-text-field
                  :ref="item.name"
                  v-model="data[index].value"
                  :prepend-icon="useIcon ? item.icon : ''"
                  :readonly="true"
                  :outlined="outlined"
                  :dense="dense"
                  hide-details="auto"
                >
                  <template v-slot:label>
                    <div
                      v-if="useTitleLabel"
                      :class="!readonly && item.required ? 'required' : ''"
                    >
                      {{ item.name }}
                    </div>
                  </template>

                  <template v-slot:append-outer>
                    <date-picker
                      v-model="data[index].value"
                      v-if="!readonly && !data[index].value2"
                    />
                  </template>
                </v-text-field>
              </template>
              <template v-else>
                <v-text-field
                  :ref="item.name"
                  :value="data[index].value2 ? '指定しない' : data[index].value"
                  :prepend-icon="useIcon ? item.icon : ''"
                  :readonly="true"
                  :outlined="outlined"
                  :dense="dense"
                  hide-details="auto"
                >
                  <template v-slot:label>
                    <div
                      v-if="useTitleLabel"
                      :class="!readonly && item.required ? 'required' : ''"
                    >
                      {{ item.name }}
                    </div>
                  </template>
                </v-text-field>
              </template>
            </v-col>
            <v-col cols="6">
              <template v-if="!readonly">
                <v-checkbox
                  v-if="item.dt_empty"
                  label="指定しない"
                  v-model="data[index].value2"
                  @change="dtcheckbox_change(data[index])"
                />
              </template>
            </v-col>
          </v-row>
        </template>
        <template v-else-if="item.type === 'TimePicker'">
          <v-row>
            <v-col cols="6">
              <v-text-field
                :ref="item.name"
                v-model="data[index].value"
                :prepend-icon="useIcon ? item.icon : ''"
                :readonly="true"
                :outlined="outlined"
                :dense="dense"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div
                    v-if="useTitleLabel"
                    :class="!readonly && item.required ? 'required' : ''"
                  >
                    {{ item.name }}
                  </div>
                </template>

                <template v-slot:append-outer>
                  <time-picker v-model="data[index].value" v-if="!readonly" />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6"> </v-col>
          </v-row>
        </template>
        <template v-else-if="item.type === 'date_month'">
          <v-row>
            <v-col cols="5">
              <v-select
                :ref="item.name + '_year'"
                :items="getYearList()"
                item-text="name"
                item-value="id"
                v-model="data[index].value"
                :prepend-icon="useIcon ? item.icon : ''"
                :readonly="readonly"
                suffix="年"
                :outlined="outlined"
                :dense="dense"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div
                    v-if="useTitleLabel"
                    :class="!readonly && item.required ? 'required' : ''"
                  >
                    {{ item.name }}
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                :ref="item.name + '_month'"
                :items="getMonthList()"
                item-text="name"
                item-value="id"
                v-model="data[index].value2"
                :readonly="readonly"
                suffix="月"
                :outlined="outlined"
                :dense="dense"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div
                    v-if="useTitleLabel"
                    :class="!readonly && item.required ? 'required' : ''"
                  >
                    {{ item.name }}
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </template>
        <template v-else
          >未対応形式:{{ item.type }} data1:{{ data[index].value }} data2:{{
            data[index].value2
          }}</template
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import TextAreaView from '../components/TextAreaView.vue';
import VTextField from 'vuetify/lib/components/VTextField';
import VTextarea from 'vuetify/lib/components/VTextarea';
import VSelect from 'vuetify/lib/components/VSelect';
import VIcon from 'vuetify/lib/components/VIcon';
import DatePicker from '../components/DatePicker';
import TimePicker from '../components/TimePicker';
import dateUtils from '../functions/DateUtils';
import dateFormat from '../functions/DateFormat';

export default {
  components: {
    VTextField,
    VTextarea,
    VSelect,
    DatePicker,
    TextAreaView,
    TimePicker,
    VIcon
  },
  name: 'DynamicWorkFlow',
  props: {
    rows: [],
    data: [],
    readonly: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    useIcon: {
      type: Boolean,
      default: true
    },
    useTitleLabel: {
      type: Boolean,
      default: false
    },
    ismobile: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      inputobject: [],
      disp_col: 4,
      data_col: 8
    };
  },
  computed: {
    ...mapState({
      user: state => state.user,
      usersList: state => state.user.usersList,
      filedata: state => state.filedata.filedata,
      userconfig: state => state.userConfig.userconfig
    })
  },

  created: function () {},
  mounted: function () {},
  watch: {
    ismobile(val) {
      this.disp_col = val ? 12 : 4;
      this.data_col = val ? 12 : 8;
    }
  },
  methods: {
    ...mapActions([]),
    onResize() {},
    inputCheck() {
      let errorMessages = [];
      for (var i = 0; i < this.rows.length; i++) {
        if (this.rows[i].required) {
          if (!(this.rows[i].type === 'DatePicker' && this.rows[i].dt_empty)) {
            if (this.data[i].value === '') {
              //必須入力
              let data = `『${this.rows[i].name}』を入力してください。`;
              errorMessages.push(data);
              continue;
            }
          }
        }
        if (this.rows[i].type === 'date_span') {
          //日付の相関チェック
          let start_date = new Date(this.data[i].value);
          let end_date = new Date(this.data[i].value2);
          if (start_date > end_date) {
            let data = `『${this.rows[i].name}』の 終了日 は 開始日 以降の日付を指定してください。`;
            errorMessages.push(data);
            continue;
          }
        }

        if (this.rows[i].validation === 'number') {
          if (!this.isNumber(this.data[i].value)) {
            //数値チェック
            let data = `『${this.rows[i].name}』は数値を入力してください。`;
            errorMessages.push(data);
            continue;
          }
        }
      }
      return errorMessages;
    },
    isNumber(val) {
      var regexp = new RegExp('^[0-9]+(.[0-9]+)?$');
      return regexp.test(val);
    },
    getSelectList(item) {
      try {
        return JSON.parse(item.list_items);
      } catch (error) {
        let result = [];
        let splitArray = item.list_items.split(',');
        for (let i = 0; i < splitArray.length; i++) {
          result.push({ id: i, name: splitArray[i] });
        }
        return result;
      }
    },
    getYearList() {
      let result = [];
      for (let year = 2000; year < 2100; year++) {
        result.push({ id: year, name: '　' + year });
      }
      return result;
    },
    getMonthList() {
      let result = [];
      for (let month = 1; month < 13; month++) {
        result.push({ id: month, name: '　' + month });
      }
      return result;
    },
    dtcheckbox_change(data) {
      data.value = data.value2
        ? ''
        : dateFormat.format(dateUtils.getToday(), 'YYYY-MM-DD');
    }
  }
};
</script>
<style scoped>
.container {
  width: 300px;
}

.left-input input {
  text-align: left;
}

.center-input input {
  text-align: center;
}

.right-input input {
  text-align: right;
  ime-mode: disabled;
}

.required::after {
  content: '必須';
  color: red;
  font-size: 12px;
  font-weight: bold;
  min-width: 10px;
  padding: 0px 0px;
  margin: 0px 5px;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
}
</style>
