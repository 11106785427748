<template>
  <!-- <v-alert
    v-model="alertVisible"
    class="mb-0"
    @input="onAlertInput"
    dismissible
    dense
  >
    プッシュ通知を有効にするにはここをクリック
  </v-alert> -->
  <div>
    <v-card class="mt-2" v-if="alertVisible" elevation="0">
      <v-toolbar class="frame" flat @click="pushMessageStart" dense>
        <div class="text-body-1">
          プッシュ通知を有効にするにはここをクリック
        </div>
        <v-spacer></v-spacer>
        <v-btn tile icon class="pa-0" @click.stop="doMessageClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <custom-dialog v-model="dialogOpen" :persistent="true">
      <template v-slot:title>通知設定</template>
      <template v-slot:body>
        <v-container>
          <template v-if="dialogMode === 0">
            ポップアップされたウインドウの「表示する / 許可 / はい /
            有効にする」などのボタンをクリックして、設定を完了させてください。
          </template>
          <template v-else-if="dialogMode === 1">
            現在ご利用中のブラウザは通知をブロックする設定になっています。有効にする場合はブラウザの設定変更を行う必要があります。
          </template>
          <template v-else-if="dialogMode === 2">
            <v-radio-group v-model="notificationMode">
              <v-radio label="通知する" :value="0" />
              <v-radio label="通知しない" :value="1" />
            </v-radio-group>
          </template>
          <template v-else-if="dialogMode === 3">
            現在ご利用中のブラウザでは通知を利用することができません。
          </template>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="updateNotification">更新</v-btn>
        <v-btn color="accent" @click="closeNotificationDialog"> 閉じる </v-btn>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Workbox } from 'workbox-window';
import token from '../../utils/token';
import CustomDialog from './CustomDialog';

export default {
  data: () => ({
    wb: new Workbox(`${process.env.BASE_URL}service-worker.js`),
    alertVisible: false,
    dialogOpen: false,
    dialogMode: 0,
    notificationMode: 0
  }),
  components: { CustomDialog },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
    ...mapState({
      initLoading: state => state.initLoading,
      dispNotificationDialog: state => state.dispNotificationDialog,
      userConfig: state => state.userConfig.userconfig,
      push_public_key: state => state.systemsettings.push_public_key
    })
  },
  mixins: [token],
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val) {
      if (!val) {
        this.init();
      }
    },
    dispNotificationDialog(val) {
      if (val) {
        this.showNotificationDialog();
        this.setDispNotificationDialog(false);
      }
    }
  },
  methods: {
    ...mapActions([
      'upsertSubscription',
      'deleteSubscription',
      'setDispNotificationDialog'
    ]),
    init() {
      //通知が許可済みかどうか
      const useAlertBer =
        (localStorage.getItem('isPushNotificationsEnabled') || '').length === 0;
      let isPushNotificationsEnabled = JSON.parse(
        (
          localStorage.getItem('isPushNotificationsEnabled') || 'false'
        ).toLowerCase()
      );
      if (isPushNotificationsEnabled) {
        //通知が許可済みの場合、購読処理をする
        this.enablePushMessage();
      } else if (useAlertBer) {
        //まだバーを出したことがない（拒否も許可もされてない想定）場合、購読するかどうかのバーを表示する

        //開発モード or httpsの場合、通知を使う
        if (location.protocol == 'https:' || this.isDevMode()) {
          //通知とサービスワーカーが使用できる場合のみ、プッシュ通知を促す
          if (this.isUseNotification() && this.isUseServiceWorker()) {
            this.alertVisible = true;
          }
        }
      }
    },
    async pushMessageStart() {
      this.alertVisible = false;
      await this.showNotificationDialog();
    },
    async enablePushMessage() {
      //開発モード or httpsの場合、通知を使う
      if (location.protocol == 'https:' || this.isDevMode()) {
        if (await this.askNotificationPermission()) {
          if (await this.registerServiceWorker()) {
            //成功
            return true;
          } else {
            //通知をブロックされているか使用不可
            return false;
          }
        } else {
          //通知をブロックされているか使用不可
          return false;
        }
      } else {
        //通知をブロックされているか使用不可
        return false;
      }
    },

    async disabledPushMessage() {
      return await this.unregisterServiceWorker();
    },
    doMessageClose() {
      this.alertVisible = false;
      localStorage.setItem('isPushNotificationsEnabled', false);
    },
    async registerServiceWorker() {
      // const applicationServerKey = process.env.VUE_APP_PUSH_PUBLIC_KEY;
      const applicationServerKey = this.push_public_key;
      try {
        if (this.isUseServiceWorker()) {
          await this.wb.register();

          const options = {
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey
          };

          // Push通知を購読する
          const reg = await navigator.serviceWorker.ready;
          const pushSubscription = await reg.pushManager.subscribe(options);

          // console.log(JSON.stringify(pushSubscription));
          await this.upsertSubscription({
            user_id: this.getUserId(),
            subscription: JSON.stringify(pushSubscription)
          });

          // // Push通知表示を許可するための確認を表示
          // Notification.requestPermission(permission => {
          //   console.log(permission); // 'default', 'granted', 'denied'
          // });

          console.log('Service Worker registration completed.');
          return true;
        } else {
          console.log('このブラウザはService Workerをサポートしていません。');
          // alert('このブラウザはService Workerをサポートしていません。');
          return false;
        }
      } catch (err) {
        console.log('Service Workerの登録に失敗しました : ' + err);
        // alert('Service Workerの登録に失敗しました。');
        return false;
      }
    },
    async unregisterServiceWorker() {
      try {
        if (this.isUseServiceWorker()) {
          // Push通知の購読を解除する
          const reg = await navigator.serviceWorker.ready;
          const subscription = await reg.pushManager.getSubscription();
          subscription.unsubscribe();

          await this.deleteSubscription({
            subscription: JSON.stringify(subscription)
          });
          return true;
        }
      } catch (err) {
        return false;
      }
      return false;
    },
    async askNotificationPermission() {
      if (this.isUseNotification()) {
        const result = await this.getNotificationPermission();
        if (result !== 'granted') {
          // alert('通知をONにしないとこの機能は使えません。');
          return false;
        }
      } else {
        // alert('このブラウザは通知をサポートしていません。');
        return false;
      }
      return true;
    },
    isUseServiceWorker() {
      return 'serviceWorker' in navigator;
    },
    isUseNotification() {
      return 'Notification' in window;
    },
    async getNotificationPermission() {
      //'default', 'granted', 'denied'
      return Notification.requestPermission();
    },
    async checkNotificationStatus() {
      if (this.isUseNotification() && this.isUseServiceWorker()) {
        const notificationPermission = await this.getNotificationPermission();
        if (notificationPermission !== 'granted') {
          return 1;
        } else {
          return 2;
        }
      } else {
        return 3;
      }
    },
    async isRegistSubscription() {
      //すでに購読があるか
      const reg = await navigator.serviceWorker.ready;
      const subscription = await reg.pushManager.getSubscription();
      return !!subscription;
    },
    async showNotificationDialog() {
      //とりあえず表示する
      this.dialogOpen = true;
      this.dialogMode = 0;
      //通知設定の状態確認（許可ダイアログが出る）
      const result = await this.checkNotificationStatus();
      //画面モード
      this.dialogMode = result;

      if (result) {
        //許可がある場合、すでに購読済か確認する
        const result2 = await this.isRegistSubscription();
        this.notificationMode = result2 ? 0 : 1;
        localStorage.setItem('isPushNotificationsEnabled', result2);
      }
    },
    closeNotificationDialog() {
      this.dialogOpen = false;
    },
    async updateNotification() {
      if (this.notificationMode === 0) {
        const result = await this.enablePushMessage();
        localStorage.setItem('isPushNotificationsEnabled', result);
      } else {
        await this.disabledPushMessage();
        localStorage.setItem('isPushNotificationsEnabled', false);
      }
      this.dialogOpen = false;
    },

    isDevMode() {
      return location.hostname === 'localhost';
    }
  }
};
</script>

