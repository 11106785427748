import http from '../../utils/httpClient';

const state = {
  newsdata: []
};

const mutations = {
  setNewsData(state, newsdata) {
    state.newsdata = newsdata;
  }
};

const actions = {
  async fetchNewsData(context, data) {
    await http.get('newsdata', { params: data }).then(LibraryData => {
      context.commit('setNewsData', LibraryData.data);
    });
  },
  async insertNewsData(context, data) {
    return await http.post('newsdata/create', data);
  },
  async updateNewsData(context, data) {
    return await http.put(`newsdata/${data.id}`, data);
  },
  async updateNewsDataWithFile(context, data) {
    await http.post(`newsdata/update`, data);
  },
  async deleteNewsData(context, data) {
    await http.post(`newsdata/delete`, data);
  }
};

export default {
  state,
  mutations,
  actions
};
