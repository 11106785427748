import http from '../../utils/httpClient';

const state = {
  timecard: []
};

const mutations = {
  setActivity(state, activity) {
    state.activity = activity;
  }
};

const actions = {
  async fetchActivity(context) {
    await http.get('activity').then(res => {
      context.commit('setActivity', res?.data);
      return res?.data;
    });
  },
  async setActivityReaded(context, data) {
    return await http.put(`activity/readed/${data.id}`, data);
  }
  /////////////////////////////////////////////////////////////
};

export default {
  state,
  mutations,
  actions
};
