import http from '../../utils/httpClient';

const state = {
  sessionData: []
};

const mutations = {
  setSessionData(state, sessionData) {
    state.sessionData = sessionData;
  }
};

const actions = {
  async fetchSessionData(context, data) {
    console.log(data);
    await http.get('sessiondata', data).then(SessionData => {
      console.log(SessionData.data);
      context.commit('setSessionData', SessionData.data);
    });
  },
  async updateSessionData(context, data) {
    await http.put(`sessiondata/${data.id}`, data);
  },
  async insertSessionData(context, data) {
    await http.post('sessiondata', data);
  },
  async deleteSessionData(context, data) {
    await http.delete(`sessiondata/${data.id}`);
  }
};

export default {
  state,
  mutations,
  actions
};
