var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mr-5"},[_c('v-card-title',[_vm._v(" レイアウト設定 "),_c('v-spacer')],1),_c('v-data-table',{staticClass:"ma-4",attrs:{"headers":_vm.getHeaders(),"items":_vm.listitem,"item-key":"id","mobile-breakpoint":0},on:{"click:row":_vm.showEvent},scopedSlots:_vm._u([{key:"item.updatetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.updatetime))+" ")]}}],null,true)}),_c('custom-dialog',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("レイアウト情報")]},proxy:true},{key:"body",fn:function(){return [_c('v-container',[_c('v-row',{staticClass:"d-flex align-center py-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":_vm.getDispStyle(_vm.config.dispStyle).useTitleLabel || _vm.ismobile
                ? 12
                : 8}},[_c('v-text-field',{attrs:{"prepend-icon":_vm.getDispStyle(_vm.config.dispStyle).useIcon
                  ? 'mdi-message-outline'
                  : '',"outlined":_vm.getDispStyle(_vm.config.dispStyle).outlined,"dense":_vm.dense,"readonly":"","hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.getDispStyle(_vm.config.dispStyle).useTitleLabel)?_c('div',[_vm._v(" レイアウト名称 ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.selectedEvent.page_name),callback:function ($$v) {_vm.$set(_vm.selectedEvent, "page_name", $$v)},expression:"selectedEvent.page_name"}})],1)],1),_c('v-row',{staticClass:"align-center py-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":_vm.getDispStyle(_vm.config.dispStyle).useTitleLabel || _vm.ismobile
                ? 12
                : 8}},[(_vm.getDispStyle(_vm.config.dispStyle).useIcon)?_c('v-icon',{staticStyle:{"align-items":"flex-start"},attrs:{"left":""}},[_vm._v("mdi-image")]):_vm._e(),_c('div',[_c('p',{staticClass:"mb-1",staticStyle:{"font-size":"0.9em"}},[_vm._v("レイアウトパターン")]),_c('img',{attrs:{"src":require(("../../../public/images/icon/layout-" + (_vm.selectedEvent.split_type) + ".svg")),"width":"100px","height":"auto"}})])],1)],1),_c('v-row',{staticClass:"align-center py-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":_vm.getDispStyle(_vm.config.dispStyle).useTitleLabel || _vm.ismobile
                ? 12
                : 8}},[(_vm.getDispStyle(_vm.config.dispStyle).useIcon)?_c('v-icon',{staticStyle:{"align-items":"flex-start"},attrs:{"left":""}},[_vm._v("mdi-format-list-numbered")]):_vm._e(),_c('div',{staticStyle:{"width":"100%"}},[_c('p',{staticClass:"mb-1",staticStyle:{"font-size":"0.9em"}},[_vm._v("表示項目")]),_c('v-card',_vm._l((_vm.layout[_vm.selectedEvent.split_type - 1].panels),function(n){return _c('v-list',{key:n,staticClass:"overflow-y-auto ma-3",staticStyle:{"min-height":"max-content"},attrs:{"dense":""}},[_c('p',{staticClass:"pb-1",staticStyle:{"font-size":"0.9em","border-bottom":"1px solid rgba(0, 0, 0, 0.12)"}},[_vm._v(" パネル"+_vm._s(n)+" ")]),_vm._l((_vm.selectedEvent.user_pages_items
                      .filter(function (x) { return x.panel_id === n; })
                      .sort(function (a, b) { return a.row - b.row; })),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.row)+"."+_vm._s(_vm.getComponentName(item.feature))+" ")])],1)],1)})],2)}),1)],1)],1)],1)],1),_c('v-card',{staticClass:"mx-1 mt-3",attrs:{"color":"background_sub"}},[_c('v-container',{staticClass:"px-6 text-body-2"},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0 text-subtitle-1"},[_vm._v("更新情報")])],1),_c('v-row',[_c('v-divider')],1),_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_vm._v("日時")]),_c('v-col',{staticClass:"pa-0 text-right"},[_vm._v(" "+_vm._s(_vm.fullDateFormat(_vm.selectedEvent.updatetime))+" ")])],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.startEditing}},[_vm._v(" 編集 ")]),(_vm.selectedEvent.user_id !== 0)?_c('v-btn',{attrs:{"color":"warning"},on:{"click":_vm.deleteData}},[_vm._v(" 削除 ")]):_vm._e(),_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v(" キャンセル ")])]},proxy:true}]),model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}}),_c('custom-dialog',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("レイアウト設定")]},proxy:true},{key:"body",fn:function(){return [(_vm.isError.duplicate)?_c('v-alert',{staticClass:"pa-0 px-4 py-2 ma-0",attrs:{"type":"error","dense":""}},[_vm._v(" "+_vm._s(_vm.isError.duplicateName.join('と'))+"が複数選択されています。 ")]):_vm._e(),_c('v-container',[_c('v-row',{staticClass:"d-flex align-center py-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":_vm.getDispStyle(_vm.config.dispStyle).useTitleLabel || _vm.ismobile
                ? 12
                : 8}},[_c('v-text-field',{attrs:{"prepend-icon":_vm.getDispStyle(_vm.config.dispStyle).useIcon
                  ? 'mdi-message-outline'
                  : '',"outlined":_vm.getDispStyle(_vm.config.dispStyle).outlined,"dense":_vm.dense,"readonly":"","hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.getDispStyle(_vm.config.dispStyle).useTitleLabel)?_c('div',[_vm._v(" レイアウト名称 ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.editedEvent.page_name),callback:function ($$v) {_vm.$set(_vm.editedEvent, "page_name", $$v)},expression:"editedEvent.page_name"}})],1)],1),_c('v-row',{staticClass:"align-center py-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":_vm.getDispStyle(_vm.config.dispStyle).useTitleLabel || _vm.ismobile
                ? 12
                : 8}},[(_vm.getDispStyle(_vm.config.dispStyle).useIcon)?_c('v-icon',{staticStyle:{"align-items":"flex-start"},attrs:{"left":""}},[_vm._v("mdi-image")]):_vm._e(),_c('div',[_c('p',{staticClass:"mb-1",staticStyle:{"font-size":"0.9em"}},[_vm._v("レイアウトの選択")]),_c('v-btn-toggle',{attrs:{"mandatory":""},on:{"change":_vm.onChange},model:{value:(_vm.editedEvent.split_type),callback:function ($$v) {_vm.$set(_vm.editedEvent, "split_type", $$v)},expression:"editedEvent.split_type"}},_vm._l((_vm.layout),function(n){return _c('v-btn',{key:n.id,staticStyle:{"height":"max-content","padding":"1.5em"}},[_c('img',{attrs:{"src":require(("../../../public/images/icon/layout-" + (n.split_type) + ".svg")),"width":"100px","height":"auto"}})])}),1)],1)],1)],1),_vm._l((_vm.layout[_vm.editedEvent.split_type].panels),function(n){return _c('v-row',{key:n,staticClass:"align-center py-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":_vm.getDispStyle(_vm.config.dispStyle).useTitleLabel || _vm.ismobile
                ? 12
                : 8}},[(_vm.getDispStyle(_vm.config.dispStyle).useIcon)?_c('v-icon',{staticStyle:{"align-items":"flex-start"},attrs:{"left":""}},[_vm._v("mdi-format-list-numbered")]):_vm._e(),_c('div',{staticStyle:{"width":"100%"}},[_c('p',{staticClass:"mb-1",staticStyle:{"font-size":"0.9em"}},[_vm._v(" パネル"+_vm._s(n)+" : 表示項目の選択 ")]),_c('v-card',{staticClass:"d-flex background_sub",staticStyle:{"width":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":6}},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"min-height":"200px","max-height":"200px"},attrs:{"dense":""}},_vm._l((_vm.editedEvent.user_pages_items
                        .filter(function (x) { return x.panel_id === n; })
                        .sort(function (a, b) { return a.row - b.row; })),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.selectComponent(item, n)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(index + 1)+"."+_vm._s(_vm.getComponentName(item.feature))+" ")])],1)],1)}),1)],1),_c('v-col',{attrs:{"cols":6}},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"min-height":"200px","max-height":"200px"},attrs:{"dense":""}},_vm._l((Object.values(_vm.baseComponents)),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.selectComponent(item, n, true)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item)+" ")])],1)],1)}),1)],1)],1)],1)],1)],1)})],2)]},proxy:true},{key:"footer",fn:function(){return [_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.register}},[_vm._v("登録")]),_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.cancelEdit}},[_vm._v(" キャンセル ")])]},proxy:true}]),model:{value:(_vm.editedOpen),callback:function ($$v) {_vm.editedOpen=$$v},expression:"editedOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }