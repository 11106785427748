import http from '../../utils/httpClient';

const state = {
  tagAssignment: []
};

const mutations = {
  setTagAssignment(state, data) {
    state.tagAssignment = data;
  }
};

const actions = {
  async fetchTagAssignment(context) {
    return await http.get('tag_assignment').then(res => {
      console.log(res.data);
      context.commit('setTagAssignment', res.data);
    });
  },
  async updateTagAssignment(context, data) {
    return await http.put(`tag_assignment/${data.id}`, data);
  },
  async insertTagAssignment(context, data) {
    return await http.post('tag_assignment', data);
  },
  async deleteTagAssignment(context, data) {
    await http.delete(`tag_assignment/${data.id}`);
  }
};

export default {
  state,
  mutations,
  actions
};
