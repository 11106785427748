//
https://proglearn.com/2020/02/29/%E3%80%90%E3%82%B5%E3%83%B3%E3%83%97%E3%83%AB%E4%BB%98%E3%81%8D%E3%80%91vuetify-%E3%81%A7-datepicker%E4%BB%98%E3%81%8D%E3%81%AE-%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB/
<template>
  <v-menu v-model="menu" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-btn icon color="primary" dark elevation="0" v-on="on">
        <v-icon>mdi-clock</v-icon>
      </v-btn>
    </template>
    <v-card color="background_sub">
      <v-container>
        <v-row>
          <v-col>
            <v-time-picker
              format="24hr"
              v-model="picker"
              locale="ja-jp"
              :allowed-minutes="allowedMinuteStep"
              @click="menu = false"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card-actions class="justify-center">
              <v-btn color="accent" @click="menu = false"> 閉じる </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  props: {
    value: { type: String, default: '00:00' },
    roundMinute: {
      type: Number,
      default: 1,
      validator: v => {
        return v >= 0 && v < 60;
      }
    }
  },
  data() {
    return {
      menu: false,
      allowedMinuteStep: []
    };
  },
  computed: {
    picker: {
      get() {
        return this.value;
      },
      set(val) {
        // this.menu = false;
        this.$emit('input', val);
      }
    }
  },
  created() {
    /** 選択できる分数を作成 */
    this.allowedMinuteStep = Array.from({ length: 60 }, (_, i) => i).filter(
      m => m % this.roundMinute === 0
    );
  }
};
</script>
