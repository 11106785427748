import moment from 'moment';

const dateUtils = {
  getNow() {
    return new Date();
  },
  getToday() {
    return new Date(moment(this.getNow()).format('YYYY-MM-DD'));
  },
  getTime() {
    let [hh, mm, ss] = moment(this.getNow()).format('HH:mm:ss').split(':');

    return this.getTime2(parseInt(hh), parseInt(mm), parseInt(ss));
  },
  getTime2(hh, mm, ss) {
    return new Date(0, 0, 0, Number(hh), Number(mm), Number(ss));
  },
  getDate(date) {
    return new Date(moment(date).format('YYYY-MM-DD'));
  },
  getYesterday() {
    return this.addDay(this.getToday(), -1);
  },
  getTomorrow() {
    return this.addDay(this.getToday(), 1);
  },
  getLastSundy() {
    let lastSunday = new Date(this.getToday());
    lastSunday.setDate(lastSunday.getDate() - lastSunday.getDay());

    return lastSunday;
  },
  getLastWeekMonday() {
    let lastWeekMonday = new Date(this.getLastSundy());
    lastWeekMonday.setDate(lastWeekMonday.getDate() - 6);
    return lastWeekMonday;
  },
  getNextSaturday() {
    let nextSaturday = new Date(this.getToday());
    nextSaturday.setDate(nextSaturday.getDate() - nextSaturday.getDay() + 6);

    return nextSaturday;
  },
  getNextMonday() {
    let nextMonday = new Date(this.getToday());
    nextMonday.setDate(nextMonday.getDate() - nextMonday.getDay() + 8);

    return nextMonday;
  },
  addYear(date, num) {
    let dt = new Date(date);
    dt.setFullYear(dt.getFullYear() + num);
    return dt;
  },
  // ▼addMonth(date, num)
  // dt.getMonth() + num されたときの実際の日付計算は、以下の通り
  // mm + num 月 dd 日 (ddは元の日付のまま)
  // ddがmm + num月の日数より多い場合に、翌月へ繰り上げられる
  // 例1）5 + 1月31日 ⇒ 6月31日 ⇒ 7月1日
  // 例2）5 - 1月31日 ⇒ 4月31日 ⇒ 5月1日
  // 例3）6 - 1月30日 ⇒ 5月30日
  // if (date.getDate() > dt.getDate()) 内で
  // 例1）31日 > 1日 のため dt(7月1日).setDate(0) ⇒ 6月30日
  // 例2）31日 > 1日 のため dt(5月1日).setDate(0) ⇒ 4月30日
  // 例3）30日 = 30日のため dt(5月30日).setDate(0)は実行せず ⇒ 5月30日
  // 元の日付が計算後の月の日数に収まる場合はそのまま元の日数が採用されるため、月計算後の日数が元の日数より大きくなることはない
  addMonth(date, num) {
    let dt = new Date(date);
    dt.setMonth(dt.getMonth() + num);
    if (date.getDate() > dt.getDate()) {
      dt.setDate(0);
    }
    return dt;
  },
  addDay(date, num) {
    let dt = new Date(date);
    dt.setDate(dt.getDate() + num);
    return dt;
  },
  getYear(date) {
    return new Date(date).getFullYear();
  },
  getMonth(date) {
    return new Date(date).getMonth() + 1;
  },
  getDay(date) {
    return new Date(date).getDate();
  },
  getWeekday(date) {
    return moment(date).weekday();
  },
  getDateRange(date1, date2) {
    const startDate = moment(date1);
    const endDate = moment(date2);
    const dateCount = endDate.diff(startDate, 'days') + 1;
    return Array.from(Array(dateCount).keys()).map(x =>
      moment(date1).add(x, 'days').toDate()
    );
  }
};

export default dateUtils;
