var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mr-5"},[_c('v-card-title',[_vm._v(" ユーザー管理 "),_c('v-btn',{staticClass:"pa-2 ml-6",attrs:{"color":"info"},on:{"click":function($event){return _vm.openCreateDialog()}}},[_vm._v("追加")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"ユーザー検索","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"ma-4",attrs:{"headers":_vm.getHeaders(),"items":_vm.users,"item-key":"id","search":_vm.search,"item-class":_vm.rowClass,"sort-by":"id","mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:_vm.rowClass(item),on:{"click":function($event){return _vm.onClickRow(item)}}},[(!_vm.$vuetify.breakpoint.xs)?_c('td',[_vm._v(_vm._s(item.id))]):_vm._e(),_c('td',[_vm._v(_vm._s(item.login_id))]),_c('td',[_vm._v(" "+_vm._s(item.disp_name)+" ")]),_c('td',[_vm._v(_vm._s(item.e_mail))]),(!_vm.$vuetify.breakpoint.xs)?_c('td',[_vm._v(" "+_vm._s(_vm.getPositionName(item.position))+" ")]):_vm._e()])]}}])}),_c('dialog-frame',{on:{"close":function($event){_vm.listDialogFlag = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("ユーザー詳細")]},proxy:true},{key:"dialogContent",fn:function(){return [_c('v-sheet',[_c('v-container',{staticClass:"px-6 text-body-2"},[_vm._l((_vm.getUserDetailData()),function(item,key){return [_c('v-row',{key:key + 'A'},[_c('v-col',{attrs:{"cols":"3","md":"6"}},[_vm._v(_vm._s(item.title))]),_c('v-col',{attrs:{"cols":"9","md":"6"}},[_vm._v(_vm._s(item.text ? item.text : '未入力'))])],1)]})],2)],1)]},proxy:true},{key:"footer-buttons",fn:function(){return [_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.openUpdateDialog}},[_vm._v("編集")]),_c('v-btn',{attrs:{"color":"warning"},on:{"click":_vm.deleteSelectedUser}},[_vm._v("削除")]),_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){_vm.listDialogFlag = false}}},[_vm._v("キャンセル")])]},proxy:true}]),model:{value:(_vm.listDialogFlag),callback:function ($$v) {_vm.listDialogFlag=$$v},expression:"listDialogFlag"}}),_c('dialog-frame',{on:{"form-clear":_vm.resetFormData},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.isNewUser ? 'ユーザー追加' : 'ユーザー更新'))]},proxy:true},{key:"dialogContent",fn:function(){return [_c('v-container',{staticClass:"mt-4"},[_c('ValidationObserver',{ref:"observer"},[_c('v-form',{ref:"user_form"},[_c('validation-provider',{attrs:{"name":"ユーザー名","rules":"required|hankaku_eisu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"rf1",attrs:{"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" ユーザー名(半角英数)"),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")])]},proxy:true}],null,true),model:{value:(_vm.userFormData.login_id),callback:function ($$v) {_vm.$set(_vm.userFormData, "login_id", $$v)},expression:"userFormData.login_id"}})]}}])}),_c('validation-provider',{attrs:{"name":"パスワード","rules":"hankaku_eisu","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isNewUser)?_c('v-text-field',{ref:"rf2",attrs:{"type":_vm.showPassword1 ? 'text' : 'password',"append-icon":_vm.showPassword1 ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors},on:{"click:append":function($event){_vm.showPassword1 = !_vm.showPassword1}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" パスワード"),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")])]},proxy:true}],null,true),model:{value:(_vm.userFormData.login_pass),callback:function ($$v) {_vm.$set(_vm.userFormData, "login_pass", $$v)},expression:"userFormData.login_pass"}}):_vm._e()]}}])}),_c('validation-provider',{attrs:{"name":"パスワード","rules":"confirmed:confirmation|hankaku_eisu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isNewUser)?_c('v-text-field',{ref:"rf3",attrs:{"type":_vm.showPassword2 ? 'text' : 'password',"append-icon":_vm.showPassword2 ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors},on:{"click:append":function($event){_vm.showPassword2 = !_vm.showPassword2}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" パスワード(確認用)"),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")])]},proxy:true}],null,true),model:{value:(_vm.passConfirmation),callback:function ($$v) {_vm.passConfirmation=$$v},expression:"passConfirmation"}}):_vm._e()]}}])}),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"姓","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"rf4",attrs:{"error-messages":errors,"dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" 姓"),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")])]},proxy:true}],null,true),model:{value:(_vm.userFormData.last_name),callback:function ($$v) {_vm.$set(_vm.userFormData, "last_name", $$v)},expression:"userFormData.last_name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"名","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"rf5",attrs:{"error-messages":errors,"dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" 名"),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")])]},proxy:true}],null,true),model:{value:(_vm.userFormData.fast_name),callback:function ($$v) {_vm.$set(_vm.userFormData, "fast_name", $$v)},expression:"userFormData.fast_name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"姓(フリガナ)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"rf6",attrs:{"error-messages":errors,"dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" 姓(フリガナ)"),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")])]},proxy:true}],null,true),model:{value:(_vm.userFormData.last_kana),callback:function ($$v) {_vm.$set(_vm.userFormData, "last_kana", $$v)},expression:"userFormData.last_kana"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"名(フリガナ)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"rf7",attrs:{"error-messages":errors,"dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" 名(フリガナ)"),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")])]},proxy:true}],null,true),model:{value:(_vm.userFormData.fast_kana),callback:function ($$v) {_vm.$set(_vm.userFormData, "fast_kana", $$v)},expression:"userFormData.fast_kana"}})]}}])})],1)],1)],1),_c('validation-provider',{attrs:{"name":"メールアドレス","rules":"hankaku_eisu|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"rf8",attrs:{"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" メールアドレス ")]},proxy:true}],null,true),model:{value:(_vm.userFormData.e_mail),callback:function ($$v) {_vm.$set(_vm.userFormData, "e_mail", $$v)},expression:"userFormData.e_mail"}})]}}])}),[_c('label',{attrs:{"for":"outside-line"}},[_vm._v("電話番号(外線)")]),_c('div',{staticClass:"d-flex justify-center",attrs:{"id":"outside-line"}},[_c('validation-provider',{attrs:{"name":"電話番号1(外線)","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"rf9",attrs:{"dense":"","error-messages":errors},model:{value:(_vm.outsideLineElms[0]),callback:function ($$v) {_vm.$set(_vm.outsideLineElms, 0, $$v)},expression:"outsideLineElms[0]"}})]}}])}),_c('span',{staticClass:"mx-2 pt-3"},[_vm._v("-")]),_c('validation-provider',{attrs:{"name":"電話番号2(外線)","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"rf10",attrs:{"dense":"","error-messages":errors},model:{value:(_vm.outsideLineElms[1]),callback:function ($$v) {_vm.$set(_vm.outsideLineElms, 1, $$v)},expression:"outsideLineElms[1]"}})]}}])}),_c('span',{staticClass:"mx-2 pt-3"},[_vm._v("-")]),_c('validation-provider',{attrs:{"name":"電話番号3(外線)","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"rf11",attrs:{"dense":"","error-messages":errors},model:{value:(_vm.outsideLineElms[2]),callback:function ($$v) {_vm.$set(_vm.outsideLineElms, 2, $$v)},expression:"outsideLineElms[2]"}})]}}])})],1)],[_c('label',{attrs:{"for":"outside-line"}},[_vm._v("電話番号(内線)")]),_c('div',{staticClass:"d-flex justify-center",attrs:{"id":"outside-line"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"電話番号(外線)","rules":"numeric_and_hyphen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"rf12",attrs:{"dense":"","error-messages":errors},model:{value:(_vm.userFormData.extension),callback:function ($$v) {_vm.$set(_vm.userFormData, "extension", $$v)},expression:"userFormData.extension"}})]}}])})],1)],1)],1)],[_c('label',{attrs:{"for":"outside-line"}},[_vm._v("電話番号(携帯)")]),_c('div',{staticClass:"d-flex justify-center"},[_c('validation-provider',{attrs:{"name":"電話番号1(携帯)","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"rf13",attrs:{"dense":"","error-messages":errors},model:{value:(_vm.cellphoneNumberElms[0]),callback:function ($$v) {_vm.$set(_vm.cellphoneNumberElms, 0, $$v)},expression:"cellphoneNumberElms[0]"}})]}}])}),_c('span',{staticClass:"mx-2 pt-3"},[_vm._v("-")]),_c('validation-provider',{attrs:{"name":"電話番号2(携帯)","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"rf14",attrs:{"dense":"","error-messages":errors},model:{value:(_vm.cellphoneNumberElms[1]),callback:function ($$v) {_vm.$set(_vm.cellphoneNumberElms, 1, $$v)},expression:"cellphoneNumberElms[1]"}})]}}])}),_c('span',{staticClass:"mx-2 pt-3"},[_vm._v("-")]),_c('validation-provider',{attrs:{"name":"電話番号3(携帯)","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"rf15",attrs:{"dense":"","error-messages":errors},model:{value:(_vm.cellphoneNumberElms[2]),callback:function ($$v) {_vm.$set(_vm.cellphoneNumberElms, 2, $$v)},expression:"cellphoneNumberElms[2]"}})]}}])})],1)],_c('validation-provider',{attrs:{"name":"携帯メールアドレス","rules":"hankaku_eisu|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"rf16",attrs:{"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" 携帯メールアドレス ")]},proxy:true}],null,true),model:{value:(_vm.userFormData.cellphone_email),callback:function ($$v) {_vm.$set(_vm.userFormData, "cellphone_email", $$v)},expression:"userFormData.cellphone_email"}})]}}])}),_c('v-select',{staticClass:"px-3",attrs:{"prepend-icon":"mdi-bookmark","label":"役職","item-text":"disp_name","item-value":"id","items":_vm.getPosition()},model:{value:(_vm.positionId),callback:function ($$v) {_vm.positionId=$$v},expression:"positionId"}}),_c('validation-provider',{attrs:{"name":"社員コード","rules":"hankaku_eisu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{ref:"rf17",attrs:{"label":"社員コード","error-messages":errors},model:{value:(_vm.userFormData.staff_code),callback:function ($$v) {_vm.$set(_vm.userFormData, "staff_code", $$v)},expression:"userFormData.staff_code"}})],1)],1)]}}])}),_c('v-row',[_c('label'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"label":("管理者権限：" + (_vm.userFormData.admin ? 'あり' : 'なし'))},model:{value:(_vm.userFormData.admin),callback:function ($$v) {_vm.$set(_vm.userFormData, "admin", $$v)},expression:"userFormData.admin"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"label":("ユーザー状態：" + (_vm.userFormData.enable ? '有効' : '無効'))},model:{value:(_vm.userFormData.enable),callback:function ($$v) {_vm.$set(_vm.userFormData, "enable", $$v)},expression:"userFormData.enable"}})],1)],1)],2)],1)],1)]},proxy:true},{key:"footer-buttons",fn:function(){return [_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){_vm.isNewUser ? _vm.createUserDetail() : _vm.updateUserDetail()}}},[_vm._v(_vm._s(_vm.isNewUser ? '登録' : '更新'))]),_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){return _vm.closeEditDialog()}}},[_vm._v("キャンセル")])]},proxy:true}]),model:{value:(_vm.editDialogFlag),callback:function ($$v) {_vm.editDialogFlag=$$v},expression:"editDialogFlag"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }