import http from '../../utils/httpClient';

const state = {
  workflowMap: []
};

const mutations = {
  setWorkflowMap(state, workflowMap) {
    state.workflowMap = workflowMap;
  }
};

const actions = {
  async fetchWorkflowMap(context) {
    await http.get('workflow_map').then(res => {
      context.commit('setWorkflowMap', res.data);
    });
  },
  async fetchWorkflowMapItem(context, data) {
    return await http.get(`workflow_map/item`, { params: data });
  },
  async updateWorkflowMap(context, data) {
    await http.put(`workflow_map/${data.id}`, data);
  },
  async insertWorkflowMap(context, data) {
    await http.post('workflow_map', data);
  },
  async upsertWorkflowMaps(context, data) {
    await http.post('workflow_map/upsert', data);
  },
  async deleteWorkflowMap(context, data) {
    await http.delete(`workflow_map/${data.id}`);
  }
};

export default {
  state,
  mutations,
  actions
};
