<template>
  <div
    class="
      v-input v-textarea
      v-input--is-label-active v-input--is-dirty v-input--is-readonly
      theme--light
      v-text-field v-text-field--is-booted
    "
  >
    <div class="v-input__prepend-outer">
      <div>
        <v-icon>{{ icon }}</v-icon>
      </div>
    </div>
    <div class="v-input__control">
      <div class="v-input__slot">
        <div class="v-text-field__slot">
          <label
            class="v-label v-label--active theme--light"
            style="left: 0px; right: auto; position: absolute"
            >{{ title }}</label
          >
          <auto-link-text :text="text" :style="'min-height: ' + minHeight" />
        </div>
      </div>
      <div class="v-text-field__details">
        <div class="v-messages theme--light">
          <div class="v-messages__wrapper"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AutoLinkText from '../components/AutoLinkText.vue';

export default {
  components: {
    AutoLinkText
  },
  name: 'TextAreaView',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'mdi-message-text-outline'
    },
    minHeight: {
      type: String,
      default: '300px'
    }
  },
  mounted: function () {}
};
</script>
