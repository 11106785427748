<template>
  <v-card class="mr-5">
    <v-card-title>
      お知らせカテゴリ管理
      <v-btn color="info" class="pa-2 ml-6" @click="openCreateDialog()"
        >追加</v-btn
      >

      <v-spacer />
      <!-- ユーザー検索フィールド -->
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="カテゴリ検索"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="getHeaders()"
      :items="getDispDirectories()"
      item-key="id"
      :search="search"
      class="ma-4"
      @click:row="onClickRow"
      :footer-props="{
        'items-per-page-options': [20, 50, 100, 250, 500, -1],
        'items-per-page-text': '行/ページ:'
      }"
      :sort-by="['path']"
      disable-sort
      :mobile-breakpoint="0"
    >
      <template v-slot:item="{ item }">
        <tr @click="onClickRow(item)">
          <td>{{ getFormattedDispName(item.disp_name) }}</td>
          <td v-if="!$vuetify.breakpoint.xs">
            {{ getUserName(item.updateuser) }}
          </td>
          <td v-if="!$vuetify.breakpoint.xs">
            {{ fullDateFormat(item.updatetime) }}
          </td>
        </tr>
      </template>
    </v-data-table>

    <dialog-frame
      v-model="listDialogFlag"
      @close="listDialogFlag = false"
      @form-clear="resetFormData"
    >
      <template v-slot:title>カテゴリ詳細</template>
      <template v-slot:dialogContent>
        <v-sheet>
          <div class="mt-4"></div>
          <v-container class="px-6 text-body-2">
            <template v-for="(item, key) in getInformationDirectoryDetail()">
              <v-row :key="key + 'A'">
                <v-col cols="3" md="6">{{ item.title }}</v-col>
                <v-col cols="9" md="6">{{ item.text }}</v-col>
              </v-row>
            </template>
          </v-container>
          <updated-by-info
            class="mt-3 mb-3"
            :insertuser="selectedDirectory ? selectedDirectory.insertuser : -1"
            :inserttime="selectedDirectory ? selectedDirectory.inserttime : ''"
            :updateuser="selectedDirectory ? selectedDirectory.updateuser : -1"
            :updatetime="selectedDirectory ? selectedDirectory.updatetime : ''"
          />
        </v-sheet>
      </template>
      <template v-slot:footer-buttons>
        <v-btn color="info" @click="openUpdateDialog" v-show="isEditButtonShow"
          >編集</v-btn
        >
        <v-btn
          color="warning"
          @click="deleteSelectedDirectory"
          v-show="isEditButtonShow"
          >削除</v-btn
        >
        <v-btn color="accent" @click="listDialogFlag = false">キャンセル</v-btn>
      </template>
    </dialog-frame>

    <dialog-frame v-model="editDialogFlag">
      <template v-slot:title>{{
        isNewDirectory ? 'カテゴリ追加' : 'カテゴリ更新'
      }}</template>
      <template v-slot:dialogContent>
        <v-container>
          <ValidationObserver ref="observer">
            <v-form ref="edit_category_form">
              <v-row class="mt-2">
                <v-select
                  prepend-icon="mdi-bookmark"
                  label="カテゴリ追加位置"
                  item-value="id"
                  item-text="disp_name"
                  :items="getEditDirectories()"
                  v-model="selectedId"
                  style="max-width: 450px"
                >
                  <template v-slot:label>{{
                    isNewDirectory ? 'カテゴリ追加位置' : 'カテゴリ移動位置'
                  }}</template>
                  <template v-slot:selection="{ item }">{{
                    getFormattedPullDownDispName(item.disp_name)
                  }}</template>
                  <template v-slot:item="{ item }">{{
                    getFormattedPullDownDispName(item.disp_name)
                  }}</template>
                </v-select>
                <v-col cols="10">
                  <validation-provider
                    v-slot="{ errors }"
                    name="カテゴリ名"
                    rules="required|pathSepalatorLimit|directoryStructureLimit|max:50"
                  >
                    <v-text-field
                      label="カテゴリ名"
                      v-model="newDirectoryName"
                      :rules="[passDuplicateCheck]"
                      :lazy-validation="true"
                      :error-messages="errors"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-radio-group
                v-model="public_lebel"
                label="アクセス権限"
                v-show="parentDirectoryState"
              >
                <v-radio
                  v-for="(pLabel, index) in publicLevelLabel"
                  :key="index"
                  :label="pLabel"
                  :value="index"
                ></v-radio>
              </v-radio-group>

              <div class="px-3 py-0">
                <multi-user-select-list
                  :data="user_id"
                  :label="'所属メンバー'"
                  :dispNumber="false"
                  v-show="
                    (public_lebel === 1 || public_lebel === 2) &&
                    parentDirectoryState
                  "
                />
              </div>
            </v-form>
          </ValidationObserver>
        </v-container>
      </template>
      <template v-slot:footer-buttons>
        <v-btn
          color="info"
          @click="
            isNewDirectory
              ? registerInformationDirectory()
              : updateInformationDirectoryDetail()
          "
          >{{ isNewDirectory ? '追加' : '更新' }}</v-btn
        >
        <v-btn color="accent" @click="closeEditDialog">キャンセル</v-btn>
      </template>
    </dialog-frame>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import fileOperation from '../../../utils/fileOperation';
import token from '../../../utils/token';
import DialogFrame from './dialog_utils/DialogFrame.vue';
import UpdatedByInfo from './dialog_utils/UpdatedByInfo.vue';
import dateformat from '../../functions/DateFormat';
import MultiUserSelectList from '../../components/MultiUserSelectList.vue';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  setInteractionMode
} from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';
import directorySort from '../../functions/DirectorySort';

const path = require('path');
// バリデーションチェックをfocusが外れたときに行うようグローバル設定
setInteractionMode('lazy');
//const ALLUSER_REF_AND_EDIT = 0;
const ALLUSER_REF_AND_ONLY_MEMBERS_EDIT = 1;
const ONLY_MEMBERS_REF_AND_EDIT = 2;
// const ONLY_ME_REF_AND_EDIT = 3;

export default {
  components: {
    DialogFrame,
    UpdatedByInfo,
    MultiUserSelectList,
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    notMobileMode: false,
    notTabletMode: false,
    path: '/',
    search: '',
    // テーブルのヘッダー
    headers: [
      {
        text: 'フォルダ名',
        value: 'disp_name'
      },
      {
        text: '更新者',
        value: 'updateuser'
      },
      {
        text: '更新日',
        value: 'updatetime'
      }
    ],
    parentDirectoryState: true, // 親フォルダのアクセスレベルがpublicLevelLabel[0]ならtrue, それ以外ならfalse
    publicLevelLabel: [
      ' 全てのユーザーが閲覧／追加／編集／削除可',
      '全てのユーザーが閲覧可。所属メンバーのみ追加／編集／削除可。',
      '所属メンバーのみ閲覧／追加／編集／削除可',
      '自分のみ閲覧／追加／編集／削除可'
    ],
    singleSelect: false,
    isNewDirectory: false,
    isEditButtonShow: true,
    directories: [],
    newDirectoryName: '',
    selectedId: 1,
    selectedDirectory: {
      id: 1,
      path: '',
      disp_name: ''
    },
    public_lebel: -1,
    user_id: [],
    listDialogFlag: false,
    editDialogFlag: false,
    deleteDialogFlag: false,
    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    isError: {},
    disp_list: [],
    editedFullScreen: false
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      usersList: state => state.user.usersList,
      usergroup: state => state.userGroups.usergroup,
      userConfig: state => state.userConfig.userconfig,
      informationDirectory: state => state.informationDirectory.informationDirectory
    }),
    directoryDepth: {
      get: function () {
        // 以下ではカテゴリパスに含まれる/の数をチェックする
        const ex = /\//g;
        const arr = this.selectedDirectory.path.match(ex);
        return arr ? arr.length : 0;
      }
    },
    categoryNameWithoutUnderbar: {
      get: function () {
        return this.selectedDirectory.disp_name.slice(this.directoryDepth);
      }
    }
  },
  mixins: [fileOperation, token],
  methods: {
    ...mapActions([
      'fetchUserList',
      'fetchUserGroup',
      'fetchInformationDirectory',
      'insertInformationDirectory',
      'updateInformationDirectory',
      'deleteInformationDirectory',
      'fetchUserConfig',
      'insertUserConfig',
      'updateUserConfig'
    ]),
    init() {
      this.dispData();
    },

    getHeaders() {
      const disp_name = {
        text: 'フォルダ名',
        value: 'disp_name'
      };
      const updateuser = {
        text: '更新者',
        value: 'updateuser'
      };
      const updatetime = {
        text: '更新日',
        value: 'updatetime'
      };
      if (this.$vuetify.breakpoint.xs) {
        return [disp_name];
      } else {
        return [disp_name, updateuser, updatetime];
      }
    },
    isMainPage() {
      return this.$route.path === '/' + this.path;
    },
    dispData() {
      const p = [];
      p.push(this.fetchInformationDirectory());

      Promise.all(p).then(() => {
        this.directories = this.informationDirectory;
        if (this.directories.length === 0) {
          this.insertInformationDirectory({
            path: '/',
            disp_name: 'ルート',
            insertuser: this.getUserId(),
            inserttime: new Date(),
            updateuser: this.getUserId(),
            updatetime: new Date()
          });
        }
        // this.selectedId = this.directories[0].path;
      });
    },

    getInformationDirectoryDetail() {
      if (!this.selectedDirectory) {
        return;
      }
      const dispData = [];
      const detailCategoryData = this.directories.find(
        u => u.id === this.selectedDirectory.id
      );
      if (!detailCategoryData) {
        return;
      }
      dispData.push({
        title: 'カテゴリ名',
        text: this.getDispNameExcludedUnderbar(detailCategoryData.disp_name)
      });
      dispData.push({
        title: 'パス',
        text: detailCategoryData.path
      });
      dispData.push({
        title: 'アクセス権限',
        text: this.publicLevelLabel[detailCategoryData.public_lebel]
      });
      return dispData;
    },
    startEdit() {
      console.log('🐈');
    },
    getDispDirectories() {
      const directories = this.informationDirectory
        //.filter(x => this.ParentDispCheck(x, parentCantDisp))
        .slice();
      return directorySort.directorysort(directories);
    },
    getEditDirectories() {
      const directories = this.informationDirectory
        .filter(
          // 選択したディレクトリ配下のディレクトリと編集不可に設定されたディレクトリはプルダウンに表示しない
          x =>
            !x.path.startsWith(this.selectedDirectory.path + '/') &&
            x.path !== this.selectedDirectory.path // &&
            //this.canEditDirectory(x)
        )
        .slice();
      return directorySort.directorysort(directories);
    },
    /* フォルダの階層の深さをフォルダ名先頭のアンダーバー_の数で求める。*/
    getCategoryDepthByUnderbar(disp_name) {
      if (!disp_name) {
        return;
      }
      const ex = /^_*/g;
      const arr = disp_name.match(ex);
      if (!arr) {
        return;
      }
      return arr[0].length;
    },
    /* フォルダの階層の深さをパスのスラッシュの数で求める。*/
    getCategoryDepthBySlash(path) {
      if (!path) {
        return;
      }
      const ex = /\//g;
      const arr = path.match(ex);
      if (!arr) {
        return;
      }
      return arr.length;
    },
    /*  表示名の___の部分を取り除いたものを返す
      例) ___01.共有 => 01.共有
    */
    getDispNameExcludedUnderbar(disp_name) {
      return disp_name.slice(this.getCategoryDepthByUnderbar(disp_name));
    },
    getFormattedDispName(disp_name) {
      const len = this.getCategoryDepthByUnderbar(disp_name);
      if (disp_name === 'ルート' || !len) {
        return disp_name;
      }
      // 表示されるカテゴリ名の前に階層構造が視覚的に分かるように全角スペースを入れる。
      let formatted_disp_name = '\u{3000}';
      for (let i = 0; i < len; i++) {
        formatted_disp_name += '-\u{3000}\u{3000}';
      }
      // 表示名の前にのアンダーバーは取り除く。
      formatted_disp_name += disp_name.slice(len);
      return formatted_disp_name;
    },
    getFormattedPullDownDispName(disp_name) {
      // カテゴリの階層を_の数で取得する
      const len = this.getCategoryDepthByUnderbar(disp_name);
      if (disp_name === 'ルート' || !len) {
        return disp_name;
      }
      let formatted_disp_name = '';
      for (let i = 0; i < len; i++) {
        formatted_disp_name += '\u{3000}';
      }
      // 元の表示名から先頭の_を消去したものを加える。
      formatted_disp_name += disp_name.slice(len);
      return formatted_disp_name;
    },
    openCreateDialog() {
      this.isNewDirectory = true;
      this.listDialogFlag = false;
      this.editDialogFlag = true;
      this.selectedId = this.informationDirectory.filter(
        d => d.path === '/' || d.disp_name === 'ルート'
      )[0].id;
      // this.selectedId = 1;
      this.public_lebel = 0;
      this.selectedDirectory = {
        pass: '',
        disp_name: ''
      };
      this.user_id = [];
    },

    openUpdateDialog() {
      this.isNewDirectory = false;
      const selectedDirectory = Object.assign({}, this.selectedDirectory);
      this.newDirectoryName = selectedDirectory.disp_name.slice(
        this.directoryDepth
      );
      this.listDialogFlag = false;
      this.editDialogFlag = true;
      this.public_lebel = selectedDirectory.public_lebel;
      this.user_id = Object.assign([], selectedDirectory.user_id);

      let tmp = this.informationDirectory.filter(
        d => d.path === path.dirname(selectedDirectory.path)
      )[0];
      // 一つ上のディレクトリが存在しなければ、プルダウンをルートに指定する
      this.selectedId = tmp
        ? tmp.id
        : this.informationDirectory.filter(
            d => d.path === '/' || d.disp_name === 'ルート'
          )[0].id;

      // const addPosition = this.directories.filter(
      //   d => d.id === this.selectedId
      // )[0];

      // if (
      //   addPosition.public_lebel === ALLUSER_REF_AND_EDIT ||
      //   addPosition.path === '/' ||
      //    this.canEditDirectory(this.selectedDirectory)
      // ) {
      //   this.parentDirectoryState = true;
      // } else {
      //   this.parentDirectoryState = false;
      // }
    },

    closeEditDialog() {
      this.resetFormData();
      this.editDialogFlag = false;
    },
    openDeleteDialog() {
      this.listDialogFlag = false;
      this.deleteDialogFlag = true;
    },

    async registerInformationDirectory() {
      if (!this.$refs.edit_category_form.validate()) {
        return;
      }
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }
      // プルダウンで選択したディレクトリのパスと追加するカテゴリ名から新しいパスを作成
      const addPosition = this.directories.filter(
        d => d.id === this.selectedId
      )[0];

      // 追加位置にあるフォルダより厳しいアクセスレベルにはできない
      // if (addPosition.public_lebel > this.public_lebel) {
      //   this.public_lebel = addPosition.public_lebel;
      // }

      if (
        (this.public_lebel === ALLUSER_REF_AND_ONLY_MEMBERS_EDIT ||
          this.public_lebel === ONLY_MEMBERS_REF_AND_EDIT) &&
        this.user_id.length === 0
      ) {
        this.user_id = [this.getUserId()];
      }

      const directoryPath = path.join(addPosition.path, this.newDirectoryName);
      this.insertInformationDirectory({
        path: directoryPath,
        disp_name:
          '_'.repeat(
            this.getCategoryDepthByUnderbar(addPosition.disp_name) + 1
          ) + this.newDirectoryName,
        public_lebel: this.public_lebel,
        user_id: this.user_id,
        insertuser: this.getUserId(),
        inserttime: new Date(),
        updateuser: this.getUserId(),
        updatetime: new Date()
      }).then(res => {
        this.directories.push(res.data);
        this.resetFormData();
        this.editDialogFlag = false;
        this.newDirectoryName = '';
      });
    },

    async updateInformationDirectoryDetail() {
      if (!this.$refs.edit_category_form.validate()) {
        return;
      }
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }

      // 選択したディレクトリ配下のすべてのディレクトリが、加工されたうえで格納される
      const sendDatas = [];

      // 変更前のパスをキャッシュしておく
      const oldPath = this.selectedDirectory.path;
      const selectedDirectory = Object.assign({}, this.selectedDirectory);
      const newDirectory = Object.assign({}, this.selectedDirectory);

      // プルダウンで選択された追加位置のディレクトリを取得
      let addPosition = Object.assign(
        {},
        this.directories.find(d => d.id === this.selectedId)
      );

      // 新しいパスに変更 (例) /a/b/c => /a/b/dに変更
      newDirectory.path = path.join(addPosition.path, this.newDirectoryName);

      // 新しい表示名の先頭に階層を表す_を付与する
      newDirectory.disp_name =
        '_'.repeat(this.getCategoryDepthByUnderbar(addPosition.disp_name) + 1) +
        this.newDirectoryName;

      newDirectory.public_lebel = this.public_lebel;
      newDirectory.user_id = this.user_id;
      if (
        (this.public_lebel === ALLUSER_REF_AND_ONLY_MEMBERS_EDIT ||
          this.public_lebel === ONLY_MEMBERS_REF_AND_EDIT) &&
        newDirectory.user_id.length === 0
      ) {
        newDirectory.user_id = [this.getUserId()];
      }

      sendDatas.push(newDirectory);

      this.directories.forEach(d => {
        // 選択されたフォルダ配下のフォルダパス全てに変更を反映
        if (d.path.startsWith(oldPath + '/') && d.id !== selectedDirectory.id) { 
          let tmp = Object.assign({}, d);
          tmp.path = path.join(
            addPosition.path,
            this.newDirectoryName,
            d.path.slice(selectedDirectory.path.length)
          );

          tmp.disp_name =
            '_'.repeat(this.getCategoryDepthBySlash(tmp.path)) +
            this.getDispNameExcludedUnderbar(tmp.disp_name);
          sendDatas.push(tmp);

          // アクセス範囲・所属メンバー判定の制御は、お知らせ・ライブラリの表示処理で行うように変更
          // tmp.public_lebel = this.public_lebel;
          // tmp.user_id = newDirectory.user_id;
        }
      });

      this.updateInformationDirectory(sendDatas).then(() => {
        // 表示画面の更新処理
        this.fetchInformationDirectory().then(() => {
          this.directories = this.informationDirectory;
        });
      });
      this.resetFormData();
      this.newDirectoryName = '';
      this.editDialogFlag = false;
    },

    deleteSelectedDirectory() {
      if (
        !window.confirm(
          `このカテゴリを削除してよろしいですか？なお、フォルダに含まれるファイルやフォルダはすべて削除されます。`
        )
      )
        return;

      const sendDatas = [];
      const selectedDirectory = Object.assign({}, this.selectedDirectory);
      sendDatas.push(selectedDirectory.id);

      // 削除対象のディレクトリ配下の全てのディレクトリも削除対象とする
      this.directories.forEach(d => {
        if (
          d.path.startsWith(selectedDirectory.path + '/') &&
          d.id !== selectedDirectory.id
        ) {
          sendDatas.push(d.id);
        }
      });
      this.deleteInformationDirectory(sendDatas).then(() => {
        // 表示画面の更新処理
        this.fetchInformationDirectory().then(() => {
          this.directories = this.informationDirectory;
        });
        this.listDialogFlag = false;
      });
    },
    onClickRow(item) {
      // ルートディレクトリは選択できない
      if (item.path === '/' || item.disp_name === 'ルート') {
        return;
      }
      this.selectedDirectory = item;
      this.listDialogFlag = true;
      // if (this.canEditDirectory(this.selectedDirectory)) {
      //   this.isEditButtonShow = true;
      // } else {
      //   this.isEditButtonShow = false;
      // }
    },
    passDuplicateCheck(value) {
      if (!value) {
        return true;
      }
      let addPosition;
      // プルダウン未選択の場合
      if (!this.selectedId) {
        addPosition = Object.assign({}, this.selectedDirectory);
        // /a/b/cで名前cを変更するので、追加位置は/a/b
        addPosition.path = path.dirname(addPosition.path);
      } else {
        addPosition = this.directories.filter(d => d.id === this.selectedId)[0];
      }

      if (!addPosition) {
        return true;
      }
      const newPath = path.join(addPosition.path, value);
      const exist = this.directories.some(
        d => d.path === newPath && d.path !== this.selectedDirectory.path
      );
      if (exist) {
        return 'そのディレクトリパスはすでに存在しています。';
      } else {
        return true;
      }
    },
    getUserName(id) {
      if (!id) return '---';
      let item = this.usersList.find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    fullDateFormat(date) {
      return dateformat.fullFormat(date);
    },

    // 入力内容と検証エラーをリセットするメソッド
    resetFormData() {
      this.$refs.edit_category_form.reset();
      this.$refs.observer.reset();
    },

    canDispDirectory(ldItem) {
      switch (ldItem.public_lebel) {
        case 0:
          // 全てのユーザーが閲覧／追加／編集／削除可。
          return true;
        case 1:
          // 全てのユーザーが閲覧可。所属メンバーのみ追加／編集／削除可。
          return true;
        case 2:
          // 所属メンバーのみ閲覧／追加／編集／削除可。
          return !!ldItem.user_id.find(x => x === this.getUserId());
        case 3:
          // 自分のみ閲覧／追加／編集／削除可。
          return ldItem.insertuser === this.getUserId();
        default:
          //？？？
          return false;
      }
    },
    canEditDirectory(ldItem) {
      switch (ldItem.public_lebel) {
        case 0:
          // 全てのユーザーが閲覧／追加／編集／削除可。
          return true;
        case 1:
          // 全てのユーザーが閲覧可。所属メンバーのみ追加／編集／削除可。
          return !!ldItem.user_id.find(x => x === this.getUserId());
        case 2:
          // 所属メンバーのみ閲覧／追加／編集／削除可。
          return !!ldItem.user_id.find(x => x === this.getUserId());
        case 3:
          // 自分のみ閲覧／追加／編集／削除可。
          return ldItem.insertuser === this.getUserId();
        default:
          //？？？
          return false;
      }
    }
  }
};

extend('required', {
  ...required,
  message: '{_field_}は必須項目です。'
});

extend('pathSepalatorLimit', {
  message: 'パスの区切り文字/は使用できません。',
  validate(value) {
    return !/\//.test(value);
  }
});

extend('directoryStructureLimit', {
  message: 'カテゴリ名の先頭に_は使用できません。',
  validate(value) {
    return !/^_+/.test(value);
  }
});

extend('max', {
  ...max,
  message: '{_field_}の入力文字数は{length}文字までです。'
});
</script>
