import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ja from 'vuetify/es5/locale/ja';
import en from 'vuetify/es5/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ja, en },
    current: 'ja'
  },
  theme: {
    themes: {
      light: {
        primary: '#e58917',
        secondary: '#ff9800',
        accent: '#A0A0A0',
        accent2: '#616161',
        success: '#4CAF50',
        info: '#e58917',
        warning: '#C62828',
        error: '#C62828',
        background: '#e0e0e0',
        background_sub: '#F5F5F5'
      },
      dark: {
        primary: '#FFA726',
        secondary: '#ff9800',
        accent: '#A1887F',
        accent2: '#BCAAA4',
        success: '#4CAF50',
        info: '#e58917',
        warning: '#C62828',
        error: '#B00020',
        background: '#616161',
        background_sub: '#757575'
      }
    }
  }
});
