import http from '../../utils/httpClient';

const state = {
  schedulesList: []
};

const mutations = {
  setSchedulesList(state, schedulesList) {
    state.schedulesList = schedulesList;
  }
};

const actions = {
  async fetchScheduleList(context, data) {
    const schedulesList = await http.get('schedules', { params: data });
    console.log('schedulesList:' + schedulesList.data.length);
    context.commit('setSchedulesList', schedulesList.data);
    return schedulesList;
  },
  async fetchScheduleItem(context, data) {
    return await http.get(`schedules/item/${data.id}`);
  },
  async getDuplexFacilitySchedule(context, data) {
    return await http.get('schedules/facilitycheck', { params: data });
  },
  async updateSchedule(context, data) {
    await http.put(`schedules/${data.id}`, data);
  },
  async insertSchedule(context, data) {
    await http.post('schedules', data);
  },
  async deleteSchedule(context, data) {
    await http.delete(`schedules/${data.id}`);
  }
};

export default {
  state,
  mutations,
  actions
};
