import http from '../../utils/httpClient';

const state = {
  userconfig: []
};

const mutations = {
  setUserConfig(state, userconfig) {
    state.userconfig = userconfig;
  }
};

const actions = {
  /** userconfig全取得 */
  async fetchUserConfig(context) {
    await http.get('userconfig').then(res => {
      context.commit('setUserConfig', res.data);
    });
  },
  /** userconfig登録 */
  async insertUserConfig(context, data) {
    return await http.post('userconfig', data);
  },
  /** userconfig更新 */
  async updateUserConfig(context, data) {
    await http.put(`userconfig/${data.id}`, data);
  },
  async upsertUserConfig(context, data) {
    return await http.post('userconfig/upsert', data);
  },
  /** userconfig削除 */
  async deleteUserConfig(context, data) {
    await http.delete(`userconfig/${data.id}`);
  }
};

export default {
  state,
  mutations,
  actions
};
