import http from '../../utils/httpClient';

const state = {
  tagDefinitions: []
};

const mutations = {
  setTagDefinitions(state, data) {
    state.tagDefinitions = data;
  }
};

const actions = {
  async fetchTagDefinition(context) {
    return await http.get('tag_definitions').then(res => {
      console.log(res.data);
      context.commit('setTagDefinitions', res.data);
    });
  },
  async updateTagDefinition(context, data) {
    return await http.put(`tag_definitions/${data.id}`, data);
  },
  async insertTagDefinition(context, data) {
    return await http.post('tag_definitions', data);
  },
  async deleteTagDefinition(context, data) {
    await http.delete(`tag_definitions/${data.id}`);
  }
};

export default {
  state,
  mutations,
  actions
};
