<template>
  <v-sheet class="frame my-2" v-resize="onResize" id="mail">
    <v-row class="pa-2">
      <v-col cols="12" :md="disp_cols" class="pa-4 py-4">
        <v-btn :to="path" tile text class="pa-1">
          <v-icon v-if="getDispStyle(config.dispStyle).usePageIcon"
            >mdi-email</v-icon
          >
          <div
            class="text-decoration-underline text-body-1"
            style="width: 7.7em"
          >
            メール
          </div>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        :md="data_cols"
        class="d-flex pa-0 py-4"
        :class="!ismobile ? 'pr-4 pl-2' : 'pa-3'"
      >
        <div>
          <v-btn class="mr-2" @click="deleteMail()"> 削除 </v-btn>
          <v-btn v-if="dispmode !== 2" @click="searchForMailInit">
            条件変更
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-btn v-if="dispmode !== 2" class="mx-2" @click="reload">
          <v-icon style="transform: rotate(-35deg)">mdi-reload</v-icon>
        </v-btn>
        <v-btn v-if="dispmode !== 2" color="info" @click="createMailInit()">
          メール作成
        </v-btn>
        <v-btn v-else color="info" @click="createFilterInit()">
          フィルタを追加
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" :md="data_cols" class="pa-0" :class="!ismobile ? 'px-4' : 'px-5'">
        <div v-if="search.emit">
          <span style="font-size: 0.9em; letter-spacing: .09em;">検索結果&emsp;</span>
          <span>{{ search.dates['start'] }}</span>
          <span v-if="search.dates['start'] || search.dates['end']"> ~ </span>
          <span>{{ search.dates['end'] }}</span>
          <span v-if="search.targetuser">
            <span style="font-size: 0.9em; letter-spacing: .09em;">&emsp;{{ dispmode === 1 ? '受取人' : '差出人'}}: </span>
            {{ search.targetuser }}</span>
          <span v-if="search.anyword">
            <span style="font-size: 0.9em; letter-spacing: .09em;">&emsp;フリーワード: </span>
            {{ search.anyword }}</span>
        </div>
        <span v-else>&emsp;</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" :md="disp_cols">
        <v-list v-if="disp_cols !== 12" dense>
          <v-list-item-group mandatory color="indigo">
            <v-list-item
              v-for="item in menuItems"
              :key="item.id"
              :value="item.value"
              link
              @click="setDispMode(item.id)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.value }}<span v-if="item.count" style="font-weight: bold;">&emsp;{{ item.count }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-select
          :items="menuItems"
          :item-text="item => item.count ? `${item.value} ${item.count}` : item.value"
          item-value="id"
          v-model="dispmode"
          dense
          v-show="ismobile"
          class="px-2"
          @change="setDispMode"
        >
          <template #item="{ item }">
            <div class="v-list-item__content">
              <div class="v-list-item__title">
                {{ item.value }} <span v-if="item.count" :style="item.count ? {'font-weight': 'bold'} : ''">&emsp;{{ item.count }}</span>
              </div>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" :md="data_cols">
        <v-data-table
          show-select
          :loading="loading"
          v-model="selected"
          :headers="headers"
          item-key="id"
          :items="displist"
          @click:row="showEvent"
          :mobile-breakpoint="800"
          :footer-props="{
            'items-per-page-options': [3, 5, 10, 20, 50, 100, 250, 500, -1],
            'items-per-page-text': '行/ページ:'
          }"
        >
          <template v-slot:[`item.sender_from`]="{ item }">
            <div class="text-truncate" style="max-width: 200px" :style="!item.is_opened ? {'font-weight': 'bold'} : ''">
              {{ item.sender_from }}
            </div>
          </template>
          <template v-slot:[`item.sender_to`]="{ item }">
            <div class="text-truncate" style="max-width: 200px" :style="!item.is_opened ? {'font-weight': 'bold'} : ''">
              {{ item.sender_to }}
            </div>
          </template>
          <template v-slot:[`item.title`]="{ item }">
            <div :style="!item.is_opened ? {'font-weight': 'bold'} : ''" class="d-flex justify-space-between">
              <span style="text-align: start;">{{ item.title || '無題' }}</span>
              <v-icon
                style="transform: rotate(25deg)"
                v-if="item.is_mail_attachment"
                >mdi-paperclip</v-icon
              >
            </div>
          </template>
          <template v-slot:[`item.sendtime`]="{ item }">
            <div :style="!item.is_opened ? {'font-weight': 'bold'} : ''">
              {{ getDateFormat(item.sendtime) }}
            </div>
          </template>
          <template v-slot:[`item.filter_name`]="{ item }">
            <div
              class="text-truncate"
              style="max-width: 200px"
            >
              {{ item.filter_name }}
            </div>
          </template>
          <template v-slot:[`item.folder_name`]="{ item }">
            <div
              class="text-truncate"
              style="max-width: 200px"
            >
              {{ item.folder_name }}
            </div>
          </template>
          <template v-slot:[`item.conditions`]="{ item }">
            <div style="text-align: start;">
              {{ item.conditions }}
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <custom-dialog v-model="isOpen" :persistent="true">
      <template v-slot:title>表示絞込み</template>
      <template v-slot:body>
        <v-container>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
              class="d-flex"
            >
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="auto"
                offset-y
                v-for="item in [{ id: 'start', start: search.anyword}, {id: 'end', end: search.anyword}]"
                :key="item.id"
              >
                <template v-slot:activator="{ on, attr }">
                  <v-text-field
                    v-model="search.dates[item.id]"
                    label="日付"
                    :prepend-icon="item.id === 'start' ? 'mdi-calendar' :'mdi-chevron-double-right'"
                    readonly
                    v-on="on"
                    v-bind="attr"
                    clearable
                  >
                </v-text-field>
                </template>
                <v-date-picker
                  :day-format="date => new Date(date).getDate()"
                  v-model="search.dates[item.id]"
                  :min="search.dates['start']"
                  :max="search.dates['end']"
                  @input="search.datesreset = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1 mb-4" no-gutters>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon ? 'mdi-send' : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="search.targetuser"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    {{ dispmode === 1 ? '受取人' : '差出人' }}
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon ? 'mdi-magnify' : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="search.anyword"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    フリーワード検索
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          </v-container>
      </template>
      <template v-slot:footer>
        <v-btn color="accent" @click="searchForMail"> 閉じる </v-btn>
      </template>
    </custom-dialog>
    <custom-dialog v-model="email.selectedOpen" :persistent="true">
      <template v-slot:title>メール詳細</template>
      <template v-slot:body>
        <v-container>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon ? 'mdi-bookmark' : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :value="selectedEvent.title || '無題'"
                hide-details="auto"
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              class="d-flex"
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon ? 'mdi-send' : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.sender_from"
                hide-details="auto"
                readonly
              >
              </v-text-field>
              <v-btn
                icon
                @click="email.isShowsenders = !email.isShowsenders"
                :style="email.isShowsenders ? { transform: 'rotate(0deg)' } : { transform: 'rotate(180deg)' }"
                ><v-icon>mdi-chevron-up</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <div v-if="email.isShowsenders">
            <v-row class="d-flex align-center py-1" no-gutters>
              <v-col
                class="d-flex"
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 12
                    : 8
                "
              >
                <v-icon
                  v-if="getDispStyle(config.dispStyle).useIcon"
                  left
                  style="align-items: flex-start"
                >
                  mdi-email-arrow-left-outline
                </v-icon>
                <div class="address_area">
                  <div
                    v-for="item in [
                      {
                        id: 1.1,
                        label: '宛先',
                        value: selectedEvent.sender_to
                      },
                      {
                        id: 1.2,
                        label: 'CC',
                        value: selectedEvent.sender_cc
                      },
                      {
                        id: 1.3,
                        label: 'BCC',
                        value: selectedEvent.sender_bcc
                      }
                    ]"
                    :key="item.id"
                  >
                    <v-text-field
                      :outlined="getDispStyle(config.dispStyle).outlined"
                      :dense="dense"
                      :value="item.value"
                      hide-details="auto"
                      readonly
                      v-if="item.value"
                      :class="item.id > 1.1 ? 'mt-5' : ''"
                    >
                      <template v-slot:label>
                        <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                          {{ item.label }}
                        </div>
                      </template>
                    </v-text-field>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex align-center py-1" no-gutters>
              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 12
                    : 8
                "
              >
                <v-text-field
                  :prepend-icon="
                    getDispStyle(config.dispStyle).useIcon ? 'mdi-calendar' : ''
                  "
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  :value="selectedEvent.sendtime"
                  hide-details="auto"
                  readonly
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex align-center py-1" no-gutters>
              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 12
                    : 8
                "
              >
                <v-icon
                  v-if="getDispStyle(config.dispStyle).useIcon && selectedEvent.mail_attachment.length > 0"
                  left
                  style="align-items: flex-start"
                >
                  mdi-paperclip
                </v-icon>
                <v-chip
                  v-for="(item, index) in selectedEvent.mail_attachment"
                  :key="index"
                  @click="downloadAttachFile(item)"
                  class="mr-1"
                  >{{ item.originalname }}
                </v-chip>
              </v-col>
            </v-row>
          </div>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
            <div v-if="htmltext" v-html="htmltext"></div>
            <v-textarea
              v-else
              :prepend-icon="
                getDispStyle(config.dispStyle).useIcon ? 'pl-4' : ''
              "
              :dense="dense"
              :value="selectedEvent.message"
              solo
              flat
              hide-details="auto"
              auto-grow
              readonly
            ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:footer>
        <div class="flex-wrap">
          <v-btn class="ma-1" color="info" @click="createMailInit(selectedEvent, 'Re:')"> 返信 </v-btn>
          <v-btn class="ma-1" color="info" @click="createMailInit(selectedEvent, 'Re:', 'ALL')">
            全員に返信
          </v-btn>
          <v-btn class="ma-1" color="info" @click="createMailInit(selectedEvent, 'Fw:')"> 転送 </v-btn>
          <v-btn class="ma-1" color="warning" @click="deleteMail(selectedEvent.id)"> 削除 </v-btn>
          <v-btn class="ma-1" color="accent" @click="cancelEvent"> キャンセル </v-btn>
        </div>
      </template>
    </custom-dialog>
    <custom-dialog v-model="email.editedOpen" :persistent="true">
      <template v-slot:title>メール作成</template>
      <template v-slot:body>
        <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.nosender" dense>
          宛先が指定されていません
        </v-alert>
        <v-container>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              class="d-flex"
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-icon
                v-if="getDispStyle(config.dispStyle).useIcon"
                left
                style="align-items: flex-start"
                class="pt-2"
              >
                mdi-send
              </v-icon>
              <div class="address_area">
                <v-combobox
                  v-for="(item, index) in [{
                    id: 'sender_to',
                    label: '宛先'
                  }, {
                    id: 'sender_cc',
                    label: 'CC'
                  }, {
                    id: 'sender_bcc',
                    label: 'BCC'
                  }]"
                  :key="item.id"
                  v-model="editedEvent[item.id]"
                  hide-details="auto"
                  :class="index === 0 ? 'pa-0': ''"
                  :items="account.list"
                  hide-no-data
                  clearable
                  multiple
                  :label="item.label"
                >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip v-bind="attrs" :input-value="selected">
                    <span class="pr-2">
                      {{ item }}
                    </span>
                    <v-icon small @click="parent.selectItem(item)">
                      $delete
                    </v-icon>
                  </v-chip>
                </template>
                </v-combobox>
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1 my-4" no-gutters>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon ? 'mdi-bookmark' : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.title"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    件名
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="ismobile ? 12 : 4"
              v-show="!getDispStyle(config.dispStyle).useTitleLabel"
            >
              <p class="mb-0 required">ファイル</p>
            </v-col>
            <v-col
              class="d-flex"
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
            <v-icon
              v-if="getDispStyle(config.dispStyle).useIcon"
              left
              style="align-items: flex-start"
              >
              mdi-paperclip
            </v-icon>
            <div class="upload_area">
              <v-chip
                v-for="(item, index) in editedEvent.mail_attachment"
                :key="index"
                class="mr-1 mb-4"
                >{{ item.originalname }}
              </v-chip>
              <file-input
                ref="fileinput"
                :params="{
                  label: getDispStyle(config.dispStyle).useTitleLabel
                    ? 'ファイル'
                    : ''
                }"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
              />
            </div>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-textarea
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon ? 'pl-4' : ''
                "
                :dense="dense"
                v-model="editedEvent.message"
                solo
                flat
                hide-details="auto"
                auto-grow
                placeholder="本文"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="createMail()"> {{ editedEvent.type ? '返信' : '送信' }} </v-btn>
        <v-btn color="accent" @click="cancelEdit()"> キャンセル </v-btn>
      </template>
    </custom-dialog>
    <custom-dialog v-model="filter.selectedOpen" :persistent="true">
      <template v-slot:title>フィルタ詳細</template>
      <template v-slot:body>
        <v-container>
          <v-row
            class="align-center py-1"
            no-gutters
            v-for="(item, index) in [
              {
                label: 'フィルタ名',
                icon: 'mdi-bookmark',
                value: selectedEvent.filter_name
              },
              {
                label: '振り分け条件',
                icon: 'mdi-sign-direction',
                value: selectedEvent.conditions
              },
              {
                label: '振り分けフォルダ名',
                icon: 'mdi-folder',
                value: selectedEvent.folder_name
              },
              {
                label: '処理順',
                icon: 'mdi-format-list-numbered',
                value: selectedEvent.priority
              }
            ]"
            :key="'filter-' + index"
          >
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon ? item.icon : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :value="item.value"
                hide-details="auto"
                readonly
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    {{ item.label }}
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="createFilterInit(selectedEvent)"> 編集 </v-btn>
        <v-btn color="warning" @click="deleteMail(selectedEvent.id)"> 削除 </v-btn>
        <v-btn color="accent" @click="cancelEvent"> キャンセル </v-btn>
      </template>
    </custom-dialog>
    <custom-dialog v-model="filter.editedOpen" :persistent="true">
      <template v-slot:title>フィルタ{{ filter.isEdit ? '編集' : '追加' }}</template>
      <template v-slot:body>
        <v-alert
          class="pa-0 px-4 py-2 ma-0"
          type="error"
          v-if="isError.message.length > 0"
          dense
        >
          <span style="white-space: pre">{{ isError.message.join('\n') }}</span>
        </v-alert>
        <v-container>
          <v-row class="align-center py-1" no-gutters>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon ? 'mdi-bookmark' : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.filter_name"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    フィルタ名
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="align-center py-1" no-gutters>
            <v-col
              class="d-flex"
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 10
                  : 8
              "
            >
              <v-select
                prepend-icon="mdi-sign-direction"
                label="振り分け条件"
                :items="filter.target"
                item-text="label"
                item-value="value"
                v-model="editedEvent.target"
                :dense="dense"
                class="mt-3 my-1"
              >
              </v-select>
              <p class="pa-4 ma-0">に</p>
            </v-col>
          </v-row>
          <v-row class="align-center py-1" no-gutters>
            <v-col
              class="d-flex"
              style="flex-wrap: wrap"
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon ? 'pl-4' : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.target_word"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    フリーワードを入力
                  </div>
                </template>
              </v-text-field>
              <span style="word-break: break-word">を含む</span>
            </v-col>
          </v-row>
          <v-row class="align-center py-1" no-gutters>
            <v-col
              class="d-flex"
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-icon
                v-if="getDispStyle(config.dispStyle).useIcon"
                left
                style="align-items: flex-start"
              >
                mdi-folder
              </v-icon>
              <div class="folder_area">
                <span style="font-size: 0.9em">振り分けフォルダ名</span>
                <v-checkbox
                  label="新規作成"
                  v-model="editedEvent.isNew"
                  class="ma-0"
                >
                </v-checkbox>
                <v-text-field
                  v-if="editedEvent.isNew"
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  v-model="editedEvent.folder_name"
                  hide-details="auto"
                  class="mt-1 mb-4 pb-0"
                >
                  <template v-slot:label>
                    <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                      振り分けフォルダ名
                    </div>
                  </template>
                </v-text-field>
                <v-select
                  v-else
                  label="振り分けフォルダ名"
                  :items="
                    mail.filters
                      .map(x => x.folder_name)
                      .filter((y, i, self) => self.indexOf(y) === i)
                  "
                  item-text="item"
                  item-value="item"
                  v-model="editedEvent.folder_name"
                  :dense="dense"
                >
                </v-select>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="mail.filters.length > 0 && filter.isEdit"
            class="align-center py-1"
            no-gutters
          >
            <v-col
              class="d-flex"
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-icon
                v-if="getDispStyle(config.dispStyle).useIcon"
                left
                style="align-items: flex-start"
                >mdi-format-list-numbered</v-icon
              >
              <div class="priority_area">
                <p class="mb-1" style="font-size: 0.9em">処理順</p>
                <v-card
                  class="d-flex background_sub mt-2"
                  no-gutters
                  style="width: 100%"
                >
                  <v-col :cols="6">
                    <v-list
                      style="min-height: 200px; max-height: 200px"
                      class="overflow-y-auto"
                      dense
                    >
                      <v-list-item
                        v-for="(item, index) in filter_list
                          .filter(x => x)
                          .sort((a, b) => a.priority - b.priority)"
                        :key="index"
                        @click="changePriority(item)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ index + 1 }}.{{ item.filter_name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col :cols="6">
                    <v-list
                      style="min-height: 200px; max-height: 200px"
                      class="overflow-y-auto"
                      dense
                    >
                      <v-list-item
                        v-for="(item, index) in mailfilters"
                        :key="'mailfilter-' + index"
                        @click="changePriority(item, true)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.filter_name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="createFilter"> {{ filter.isEdit ? '更新' : '追加' }} </v-btn>
        <v-btn color="accent" @click="cancelEdit"> キャンセル </v-btn>
      </template>
    </custom-dialog>
  </v-sheet>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import inputStyleUtils from '../../utils/inputStyleUtils';
import CustomDialog from '../components/CustomDialog.vue';
import dateformat from '../functions/DateFormat';
import token from '../../utils/token';
import FileInput from '../components/FileInput2.vue';
import scroll from '../../utils/scroll';

// 定数
const ENUM = {
  // 受信トレイ
  receiveId: 0,
  // 送信トレイ
  sendId: 1,
  // フィルタの管理
  filterId: 2,
  // 受信トレイと送信トレイの間にフォルダ名を表示する(0以上1未満にする)ため、処理順を割る
  divideNum: 10000,
  // フィルタの振り分け条件(フリーワード)で表示する文字列の上限
  sliceNum: 16
}

export default {
  components: {
    FileInput,
    CustomDialog
  },
  data: () => ({
    name: 'mail',
    path: '/mail',
    config: {},
    dense: true,
    ismobile: false,
    headers: [],
    dispmode: 0,
    disp_cols: 2,
    data_cols: 10,
    // NOTE: id は固定(フィルタの振り分けフォルダのidが0.0001以上0.9999以下のため)
    menuItems: [
      {
        id: ENUM.receiveId,
        value: '受信トレイ',
        count: 0
      },
      {
        id: ENUM.sendId,
        value: '送信トレイ'
      },
      {
        id: ENUM.filterId,
        value: 'フィルタの管理'
      }
    ],
    isOpen: false,
    email: {
      selectedOpen: false,
      editedOpen: false,
      isShowsenders: true
    },
    selected: [],
    displist: [],
    loading: true,
    selectedEvent: {},
    editedEvent: {},
    // リアクティブに検出するためにフィルタの処理順は別途dataに持たせる
    // NOTE: editedEvent内オブジェクトに含めると変更が検出されない
    filter_list: [],
    isError: {
      nosender: false,
      duplicateFilterName: false,
      message: []
    },
    // htmlメール
    htmltext: null,
    search: {
      dates: {
        start: '',
        end: ''
      },
      targetuser: null,
      anyword: null,
      datesreset: false,
      emit: false,
    },
    account: {
      list: []
    },
    filter: {
      selectedOpen: false,
      editedOpen: false,
      isEdit: false,
      target: [
        {
          value: 'FROM',
          label: '送信元（FROM）'
        },
        {
          value: 'TO',
          label: '送信先（TO）'
        },
        {
          value: 'TITLE',
          label: '件名'
        }
      ]
    },
    access: true,
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  beforeDestroy() {
    // メールページをはなれる場合、stateを初期化する
    this.resetAll();
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    },
    selected() {
    },
    loading() {
    },
    'editedEvent.isNew'() {
      const folderList = this.mail.filters
                .map(x => x.folder_name)
                .filter((y, i, self) => self.indexOf(y) === i);
      // 既存の振り分けフォルダ名ではない場合のみ初期化
      this.editedEvent.folder_name = 
      folderList.some(x => x === this.editedEvent.folder_name) ?
      this.editedEvent.folder_name : null;
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      usersList: state => state.user.usersList,
      userConfig: state => state.userConfig.userconfig,
      mailaccount: state => state.mailaccount.mailaccount,
      mail: state => state.mail.mail,
      mailfilters: state => state.mail.mailfilters
    })
  },
  mixins: [token, inputStyleUtils, scroll],
  methods: {
    ...mapActions([
      'refresh',
      'fetchUserConfig',
      'fetchMailAccount',
      'fetchMail',
      'getMail',
      'getMailTmpFile',
      'sendMail',
      'sendMailWithFileData',
      'deleteMailSingle',
      'deleteMailMultiple',
      'fetchMailFilters',
      'insertMailFilter',
      'updateMailFilter',
      'deleteMailFilterSingle',
      'deleteMailFilterMultiple',
      'searchMail',
      'resetAll'
    ]),
    init() {
      this.loading = true;
        // トークン再発行
      this.refreshToken();
      // メールアカウントがない場合、メール取得・フィルタ取得を行わない
      this.fetchMailAccount(this.getUserId()).then(() => {
        if (!this.mailaccount) {
          this.loading = false;
          return;
        }
        this.dispData();
      }).catch(err => {
        console.log(err);
        this.loading = false;
      });
    },
    refreshToken() {
      const refreshToken = localStorage.getItem('refreshToken');
      this.refresh({ refreshToken })
        .then(res => {
          localStorage.setItem('token', res.data.token);
          const exist = !!res.data.newRefreshToken;
          if (exist) {
            localStorage.setItem('refreshToken', res.data.newRefreshToken);
          }
          return res;
        })
        .catch(err => {
          console.log(err);
      });
    },
    dispData() {
      const p = [];
      p.push(this.refreshToken());
      p.push(this.fetchMail(this.getUserId()));
      p.push(this.fetchMailFilters(this.getUserId()));

      Promise.all(p).then(() => {
        if (this.mail) {
          // テーブルに表示するデータを定義
          this.changeDispList();
        }
        if (this.mailfilters) {
          // メニューに振り分けフォルダ名を追加する
          this.setCategory();
        }
        this.access = true;
      }).catch(err => {
        console.log(err);
        this.resetAll();
        this.access = false;
      }).finally(() => {
        this.loading = false;
      });
    },
    reload() {
      // ローディング中はアクセスさせない
      if (this.mailaccount && !this.loading) {
        this.loading = true;
        this.dispData();
      }
    },
    onResize() {
      this.ismobile = document.getElementById('mail').clientWidth < 800;

      if (this.isMainPage() && !this.ismobile) {
        this.disp_cols = 2;
        this.data_cols = 10;
      } else {
        this.disp_cols = 12;
        this.data_cols = 12;
      }
    },
    isMainPage() {
      return this.$route.path === '/' + this.name;
    },
    setHeaders() {
      this.headers = [];
      // 与えられた数値以下の最大の整数を定義(メニューからフィルタの振り分けフォルダ名の id が 0 以上 1 未満のため)
      const number = Math.floor(this.dispmode);
      switch (number) {
        case 0:
          this.headers.push({
            text: '差出人',
            value: 'sender_from',
            width: '150px'
          });
          break;
        case 1:
          this.headers.push({
            text: '受取人',
            value: 'sender_to',
            width: '150px'
          });
          break;
        default:
          break;
      }

      if (this.dispmode <= 1) {
        this.headers.push({
          text: '件名',
          value: 'title'
        });
        this.headers.push({
          text: '日時',
          value: 'sendtime',
          align: 'end',
          width: '150px'
        });
      } else {
        // フィルタ
        this.headers.push({
          text: '処理順',
          value: 'priority',
          align: 'center',
          width: '90px'
        });
        this.headers.push({
          text: 'フィルタ名',
          value: 'filter_name'
        });
        this.headers.push({
          text: '振り分け条件',
          value: 'conditions',
        });
        this.headers.push({
          text: '振り分け先',
          value: 'folder_name',
          width: '200px'
        });
      }
    },
    searchForMailInit() {
      // 検索条件を初期化
      this.searchForMailReset();
      // アカウントがあるかつアクセスできる状態である場合のみ検索ダイアログの表示
      this.isOpen = this.mailaccount && this.access ? true : false;
    },
    searchForMailReset() {
      this.search.dates.start = '';
      this.search.dates.end = '';
      this.search.targetuser = null;
      this.search.anyword = null;
      this.search.emit = false;
    },
    searchForMail() {
      // 選択を初期化
      this.selected = [];
      const data = {
        user_id: this.getUserId(),
        mail_account_id: this.mailaccount.id,
        is_received: this.dispmode < 1
      };

      if (this.search.dates.start) {
        data.start = this.search.dates.start;
      }
      if (this.search.dates.end) {
        data.end = this.search.dates.end;
      }
      if (this.search.targetuser) {
        if (data.is_received) {
          // 受信メールでの検索
          data.sender_from = this.search.targetuser;
        } else {
          // 送信メールでの検索
          data.sender_to = this.search.targetuser;
        }
      }
      if (this.search.anyword) {
        data.searchword = this.search.anyword;
      }

      this.loading = true;
      this.searchMail(data).then(res => {
        // 検索条件を画面に表示
        this.search.emit = true;
        this.displist = res.data;
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.loading = false;
      });

      // 画面を閉じる
      this.isOpen = false;
    },
    setCategory() {
      // 振り分けフォルダ(id が0より大きく1未満)以外のオブジェクト配列をコピーする
      const menulist = Object.assign(
        this.menuItems.filter(x => x.id === ENUM.receiveId || x.id >= ENUM.sendId),
        []
      );
      this.mailfilters.forEach(ele => {
        // 振り分け条件に表示する値を定義
        const label = this.filter.target
          .filter(x => x.value === ele.target)
          .map(y => y.label);
        ele.conditions = `${label}に` + 
        `${ele.target_word.toString().length > ENUM.sliceNum ? `${ele.target_word.toString().slice(0,ENUM.sliceNum)}...` : ele.target_word }` +
        `を含む`;
        // 取得後のメニューに表示する項目を定義
        menulist.push({
          // id は 0より大きく1未満の値に変更
          id: ele.priority / ENUM.divideNum,
          value: ele.folder_name
        });
      });
      // フォルダ名の重複は表示しない
      this.menuItems = menulist
        .filter(
          (x, i, self) =>
            self.findIndex(y => ['value'].every(k => y[k] === x[k])) === i
        )
        .sort((a, b) => a.id - b.id);
        this.changeViewCount();
    },
    changeViewCount() {
      // メールの未読件数を更新する
      this.menuItems.forEach((ele) => {
        switch (ele.id) {
          case 0:
            // 受信トレイ
            ele.count = this.mail.receive.filter(x => x.is_opened === false).length;
            break;
          default:
            break;
        }
        // 振り分けフォルダ
        if (ele.id > 0 && ele.id < 1) {
          const folderName = this.menuItems.filter(x => x.id === ele.id)[0].value;
          ele.count = this.mail.filters
            .filter(x => x.folder_name === folderName)
            .map(y => y.mail)
            .flat().filter(x => x.is_opened === false).length;
        }
      })
    },
    setDispMode(dispmode) {
      this.dispmode = dispmode;
      this.changeDispList();
    },
    changeDispList() {
      // 選択を初期化
      this.selected = [];
      // 検索条件を初期化
      this.searchForMailReset();
      switch (this.dispmode) {
        case 0:
          this.displist = this.mail.receive;
          break;
        case 1:
          this.displist = this.mail.send;
          break;
        case 2:
          // メールフィルタの分類
          this.displist = this.mailaccount && this.access ? this.mailfilters : [];
          break;
        default: {
          const folderName = this.menuItems.filter(
            x => x.id === this.dispmode
          )[0].value;
          // ふりわけたフォルダ名にあるメール
          this.displist = this.mail.filters
            .filter(x => x.folder_name === folderName)
            .map(y => y.mail)
            .flat().sort((a,b) => new Date(b.sendtime).getTime() - new Date(a.sendtime).getTime());
          break;
        }
      }
      this.setHeaders();
    },
    showEvent(eventData) {
      this.selectedEvent = JSON.parse(JSON.stringify(eventData));
      if (this.dispmode !== ENUM.filterId) {
        this.showMailEvent(eventData);
      } else {
        this.showFilterEvent();
      }
    },
    async showMailEvent(eventData) {
      const detail = await this.getMail(this.selectedEvent.id);
      if (detail) {
        this.email.isShowsenders = true;
        this.email.selectedOpen = true;
        // 既読フラグをtrueに変更
        eventData.is_opened = true;
        const sendtime = new Date(this.selectedEvent.sendtime);
        this.selectedEvent.sendtime =
          `${sendtime.getFullYear()}年` +
          `${(sendtime.getMonth() + 1).toString().padStart(2, '0')}月` +
          `${sendtime.getDate().toString().padStart(2, '0')}日` +
          `${sendtime.getHours().toString().padStart(2, '0')}` +
          `:` +
          `${sendtime.getMinutes().toString().padStart(2, '0')}`;
        this.selectedEvent.sender_cc = detail.data.sender_cc;
        this.selectedEvent.sender_bcc = detail.data.sender_bcc;
        this.selectedEvent.message = detail.data.detail;
        // htmlメールの表示
        this.selectedEvent.mail_attachment = detail.data.mail_attachment;
        this.htmltext = null;
        if (this.selectedEvent.mail_attachment.some(x => x.originalname === 'message0.html')) {
          const id = this.selectedEvent.mail_attachment.filter(x => x.originalname === 'message0.html')[0].id;
          this.getMailTmpFile(id).then(res => {
            this.htmltext = new TextDecoder().decode(new Uint8Array(res.data));
          });
        }
        this.changeViewCount();
      }
    },
    showFilterEvent() {
      this.filter.selectedOpen = true;
    },
    async downloadAttachFile(item) {
      try {
        this.getMailTmpFile(item.id).then(res => {
          // 取得したファイルをダウンロードできるようにする
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', item.originalname);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      } catch (err) {
        console.log(err);
      }
    },
    inputCheck() {
      let flag = true;
      this.errReset();
      if (!this.editedEvent) {
        flag = false;
      }

      if (this.dispmode !== ENUM.filterId) {
        flag = this.inputMailCheck();
      } else {
        flag = this.inputFilterCheck();
      }

      return flag;
    },
    inputMailCheck() {
      let flag = true;
      // 宛先指定がない場合
      if (!this.editedEvent.sender_to || this.editedEvent.sender_to.length < 1 || !this.editedEvent.sender_to.every(x => x && x.toString().trim() !== '')) {
        this.isError.nosender = true;
        flag = false;
      }

      // 件名なしの場合、アラート
      else if (!this.editedEvent.title || this.editedEvent.title.trim() === '') {
        flag = !window.confirm(`件名なしで送信します。`) ? false : true;
      }
      return flag;
    },
    inputFilterCheck() {
      let flag = true;
      // すでに登録されたフィルタ名ではない
      if (this.mailfilters.some(x => x.filter_name === this.editedEvent.filter_name)) {
        if (this.filter.isEdit) {
          // 編集時のみ変更したフィルタ名を確認
          if (this.selectedEvent.filter_name !== this.editedEvent.filter_name) {
            console.log('already registered filter name!');
            this.isError.duplicateFilterName = true;
            this.isError.message.push(
              `${this.editedEvent.filter_name}はすでに追加済みです。`
            );
            flag = false;
          }
        }
        else {
          console.log('already registered filter name!');
          this.isError.duplicateFilterName = true;
          this.isError.message.push(
            `${this.editedEvent.filter_name}はすでに追加済みです。`
          );
          flag = false;
        }
      }

      const emptyList = Object.entries(this.editedEvent)
        .map(([key, value]) => {
          if (value === null || value === '' || value.toString().trim() === '')
            return key;
        })
        .filter(x => x);
      // すべて入力されている
      if (emptyList.length > 0) {
        const labels = {
          filter_name: {
            label: 'フィルタ名'
          },
          target_word: {
            label: 'フリーワード'
          },
          folder_name: {
            label: '振り分けフォルダ名'
          }
        };
        this.isError.message.push(
          `${emptyList.map(x => labels[x].label).join('と\n')}が入力されていません。`
        );
        console.log('some input value is empty!');
        flag = false;
      }

      // 更新の場合、すべてのフィルタの処理順が設定されている
      if (this.filter.isEdit) {
        // １つも選択されていない
        if (this.filter_list.length === 0) {
          console.log('no filter selected!');
          flag = false;
          this.isError.message.push(
            `処理順にすべてのフィルタが選択されていません。`
          );
        }
        // 欠けている
        else if (this.filter_list.length !== this.mailfilters.length) {
          console.log('some filter not selected!');
          // NOTE: メッセージに選択していないフィルタ名を表示しようと条件をわけたもののとくに意味なさそうだったのでそのままです。
          flag = false;
          this.isError.message.push(
            `処理順にすべてのフィルタが選択されていません。`
          );
        }
      }

      return flag;
    },
    createMailInit(event = null, type = null, target = null) {
      // 返信・転送から作成する場合もあるのでダイアログを閉じる
      this.email.selectedOpen = false;
      this.errReset();
      // アカウントがない場合、ダイアログを表示しない
      if (!this.mailaccount) {
        this.email.editedOpen = false;
        return;
      }
      this.email.editedOpen = true;
      this.account.list = this.usersList.filter(x => x.enable).map(x => x.e_mail);
      this.editedEvent = event ? JSON.parse(JSON.stringify(event)) :
      {
        sender_to: null,
        sender_cc: null,
        sender_bcc: null,
        sender_from: null,
        title: null,
        message: null
      };
      // 作成をキャンセルした場合、詳細にもどるか、一覧にもどるか判定するために変数を追加
      this.editedEvent.type = type;

      switch (type) {
        case 'Re:':
          // 返信の場合、宛先をsender_from の値に変更
          this.editedEvent.sender_to = event.sender_from.split(',');
          this.editedEvent.sender_cc =
            target === 'ALL' && event.sender_cc ? event.sender_cc.split(',') : null;
          this.editedEvent.mail_attachment = null;
          break;
        // 添付ファイルがあるメールを転送する場合、添付ファイルを表示(実際の送付はAPIで実施)
        case 'Fw:':
        default:
          this.editedEvent.sender_to = null;
          this.editedEvent.sender_cc = null;
          break;
      }

      if (event) {
        // 返信または転送の場合、件名に Re: または Fw: をつける
        this.editedEvent.title = `${type} ${event.title || '無題'}`;
        const copy = this.editedEvent.message;
        let tmp = `------Original Message-------\n` +
          `> From: ${event.sender_from}\n` +
          `> To: ${event.sender_to}\n` +
          `> Sent: ${event.sendtime}\n` +
          `> Subject: ${event.title || '無題'}\n`;
        // 本文の前に改行を入れる
        tmp += `\n`;
        // 文頭に引用符を追記
        copy?.split(/\n/).forEach(word => {
          tmp += `> ${word}\n`;
        });
        this.editedEvent.message = `\n\n\n\n${tmp}`;
        // 署名を文末に追記
        this.editedEvent.message = this.mailaccount.signature
          ? `${this.editedEvent.message}\n${this.mailaccount.signature}`
          : this.editedEvent.message;
      } else {
        // 新規作成の場合、署名があれば追記
        this.editedEvent.message = this.mailaccount.signature
          ? `\n\n\n${this.mailaccount.signature}`
          : null;
      }
      this.editedEvent.sender_from = `${this.mailaccount.display_name}<${this.mailaccount.mail_account}>`;
      // bccは初期化
      this.editedEvent.sender_bcc = null;
    },
    createMail() {
      //入力チェック
      if (!this.inputCheck()) {
        this.doScrollTop();
        return;
      }

      let files = this.$refs.fileinput.files;
      let promise = null;

      let mainFunc = () => {
        let formData = new FormData();
        if (files.length > 0) {
          files.forEach(file => {
            formData.append('files', file);
          });
        }

      // 送信データ
      const data = {
        user_id: this.getUserId(),
        mail_account_id: this.mailaccount.id,
        from: this.editedEvent.sender_from,
        to: this.editedEvent.sender_to.map(x => x.toString().trim()).join()
      };

      if (this.editedEvent.sender_cc && this.editedEvent.sender_cc.length > 0) {
        data.cc = this.editedEvent.sender_cc.map(x => x.toString().trim()).join();
      }
      if (this.editedEvent.sender_bcc && this.editedEvent.sender_bcc.length > 0) {
        data.bcc = this.editedEvent.sender_bcc.map(x => x.toString().trim()).join();
      }
      if (this.editedEvent.title) {
        data.title = this.editedEvent.title;
      }
      if (this.editedEvent.message) {
        data.detail = this.editedEvent.message;
      }
      if (this.editedEvent.mail_attachment) {
        data.mail_attachment = this.editedEvent.mail_attachment;
      }
      this.loading = true;
      if (files.length > 0) {
        formData.append('data', JSON.stringify(data));
        // ハイフンくぎりで指定(リクエストデータを1つにまとめる)
        promise = this.sendMailWithFileData(
          {
            user_idandmail_account_id: `${this.getUserId()}-${this.mailaccount.id}`,
            data: formData
          });
      }
      else {
        promise = this.sendMail(data);
      }

        promise
          .then(() => {
            setTimeout(() => {
              this.email.editedOpen = false;
              this.editedEvent = {};
              // 再取得
              this.dispData();
            }, 1800);
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            // ダイアログを閉じる
            this.loading = false;
          });
      };

      if (promise == null) {
        mainFunc();
      } else {
        promise
          .then(() => {
            mainFunc();
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    createFilterInit(event = null) {
      // 編集から作成する場合もあるのでダイアログを閉じる
      this.filter.selectedOpen = false;
      this.errReset();
      // アカウントがない場合、ダイアログを表示しない
      if (!this.mailaccount) {
        this.filter.editedOpen = false;
        return;
      }
      this.filter.editedOpen = true;
      // フィルタを編集するフラグをたてる
      this.filter.isEdit = !!event;
      this.editedEvent = event
        ? JSON.parse(JSON.stringify(event))
        : {
            filter_name: null,
            target: 'FROM',
            target_word: null,
            folder_name: null,
            isNew: true
          };
      this.filter_list = this.mailfilters.map(x => {
        return {
          id: x.id,
          priority: x.priority,
          filter_name: x.filter_name
        };
      });
    },
    changePriority(target, isAdd = null) {
      // フィルタを並び替える側
      if (isAdd === null) {
        this.filter_list.splice(this.filter_list.indexOf(target), 1);

        // 表示する行番号を上書き(最初・最後ではないフィルタを削除した場合、優先度が狂うため)
        this.filter_list.forEach((ele, i) => {
          ele.priority = i + 1;
        });
      }

      // フィルタを追加する側
      const num = this.filter_list.length;
      // 追加できるのは(表示項目数)回まで
      if (isAdd && num < this.mailfilters.length) {
        // 同一のフィルタは追加できない
        if (this.filter_list.every(x => x.filter_name !== target.filter_name)) {
          this.filter_list.push({
            id: target.id,
            priority: num + 1,
            filter_name: target.filter_name
          });
        }
      }
    },
    async createFilter() {
      if (!this.inputCheck()) {
        this.doScrollTop();
        return;
      }
      const data = {
        user_id: this.getUserId(),
        mail_account_id: this.mailaccount.id,
        filter_name: this.editedEvent.filter_name.toString().trim(),
        folder_name: this.editedEvent.folder_name.toString().trim(),
        target: this.editedEvent.target,
        target_word: this.editedEvent.target_word
      };
      try {
        this.loading = true;
        if (this.filter.isEdit) {
          // 更新対象のid
          data.id = this.editedEvent.id;
          // 処理順も更新する
          data.priorities = this.filter_list.map(x => {
            return {
              id: x.id,
              priority: x.priority
            };
          });
          await this.updateMailFilter(data);
        } else {
          await this.insertMailFilter(data);
        }
        // 再取得
        this.dispData();
      } catch (err) {
        console.log(err);
      } finally {
        this.filter.editedOpen = false;
        this.editedEvent = {};
        this.loading = false;
      }
    },
    cancelEvent() {
      this.email.selectedOpen = false;
      this.filter.selectedOpen = false;
      this.selectedEvent = {};
    },
    cancelEdit() {
      this.email.editedOpen = false;
      this.filter.editedOpen = false;
      if (this.dispmode !== ENUM.filterId) {
        this.$refs.fileinput.fileClear();
        // メールの新規作成をキャンセルする場合は詳細を表示しない
        if (this.editedEvent.type) {
          this.email.selectedOpen = true;
        }
      }
      this.editedEvent = {};
    },
    async deleteMail(id = null) {
      if (id === null && this.selected.length === 0) {
        window.alert(`チェックボックスを１つ以上選択してください。`);
        return;
      } else if (
        !window.confirm(
          `選択した${
            this.dispmode !== ENUM.filterId ? 'Webメール' : 'フィルタ'
          }を削除してよろしいですか？`
        )
      ) {
        return;
      }
      try {
        if (id) {
          // 特定のメールまたはフィルタを削除
          this.dispmode !== ENUM.filterId
            ? await this.deleteMailSingle(id)
            : await this.deleteMailFilterSingle(id);
          this.email.selectedOpen = false;
          this.filter.selectedOpen = false;
          this.selectedEvent = {};
        } else {
          // 複数のメールまたはフィルタを削除
          this.dispmode !== ENUM.filterId
            ? await this.deleteMailMultiple({
                ids: this.selected.map(x => x.id)
              })
            : await this.deleteMailFilterMultiple({
                ids: this.selected.map(x => x.id)
              });
          // 選択初期化
          this.selected = [];
        }
        // 再取得
        this.dispData();
      } catch (err) {
        console.log(err);
      }
    },
    getDateFormat(date) {
      if (!date) return '';

      return dateformat.autoFormat(date);
    },
    errReset() {
      for (var key in this.isError) {
        // 配列ならば初期化・論理型はfalse
        // NOTE: isError に設定するデータ型は配列か論理
        this.isError[key] = Array.isArray(this.isError[key]) ? [] : false;
      }
    }
  }
};
</script>
<style>
.v-btn--active::before {
  opacity: 0 !important;
}
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: transparent !important;
  padding: 0 !important;
}
.folder_area,
.address_area,
.upload_area,
.priority_area {
  width: 100%;
}
.v-data-table__mobile-row__header {
  display: none !important;
}
.v-data-table-header-mobile > tr > th {
  padding: 0 .6em 0 .37em !important;
}
</style>