export default {
  methods: {
    doScrollTop(){
      // ダイアログのスクロール位置をトップに変更する
      const elements = document.getElementsByClassName('dialog-main');
      if (!elements || !elements.length) {
        // 要素が取得できなかった場合は終了
        return;
      }
      elements[0].scrollTop = 0;
    }
  }
}