import http from '../../utils/httpClient';

const state = {
  colorThemes: []
};

const mutations = {
  setColorThemes(state, colorThemes) {
    state.colorThemes = colorThemes;
  }
};

const actions = {
  async fetchColorThemes(context) {
    await http.get('colorTheme').then(res => {
      context.commit('setColorThemes', res.data);
    });
  }
};

export default {
  state,
  mutations,
  actions
};
