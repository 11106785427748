import http from '../../utils/httpClient';

const state = {
  filedata: []
};

const mutations = {
  setFileData(state, filedata) {
    state.filedata = filedata;
  }
};

const actions = {
  async fetchFileData(context) {
    await http.get('filedata').then(res => {
      context.commit('setFileData', res.data);
    });
  }
};

export default {
  state,
  mutations,
  actions
};
