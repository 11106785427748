import http from '../../utils/httpClient';

const state = {
  selectUserId: []
};

const mutations = {
  setSelectUserId(state, selectUserId) {
    state.selectUserId = selectUserId;
  }
};

const actions = {
  async fetchSelectUserId(context, data) {
    console.log(data);
    await http
      .get('schedulesid')
      .then(selectUserId =>
        context.commit('setSelectUserId', selectUserId.data)
      );
  },
  async updateSelectUserId(context, data) {
    await http.put(`schedulesid/${data.id}`, data);
  },
  async insertSelectUserId(context, data) {
    await http.post('schedulesid', data);
  },
  async deleteSelectUserId(context, data) {
    await http.delete(`schedulesid/${data.id}`);
  }
};

export default {
  state,
  mutations,
  actions
};
