<template>
  <v-card class="mr-5">
    <v-card-title>
      ワークフロー申請経路管理
      <v-btn color="info" class="pa-2 ml-6" @click="startEdit">追加</v-btn>

      <v-spacer />
      <!-- ワークフロー申請経路検索フィールド -->
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="ワークフロー申請経路検索"
        single-line
        hide-details
      />
    </v-card-title>
    <!-- ワークフロー申請経路リスト表示テーブル -->
    <v-data-table
      :headers="getHeaders()"
      :items="listitem"
      item-key="id"
      :search="search"
      class="ma-4"
      sort-by="id"
      :mobile-breakpoint="0"
      @click:row="showEvent"
    >
      <template v-slot:[`item.def_category_id`]="{ item }">
        {{ getCategoryNames(item.def_category_id) }}
      </template>
      <template v-slot:[`item.def_group_id`]="{ item }">
        {{ getGroupNames(item.def_group_id) }}
      </template>
      <template v-slot:[`item.def_user_id`]="{ item }"> {{ getUserNames(item.def_user_id) }} </template>
      <template v-slot:[`item.updatetime`]="{ item }">
        {{ getDateFormat(item.updatetime) }}
      </template>
      <template v-slot:[`item.updateuser`]="{ item }">
        {{ getUserName(item.updateuser) }}
      </template>
    </v-data-table>
    <custom-dialog v-model="selectedOpen">
      <template v-slot:title>ワークフロー申請経路情報</template>
      <template v-slot:body>
        <v-container>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">申請経路名称</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-text-field
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.route_name"
                readonly
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">申請経路名称</div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">対象カテゴリ</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-select
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :items="workflowTemplate"
                item-text="disp_name"
                item-value="id"
                v-model="selectedEvent.def_category_id"
                readonly
                multiple
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">対象カテゴリ</div>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">対象グループ</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-select
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :items="usergroup"
                item-text="group_name"
                item-value="id"
                v-model="selectedEvent.def_group_id"
                readonly
                multiple
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">対象グループ</div>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">対象ユーザー</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-select
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :items="usersList"
                item-text="disp_name"
                item-value="id"
                v-model="selectedEvent.def_user_id"
                readonly
                multiple
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">対象ユーザー</div>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="12">
              <p class="mb-0">申請経路</p>
            </v-col>
            <v-col :cols="1" />
            <v-col :cols="11">
              <div>{{ getAcceptRouteText(selectedEvent.detail) }}</div>
            </v-col>
          </v-row>
        </v-container>

        <v-card class="mx-1 mt-3" color="background_sub">
          <v-container class="px-6 text-body-2">
            <v-row class="pa-0">
              <v-col class="pa-0 text-subtitle-1">更新情報</v-col>
            </v-row>
            <v-row class="pa-0">
              <v-col class="pa-0" cols="2">作成者</v-col>

              <v-col class="pa-0">{{ getUserName(selectedEvent.insertuser) }}</v-col>
              <v-col class="pa-0 text-right">{{ fullDateFormat(selectedEvent.inserttime) }}</v-col>
            </v-row>
            <v-row><v-divider /></v-row>
            <v-row class="pa-0">
              <v-col class="pa-0" cols="2">更新者</v-col>

              <v-col class="pa-0">{{ getUserName(selectedEvent.updateuser) }}</v-col>
              <v-col class="pa-0 text-right">{{ fullDateFormat(selectedEvent.updatetime) }}</v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="startEditing"> 編集 </v-btn>
        <v-btn color="warning" @click="deleteData"> 削除 </v-btn>
        <v-btn color="accent" @click="selectedOpen = false"> キャンセル </v-btn>
      </template>
    </custom-dialog>
    <custom-dialog v-model="editedOpen">
      <template v-slot:title>申請経路情報</template>
      <template v-slot:body>
        <v-container>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">ワークフロー申請経路名称</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-text-field
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.route_name"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">申請経路名称</div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">対象カテゴリ</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-select
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :items="workflowTemplate"
                item-text="disp_name"
                item-value="id"
                v-model="editedEvent.def_category_id"
                multiple
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">対象カテゴリ</div>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">対象グループ</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 12 : 8">
              <v-select
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :items="usergroup"
                item-text="group_name"
                item-value="id"
                v-model="editedEvent.def_group_id"
                multiple
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">対象グループ</div>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="ismobile ? 12 : 4" v-show="!getDispStyle(config.dispStyle).useTitleLabel">
              <p class="mb-0">対象ユーザー</p>
            </v-col>
            <v-col :cols="getDispStyle(config.dispStyle).useTitleLabel || ismobile ? 10 : 6">
              <v-select
                :prepend-icon="getDispStyle(config.dispStyle).useIcon ? 'mdi-message-outline' : ''"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :items="usersList"
                item-text="disp_name"
                item-value="id"
                v-model="editedEvent.def_user_id"
                readonly
                multiple
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">対象ユーザー</div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-btn @click="useUserList = !useUserList" v-if="!useUserList" small
                ><v-icon>mdi-chevron-down</v-icon>展開</v-btn
              >
              <v-btn @click="useUserList = !useUserList" v-if="useUserList" small
                ><v-icon>mdi-chevron-up</v-icon>畳む</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" v-if="useUserList" no-gutters>
            <v-col :cols="12">
              <multi-user-select-list
                :data="editedEvent.def_user_id"
                :label="'対象ユーザー'"
                :dispNumber="false"
                :canEmpty="true"
              />
            </v-col>
          </v-row>

          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col :cols="12">
              <p class="mb-0">申請経路</p>
            </v-col>
            <v-col :cols="12">
              <route-setting
                ref="route_setting"
                v-model="editedEvent.detail"
                :readonly="false"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :ismobile="ismobile"
                :useIcon="getDispStyle(config.dispStyle).useIcon"
                :useTitleLabel="getDispStyle(config.dispStyle).useTitleLabel"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="endEdit">登録</v-btn>
        <v-btn color="accent" @click="cancelEdit"> キャンセル </v-btn>
      </template>
    </custom-dialog>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import fileOperation from '../../../utils/fileOperation';
import token from '../../../utils/token';
import CustomDialog from '../../components/CustomDialog.vue';
import inputStyleUtils from '../../../utils/inputStyleUtils';
import dateformat from '../../functions/DateFormat';
import RouteSetting from '../../components/WF3RouteSetting.vue';
import MultiUserSelectList from '../../components/MultiUserSelectList.vue';

export default {
  components: {
    CustomDialog,
    RouteSetting,
    MultiUserSelectList
  },
  data: () => ({
    name: 'WorkflowRouteSettings',
    path: '/WorkflowRouteSettings',
    config: {},
    dense: true,
    ismobile: false,
    headers: [],
    dispmode: 0,
    search: '',
    listitem: [],
    isError: {
      reapply: false,
      file: false,
      category: false,
      route: false,
      extra: false,
      extraText: ''
    },
    selectedOpen: false,
    selectedFullScreen: false,
    selectedEvent: {},
    editedOpen: false,
    editedFullScreen: false,
    editedEvent: {},
    useUserList: false,

    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    disp_list: []
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      workflowRoute: state => state.workflowRoute.workflowRoute,
      usersList: state => state.user.usersList,
      usergroup: state => state.userGroup.usergroup,
      workflowTemplate: state => state.workflowTemplate.workflowTemplate,
      userConfig: state => state.userConfig.userconfig
    }),
    outsideLine: {
      get() {
        return `${this.outsideLineElms[0]}-${this.outsideLineElms[1]}-${this.outsideLineElms[2]}`;
      }
    },
    cellphoneNumber: {
      get() {
        return `${this.cellphoneNumberElms[0]}-${this.cellphoneNumberElms[1]}-${this.cellphoneNumberElms[2]}`;
      }
    }
  },
  mixins: [fileOperation, token, inputStyleUtils],
  methods: {
    ...mapActions([
      'fetchWorkflowRoute',
      'updateWorkflowRoute',
      'insertWorkflowRoute',
      'deleteWorkflowRoute',
      'fetchWorkflowTemplate',
      'fetchUserConfig'
    ]),
    init() {
      this.dispData();
    },
    getHeaders() {
      const disp_id = {
        text: 'ID',
        value: 'id'
      };
      const disp_name = {
        text: '名前',
        value: 'route_name'
      };
      const category_id = {
        text: 'カテゴリ',
        value: 'def_category_id'
      };
      const group_id = {
        text: 'グループ',
        value: 'def_group_id'
      };
      const user_id = {
        text: 'ユーザー',
        value: 'def_user_id'
      };
      const updateuser = {
        text: '更新者',
        value: 'updateuser',
        width: '120px'
      };
      const updatetime = {
        text: '更新日時',
        value: 'updatetime',
        align: 'end',
        width: '150px'
      };
      return [disp_id, disp_name, category_id, group_id, user_id, updateuser, updatetime];
    },
    isMainPage() {
      return this.$route.path === '/' + this.path;
    },
    async dispData() {
      await this.fetchWorkflowTemplate();

      await this.fetchWorkflowRoute();
      this.listitem = this.workflowRoute;
    },
    showEvent(eventData) {
      const data = { ...eventData };

      this.selectedOpen = true;
      this.selectedEvent = data;
      this.editedOpen = false;
      this.editedEvent = {};
    },
    startEdit() {
      const data = {
        id: -1,
        route_name: '',
        route: [],
        def_category_id: [],
        def_group_id: [],
        def_user_id: [],
        detail: []
      };
      this.selectedOpen = false;
      this.selectedEvent = {};
      this.editedOpen = true;
      this.editedEvent = data;
    },
    startEditing() {
      const data = { ...this.selectedEvent };
      this.selectedOpen = false;
      this.selectedEvent = {};
      this.editedEvent = data;
      this.editedOpen = true;
    },
    inputCheck() {
      return true;
    },
    async endEdit() {
      //入力チェック
      if (!this.inputCheck()) return;
      try {
        const data = { ...this.editedEvent };

        //存在しないカテゴリ、グループ、ユーザーがあれば除外する
        data.def_category_id = data.def_category_id.filter(x => this.workflowTemplate.some(y => y.id == x));
        data.def_group_id = data.def_group_id.filter(x => this.usergroup.some(y => y.id == x));
        data.def_user_id = data.def_user_id.filter(x => this.usersList.some(y => y.id == x));

        //更新処理の場合
        if (data.id != -1) {
          await this.updateWorkflowRoute(data);
        } else {
          await this.insertWorkflowRoute(data);
        }
        this.dispData();
        this.cancelEdit();
      } catch (err) {
        console.log(err);
      }
    },
    cancelEdit() {
      this.selectedOpen = false;
      this.editedOpen = false;
    },
    async deleteData() {
      //削除確認
      if (!window.confirm(`この申請経路を削除してよろしいですか？`)) return;
      try {
        const data = this.selectedEvent;
        await this.deleteWorkflowRoute(data);
        this.dispData();
        this.cancelEdit();
      } catch (err) {
        console.log(err);
      }
    },
    getUserName(id) {
      if (!id) return '---';
      let item = this.usersList.find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    getDateFormat(date) {
      return dateformat.autoFormat(date);
    },
    fullDateFormat(date) {
      return dateformat.fullFormat(date);
    },
    getCategoryNames(ids) {
      return ids
        .map((x, i) => {
          if (i < 3) {
            return this.workflowTemplate.find(y => y.id == x)?.disp_name ?? '---';
          } else if (i == 3) {
            return `他${ids.length - 3}件`;
          }
          return '';
        })
        .filter(x => x.length > 0)
        .join(',');
    },
    getGroupNames(ids) {
      return ids
        .map((x, i) => {
          if (i < 3) {
            return this.usergroup.find(y => y.id == x)?.group_name ?? '---';
          } else if (i == 3) {
            return `他${ids.length - 3}件`;
          }
          return '';
        })
        .filter(x => x.length > 0)
        .join(',');
    },
    getUserNames(ids) {
      return ids
        .map((x, i) => {
          if (i < 3) {
            return this.usersList.find(y => y.id == x)?.disp_name ?? '---';
          } else if (i == 3) {
            return `他${ids.length - 3}件`;
          }
          return '';
        })
        .filter(x => x.length > 0)
        .join(',');
    },

    getAcceptRouteText(mapRoutes) {
      return (
        mapRoutes
          .map(x => {
            const userNames = x.route.map(y => this.usersList.find(z => z.id == y)?.disp_name ?? '---');
            const userName = userNames.length == 1 ? userNames[0] : userNames.join(',');

            if (x.accept_count == 0) {
              return userName + '(自動)';
            } else if (userNames.length == 1 && x.accept_count == 1) {
              return userName;
            } else {
              return userName + '(' + x.accept_count + '人)';
            }
          })
          .join('->') + '->完了'
      );
    }
  }
};
</script>

<style lang="css">
.rowBackGround {
  color: darkgray;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
