import http from '../../utils/httpClient';

const state = {
  usergroup: []
};

const mutations = {
  setUserGroup(state, usergroup) {
    state.usergroup = usergroup;
  }
};

const actions = {
  async fetchUserGroup(context) {
    await http.get('usergroup').then(res => {
      context.commit('setUserGroup', res.data);
    });
  },
  async updateUserGroup(context, data) {
    return await http.put(`usergroup/${data.id}`, data);
  },
  async insertUserGroup(context, data) {
    return await http.post('usergroup/create', data);
  },
  async deleteUserGroup(context, data) {
    await http.delete(`usergroup/${data.id}`);
  }
};

export default {
  state,
  mutations,
  actions
};
