<template>
  <v-dialog
    v-model="isVisible"
    scrollable
    persistent
    max-width="600"
    overlay-opacity="100%"
    :fullscreen="editedFullScreen"
    v-if="isVisible"
  >
    <v-card color="background_sub">
      <v-card-title class="pa-0 ma-0">
        <v-toolbar color="primary" dark dense>
          <v-toolbar-title><slot name="title"></slot></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="editedFullScreen = true"
            v-show="!editedFullScreen"
            icon
          >
            <v-icon>mdi-window-maximize</v-icon>
          </v-btn>
          <v-btn
            @click="editedFullScreen = false"
            v-show="editedFullScreen"
            icon
          >
            <v-icon>mdi-window-restore</v-icon>
          </v-btn>
          <v-btn @click="closeDialog" icon>
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <slot name="dialogContent"></slot>
      </v-card-text>
      <v-card-actions class="justify-center">
        <slot name="footer-buttons"> </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    editedFullScreen: false
  }),
  props: {
    item: Object,
    title: String,
    value: { type: Boolean, default: false }
  },
  computed: {
    isVisible: {
      get() {
        return this.value;
      },
      set(val) {
        //v-modelに値を返す（なのでthis.valueも書き換わる）
        this.$emit('input', val);
      }
    }
  },
  methods: {
    closeDialog() {
      this.isVisible = false;
      this.$emit('form-clear');
    }
  }
};
</script>
