<template>
  <v-card class="mr-5">
    <v-card-title>
      表示設定
      <v-spacer />
      <v-btn color="info" disabled @click="startEdit">編集</v-btn>
    </v-card-title>
    <v-card-text>
      <v-sheet>
        <v-container class="px-6 text-body-2">
          <template>
            <v-row v-for="(item, key) in disp_list" :key="key">
              <v-col cols="3">{{ item.title }}</v-col>
              <v-col cols="9">{{ item.text }}</v-col>
            </v-row>
          </template>
        </v-container>
      </v-sheet>
    </v-card-text>
    <v-dialog> </v-dialog>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import fileOperation from '../../../utils/fileOperation';
import token from '../../../utils/token';
import inputStyleUtils from '../../../utils/inputStyleUtils';

export default {
  components: {},
  data: () => ({
    name: 'userconfig',
    path: '/userconfig',
    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    isError: {},
    disp_list: [],
    editDialogVisible: false
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      usersList: state => state.user.usersList,
      usergroup: state => state.userGroup.usergroup,
      userConfig: state => state.userConfig.userconfig
    })
  },
  mixins: [fileOperation, token, inputStyleUtils],
  methods: {
    ...mapActions(['fetchUserConfig', 'insertUserConfig', 'updateUserConfig']),
    init() {
      this.dispData();
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.iconSize = window.innerHeight * 0.1;
      this.calendarHeight = Math.max(this.windowSize.y - 140, 600);
      this.dialogLeft = Math.max((this.windowSize.x - 600) / 2, 0);
      let temp = this.$refs.card;
      this.cardSize = { x: temp.clientWidth, y: temp.clientHeight };

      this.selectedFullScreen = window.innerWidth < 600;
      this.editedFullScreen = window.innerWidth < 600;

      this.headers = this.getHeaders();
    },
    isMainPage() {
      return this.$route.path === '/' + this.name;
    },
    dispData() {
      const dispData = [];

      const p = [];
      p.push(this.fetchUserConfig());

      Promise.all(p).then(() => {
        const config = this.userConfig.find(
          x => x.user_id === this.getUserId()
        );
        const dispStyle = this.getDispStyle(config.dispStyle);

        dispData.push({
          title: '表示スタイル',
          text: ''
        });
        dispData.push({
          title: '　名称',
          text: dispStyle.disp_name
        });
        dispData.push({
          title: '　入力エリア',
          text: dispStyle.outlined ? 'アウトライン' : 'アンダーバー'
        });
        dispData.push({
          title: '　アイコン',
          text: dispStyle.useIcon ? '使用する' : '使用しない'
        });
        dispData.push({
          title: '　機能アイコン',
          text: dispStyle.usePageIcon ? '使用する' : '使用しない'
        });
        dispData.push({
          title: '　入力エリアラベル',
          text: dispStyle.useTitleLabel
            ? '入力項目に含めて表示する'
            : '入力項目と分けて表示する'
        });
        dispData.push({
          title: '　備考',
          text: dispStyle.remarks
        });

        dispData.push({ title: 'ライブラリ', text: '' });
        dispData.push({
          title: '　表示件数（トップ）',
          text: (config.library_items_per_page_sub ?? 5) + '件'
        });
        dispData.push({
          title: '　表示件数',
          text: (config.library_items_per_page_main ?? 10) + '件'
        });

        dispData.push({ title: 'お知らせ', text: '' });
        dispData.push({
          title: '　表示件数（トップ）',
          text: (config.information_items_per_page_sub ?? 3) + '件'
        });
        dispData.push({
          title: '　表示件数（専用ページ）',
          text: (config.information_items_per_page_main ?? 10) + '件'
        });

        dispData.push({ title: 'ワークフロー', text: '' });

        dispData.push({
          title: '　表示件数（トップ）',
          text: (config.workflow2_items_per_page_sub ?? 5) + '件'
        });
        dispData.push({
          title: '　表示件数（専用ページ）',
          text: (config.workflow2_items_per_page_main ?? 10) + '件'
        });

        this.disp_list = dispData;
      });
    },
    startEdit() {
      console.log('🐈');
    }
  }
};
</script>
