export default {
  data: () => ({
    dispStyles: [
      {
        id: 1,
        disp_name: 'Vuetify',
        outlined: false,
        useIcon: true,
        usePageIcon: true,
        useTitleLabel: true,
        remarks: 'Vuetifyの機能を利用したスタイル'
      },
      {
        id: 2,
        disp_name: 'Vuetify-NoIcon',
        outlined: false,
        useIcon: false,
        usePageIcon: false,
        useTitleLabel: true,
        remarks: 'Iconを使用しないスタイル'
      },
      {
        id: 3,
        disp_name: 'AipoStyle',
        outlined: true,
        useIcon: false,
        usePageIcon: false,
        useTitleLabel: false,
        remarks: 'Aipoライクのスタイル'
      }
    ]
  }),

  methods: {
    getDispStyle(style) {
      return this.dispStyles.find(x => x.id === style) || this.dispStyles[0];
    }
  }
};
