var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mr-5"},[_c('v-card-title',[_vm._v(" アカウント設定 "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.startEdit}},[_vm._v(_vm._s(_vm.disp_list.length === 0 ? '追加': '編集'))])],1),_c('v-card-text',[_c('v-sheet',[_c('v-container',{staticClass:"px-6 text-body-2"},[(_vm.disp_list.length !== 0)?_vm._l((_vm.disp_list),function(item,key){return _c('div',{key:key},[_c('v-row',[_c('v-col',{attrs:{"cols":"3","md":"6"}},[_vm._v(_vm._s(item.label))]),(item.label.match(/パスワード/))?_c('v-col',{attrs:{"cols":"9","md":"6"}},[_vm._v("******")]):(item.label !== '認証' && item.label !== '暗号化')?_c('v-col',{attrs:{"cols":"9","md":"6"}},[_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(item.value))])]):_c('v-col',{attrs:{"cols":"9","md":"6"}},[_vm._v(_vm._s(item.value ? 'あり' : 'なし'))])],1)],1)}):[_c('span',[_vm._v("アカウントはありません。")])]],2)],1)],1),_c('custom-dialog',{attrs:{"persistent":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("アカウント設定")]},proxy:true},{key:"body",fn:function(){return [(_vm.error.isError)?_c('v-alert',{staticClass:"pa-0 px-4 py-2 ma-0",attrs:{"type":"error","dense":""}},[_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(_vm.error.message.join('\n')))])]):_vm._e(),_vm._l((Object.values(_vm.forms).slice(0,7)),function(input,index){return _c('v-container',{key:input.label},[_c('v-row',{staticClass:"d-flex align-center py-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":_vm.getDispStyle(_vm.config.dispStyle).useTitleLabel || _vm.ismobile
                ? 12
                : 8}},[(input.label.match(/パスワード/))?_c('v-text-field',{attrs:{"prepend-icon":_vm.getDispStyle(_vm.config.dispStyle).useIcon
                  ? 'mdi-message-outline'
                  : '',"outlined":_vm.getDispStyle(_vm.config.dispStyle).outlined,"dense":_vm.dense,"hide-details":"auto","append-icon":_vm.forms.mail_password.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.forms.mail_password.show ? 'text' : 'password'},on:{"click:append":function($event){_vm.forms.mail_password.show = !_vm.forms.mail_password.show}},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.getDispStyle(_vm.config.dispStyle).useTitleLabel)?_c('div',[_vm._v(" "+_vm._s(input.label)+" ")]):_vm._e()]},proxy:true}],null,true),model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}}):_c('v-text-field',{attrs:{"prepend-icon":_vm.getDispStyle(_vm.config.dispStyle).useIcon
                  ? 'mdi-message-outline'
                  : '',"outlined":_vm.getDispStyle(_vm.config.dispStyle).outlined,"dense":_vm.dense,"hide-details":"auto","readonly":index === 0 && _vm.disp_list.length !== 0,"disabled":index === 0 && _vm.disp_list.length !== 0},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.getDispStyle(_vm.config.dispStyle).useTitleLabel)?_c('div',[_vm._v(" "+_vm._s(input.label)+" ")]):_vm._e()]},proxy:true}],null,true),model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}})],1)],1)],1)}),_vm._l((Object.values(_vm.forms).slice(7,9)),function(input){return _c('v-container',{key:input.label,staticClass:"py-1"},[_c('v-row',{staticClass:"align-center py-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":_vm.getDispStyle(_vm.config.dispStyle).useTitleLabel || _vm.ismobile
                ? 12
                : 8}},[(_vm.getDispStyle(_vm.config.dispStyle).useIcon)?_c('v-icon',{staticStyle:{"align-items":"flex-start"},attrs:{"left":""}},[_vm._v("mdi-message-outline")]):_vm._e(),_c('div',[_c('span',{staticStyle:{"font-size":"0.9em"}},[_vm._v(_vm._s(input.label))]),_c('v-radio-group',{attrs:{"row":""},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}},_vm._l(([1,0]),function(n){return _c('v-radio',{key:n,attrs:{"label":n ? "あり": "なし","value":n}})}),1)],1)],1)],1)],1)}),_vm._l((Object.values(_vm.forms).slice(9)),function(input){return _c('v-container',{key:input.label,staticClass:"py-1"},[_c('v-row',{staticClass:"d-flex align-center py-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":_vm.getDispStyle(_vm.config.dispStyle).useTitleLabel || _vm.ismobile
                ? 12
                : 8}},[_c('v-textarea',{attrs:{"prepend-icon":_vm.getDispStyle(_vm.config.dispStyle).useIcon
                  ? 'mdi-message-outline'
                  : '',"outlined":_vm.getDispStyle(_vm.config.dispStyle).outlined,"dense":_vm.dense,"hide-details":"auto","auto-grow":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.getDispStyle(_vm.config.dispStyle).useTitleLabel)?_c('div',[_vm._v(" "+_vm._s(input.label)+" ")]):_vm._e()]},proxy:true}],null,true),model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}})],1)],1)],1)})]},proxy:true},{key:"footer",fn:function(){return [_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.register}},[_vm._v(_vm._s(_vm.disp_list.length === 0 ? '追加': '更新')+" ")]),(_vm.disp_list.length !== 0)?_c('v-btn',{attrs:{"color":"warning"},on:{"click":_vm.deleteData}},[_vm._v(" 削除 ")]):_vm._e(),_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.cancel}},[_vm._v(" キャンセル ")])]},proxy:true}]),model:{value:(_vm.editDialogVisible),callback:function ($$v) {_vm.editDialogVisible=$$v},expression:"editDialogVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }