import http from '../../utils/httpClient';

const state = {
  informationdata: []
};

const mutations = {
  setInformationData(state, informationdata) {
    state.informationdata = informationdata;
  }
};

const actions = {
  async fetchInformationData(context, data) {
    await http.get('informationdata', data).then(LibraryData => {
      context.commit('setInformationData', LibraryData.data);
    });
  },
  async insertInformationData(context, data) {
    return await http.post('informationdata/create', data);
  },
  async updateInformationData(context, data) {
    return await http.put(`informationdata/${data.id}`, data);
  },
  async updateInformationDataWithFile(context, data) {
    await http.post(`informationdata/update`, data);
  },
  async deleteInformationData(context, data) {
    await http.post(`informationdata/delete`, data);
  }
};

export default {
  state,
  mutations,
  actions
};
