import http from '../../utils/httpClient';

export default {
  state: {
    id: '',
    name: '',
    token: '',
    pic: '',
    expiration_time: '',
    usersList: []
  },
  getters: {},
  mutations: {
    store(state, user) {
      state.id = user.id;
      state.token = user.token;
      state.name = user.name;
      state.pic = user.pic;
      state.expiration_time = user.expiration_time;
    },
    drop(state) {
      state.id =
        state.token =
        state.name =
        state.pic =
        state.expiration_time =
          '';
    },
    updateUser(state, user) {
      state.user = {
        id: user.id,
        userId: user.userId,
        userToken: user.userToken
      };
    },
    setUsersList(state, usersList) {
      state.usersList = usersList;
    }
  },
  actions: {
    store(context, user) {
      context.commit('store', user);
    },
    drop(context) {
      context.commit('drop');
    },
    async fetchUserList(context, data) {
      console.log(data);
      await http.get('users').then(usersList => {
        return context.commit('setUsersList', usersList.data);
      });
    },
    async insertUser(context, data) {
      return await http.post('users/create', data);
    },
    async updateUserList(context, data) {
      return await http.put(`users/${data.id}`, data);
    },
    async deleteUser(context, id) {
      await http.delete(`users/${id}`);
    },
    async auth(context, data) {
      context.commit('updateUser', data);
    },
    async changeUserPassword(context, data) {
      return await http.put(`users/changepassword/${data.id}`, data);
    }
  }
};
