<template>
  <div v-resize="onResize">
    <v-dialog
      max-width="600px"
      :position-y="0"
      v-model="isVisible"
      overlay-opacity="100%"
      :fullscreen="fullScreen"
      :persistent="persistent"
      scrollable
    >
      <v-card color="background_sub" flat>
        <v-card-title class="pa-0 ma-0">
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title
              ><slot name="title">ダイアログ</slot></v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn @click="fullScreen = true" v-show="!fullScreen" icon>
              <v-icon>mdi-window-maximize</v-icon>
            </v-btn>
            <v-btn @click="fullScreen = false" v-show="fullScreen" icon>
              <v-icon>mdi-window-restore</v-icon>
            </v-btn>
            <v-btn @click="isVisible = false" icon>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="px-0 ma-0 dialog-main" v-if="isVisible">
          <slot name="body"></slot>
        </v-card-text>
        <v-card-actions class="justify-center">
          <slot name="footer"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import token from '../../utils/token';

export default {
  components: {},
  props: {
    value: { type: Boolean, default: false },
    persistent: { type: Boolean, default: false }
  },
  name: 'CustomDialog',
  data: function () {
    return {
      fullScreen: false
    };
  },
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    isVisible(val) {
      if (val) {
        this.init();
      }
    },
    initLoading(val) {
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading
    }),
    isVisible: {
      get() {
        return this.value;
      },
      set(val) {
        //v-modelに値を返す（なのでthis.valueも書き換わる）
        this.$emit('input', val);
      }
    }
  },
  mixins: [token],
  methods: {
    ...mapActions([]),
    init() {},
    onResize() {
      this.fullScreen = window.innerWidth < 600;
    }
  }
};
</script>
