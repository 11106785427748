import http from '../../utils/httpClient';

const state = {
  workflowTemplateRow: []
};

const mutations = {
  //#region ワークフローテンプレート項目
  setWorkflowTemplateRow(state, workflowTemplateRow) {
    state.workflowTemplateRow = workflowTemplateRow;
  }
  //#endregion
};

const actions = {
  //#region ワークフローテンプレート項目
  async fetchWorkflowTemplateRow(context) {
    await http.get('workflow_template_row').then(res => {
      context.commit('setWorkflowTemplateRow', res.data);
    });
  },
  async updateWorkflowTemplateRow(context, data) {
    await http.put(`workflow_template_row/${data.id}`, data);
  },
  async insertWorkflowTemplateRow(context, data) {
    await http.post('workflow_template_row', data);
  },
  async deleteWorkflowTemplateRow(context, data) {
    await http.delete(`workflow_template_row/${data.id}`);
  }
  //#endregion
};

export default {
  state,
  mutations,
  actions
};
