import jwt_decode from 'jwt-decode';

function jwtDecode() {
  const token = localStorage.getItem('token');
  return jwt_decode(token);
}

export default {
  data: () => ({
    TOKEN_FEATURE: {
      SCHEDULE: 'schedule', //スケジュール
      WORKFLOW: 'workflow', //ワークフロー
      LIBRARY: 'library', //ライブラリ
      INFORMATION: 'information', //お知らせ
      TIMECARD: 'timecard', //タイムカード
      ATTACHMENT: 'attachment' //添付ファイル
    },
    TOKEN_OWNER: {
      ME: 'me',
      OTHER: 'other'
    },
    TOKEN_ACTION: {
      VIEW: 'view', //参照
      EDIT: 'edit', //更新（追加）
      DELETE: 'delete' //削除
    }
  }),

  methods: {
    /** ユーザー表示名を取得する */
    getUserName() {
      return jwtDecode()?.user.disp_name;
    },
    /** ユーザーIDを取得する */
    getUserId() {
      return jwtDecode()?.user.id;
    },
    getPermission(feature, owner, action) {
      // console.log('getPermission', feature, owner, action);
      //
      // const dummy = {
      //   schedule: {
      //     me: { view: true, edit: true, delete: true },
      //     other: { view: true, edit: false, delete: false }
      //   },
      //   workflow: {
      //     me: { view: true, edit: true, delete: true },
      //     other: { view: false, edit: false, delete: false }
      //   },
      //   library: {
      //     me: { view: true, edit: true, delete: true },
      //     other: { view: true, edit: false, delete: false }
      //   },
      //   information: {
      //     me: { view: true, edit: true, delete: true },
      //     other: { view: true, edit: false, delete: false }
      //   },
      //   attachment: {
      //     me: { view: true, edit: true, delete: true },
      //     other: { view: true, edit: false, delete: false }
      //   }
      // };
      // return dummy?.[feature]?.[owner]?.[action];
      return jwtDecode()?.user?.permissions?.[feature]?.[owner]?.[action];
    }
  }
};
