import http from '../../utils/httpClient';

const state = {
  mail: [],
  mailfilters: []
};

const mutations = {
  setMail(state, data) {
    state.mail = data;
  },
  setMailFilters(state, data) {
    state.mailfilters = data;
  },
  reset(state) {
    Object.assign(state.mail, {
      send: [],
      receive: [],
      filters: [],
    });
    Object.assign(state.mailfilters, []);
  }
};

const actions = {
  async fetchMail(context, user_id) {
    return await http.get(`mail/${user_id}`).then(res => {
      context.commit('setMail', res.data);
    });
  },
  async getMail(context, id) {
    return await http.get(`mail/detail/${id}`);
  },
  async getMailTmpFile(context, id) {
    return await http.get(`mail/download/${id}`, {
      responseType: 'arraybuffer'
    });
  },
  async sendMail(context, data) {
    return await http.post(`mail/send`, data);
  },
  async sendMailWithFileData(context, data) {
    return await http.post(`mail/send_with_file/${data.user_idandmail_account_id}`, data.data);
  },
  async deleteMailSingle(context, id) {
    await http.delete(`mail/${id}`);
  },
  async deleteMailMultiple(context, data) {
    await http.post(`mail/delete`, data);
  },
  async fetchMailFilters(context, user_id) {
    return await http.get(`mail/filters/${user_id}`).then(res => {
      context.commit('setMailFilters', res.data);
    });
  },
  async insertMailFilter(context, data) {
    return await http.post(`mail/filters/create`, data);
  },
  async updateMailFilter(context, data) {
    return await http.put(`mail/filters/update/${data.id}`, data);
  },
  async deleteMailFilterSingle(context, id) {
    await http.delete(`mail/filters/${id}`);
  },
  async deleteMailFilterMultiple(context, data) {
    await http.post(`mail/filters/delete`, data);
  },
  async searchMail(context, data) {
    return await http.post('mail/search', data);
  },
  resetAll(context) {
    context.commit('reset');
  }
};

export default {
  state,
  mutations,
  actions
};
