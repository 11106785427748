<template>
  <v-container class="mx-1 mt-3 px-6 pb-3 text-body-2">
    <v-row class="pa-0">
      <v-col class="pa-0 text-subtitle-1">更新情報</v-col>
    </v-row>
    <v-row class="pa-0">
      <v-col class="pa-0" cols="2">作成者</v-col>

      <v-col class="pa-0">{{ getUserName(insertuser) }}</v-col>
      <v-col class="pa-0 text-right">{{ fullDateFormat(inserttime) }}</v-col>
    </v-row>
    <v-row><v-divider /></v-row>
    <v-row class="pa-0">
      <v-col class="pa-0" cols="2">更新者</v-col>

      <v-col class="pa-0">{{ getUserName(updateuser) }}</v-col>
      <v-col class="pa-0 text-right">{{ fullDateFormat(updatetime) }}</v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import dateformat from '../../../functions/DateFormat';

export default {
  data: () => ({}),
  props: {
    item: Object,
    insertuser: Number,
    updateuser: Number,
    inserttime: String,
    updatetime: String
  },
  computed: {
    ...mapState({
      usersList: state => state.user.usersList,
      userConfig: state => state.userConfig.userconfig
    })
  },
  methods: {
    getUserName(id) {
      if (!id) return '---';
      let item = this.usersList.find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    getDateFormat(date) {
      return dateformat.autoFormat(date);
    },
    fullDateFormat(date) {
      return dateformat.fullFormat(date);
    }
  }
};
</script>
