import http from '../../utils/httpClient';

const state = {
  workflowTemplateRow: []
};

const mutations = {
  //#region ワークフローテンプレート項目
  setsubscription(state, subscription) {
    state.subscription = subscription;
  }
  //#endregion
};

const actions = {
  async upsertSubscription(context, data) {
    await http.post('subscription/upsert', data);
  },

  async deleteSubscription(context, data) {
    await http.post('subscription/delete', data);
  }
};

export default {
  state,
  mutations,
  actions
};
