import http from '../../utils/httpClient';

const state = {
  facility: []
};

const mutations = {
  setFacility(state, data) {
    state.facility = data;
  }
};

const actions = {
  async fetchFacility(context) {
    return await http.get('facility').then(res => {
      context.commit('setFacility', res.data);
    });
  },
  async updateFacility(context, data) {
    return await http.put(`facility/${data.id}`, data);
  },
  async insertFacility(context, data) {
    return await http.post('facility', data);
  },
  async deleteFacility(context, data) {
    await http.delete(`facility/${data.id}`);
  }
};

export default {
  state,
  mutations,
  actions
};
