import http from '../../utils/httpClient';

const state = {
  newsDirectory: []
};

const mutations = {
  setnewsDirectory(state, newsDirectory) {
    state.newsDirectory = newsDirectory;
  }
};

const actions = {
  /** newsDirectory全取得 */
  async fetchNewsDirectory(context) {
    await http.get('news_directory').then(res => {
      context.commit('setnewsDirectory', res.data);
    });
  },
  /** newsDirectory登録 */
  async insertNewsDirectory(context, data) {
    return await http.post('news_directory', data);
  },
  /** newsDirectory更新 */
  async updateNewsDirectory(context, data) {
    return await http.post(`news_directory/update`, data);
  },
  /** newsDirectory削除 */
  async deleteNewsDirectory(context, data) {
    await http.post(`news_directory/delete`, data);
  }
};

export default {
  state,
  mutations,
  actions
};
