import http from '../../utils/httpClient';

const state = {
  workflowRoute: []
};

const mutations = {
  setWorkflowRoute(state, workflowRoute) {
    state.workflowRoute = workflowRoute;
  }
};

const actions = {
  //#region ワークフロー申請経路
  async fetchWorkflowRoute(context) {
    await http.get('workflow_route').then(res => {
      context.commit('setWorkflowRoute', res.data);
    });
  },
  async updateWorkflowRoute(context, data) {
    return await http.put(`workflow_route/${data.id}`, data);
  },
  async insertWorkflowRoute(context, data) {
    return await http.post('workflow_route', data);
  },
  async deleteWorkflowRoute(context, data) {
    await http.delete(`workflow_route/${data.id}`);
  }
  //#endregion
};

export default {
  state,
  mutations,
  actions
};
