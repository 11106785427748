<template>
  <transition mode="out-in">
    <router-view />
  </transition>
</template>

<script>
export default {
  name: 'App',

  components: {},

  data: () => ({
    //
  }),
  created: function () {
    // this.$store.dispatch("prelogin");
  },
  methods: {}
};
</script>
