<template>
  <v-card class="mr-5">
    <v-card-title>
      タグ管理
      <v-btn color="info" class="pa-2 ml-6" @click="startEdit">追加</v-btn>

      <v-spacer />
      <!-- タグ検索フィールド -->
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="タグ検索"
        single-line
        hide-details
      />
    </v-card-title>
    <!-- タグリスト表示テーブル -->
    <v-data-table
      :headers="getHeaders()"
      :items="listitem"
      item-key="id"
      :search="search"
      class="ma-4"
      :mobile-breakpoint="0"
      @click:row="showEvent"
    >
      <template v-slot:[`item.back_color`]="{ item }">
        <v-chip :color="item.back_color" dark>
          {{ item.disp_name }}
        </v-chip>
      </template>

      <template v-slot:[`item.updatetime`]="{ item }">
        {{ getDateFormat(item.updatetime) }}
      </template>
      <template v-slot:[`item.updateuser`]="{ item }">
        {{ getUserName(item.updateuser) }}
      </template>
    </v-data-table>
    <custom-dialog v-model="selectedOpen">
      <template v-slot:title>タグ情報</template>
      <template v-slot:body>
        <v-container>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="ismobile ? 12 : 4"
              v-show="!getDispStyle(config.dispStyle).useTitleLabel"
            >
              <p class="mb-0">タグ名称</p>
            </v-col>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon
                    ? 'mdi-message-outline'
                    : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.disp_name"
                readonly
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    タグ名称
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="ismobile ? 12 : 4"
              v-show="!getDispStyle(config.dispStyle).useTitleLabel"
            >
              <p class="mb-0">背景色</p>
            </v-col>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 11
                  : 7
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon
                    ? 'mdi-message-outline'
                    : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.back_color"
                readonly
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    背景色
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col :cols="1">
              <input
                class="form-control"
                type="color"
                v-model="selectedEvent.back_color"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="ismobile ? 12 : 4"
              v-show="!getDispStyle(config.dispStyle).useTitleLabel"
            >
              <p class="mb-0">表示優先度</p>
            </v-col>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon
                    ? 'mdi-message-outline'
                    : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="selectedEvent.sort"
                readonly
                hide-details="auto"
                type="number"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    順序
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-card class="mx-1 mt-3" color="background_sub">
          <v-container class="px-6 text-body-2">
            <v-row class="pa-0">
              <v-col class="pa-0 text-subtitle-1">更新情報</v-col>
            </v-row>
            <v-row class="pa-0">
              <v-col class="pa-0" cols="2">作成者</v-col>

              <v-col class="pa-0">{{
                getUserName(selectedEvent.insertuser)
              }}</v-col>
              <v-col class="pa-0 text-right">{{
                fullDateFormat(selectedEvent.inserttime)
              }}</v-col>
            </v-row>
            <v-row><v-divider /></v-row>
            <v-row class="pa-0">
              <v-col class="pa-0" cols="2">更新者</v-col>

              <v-col class="pa-0">{{
                getUserName(selectedEvent.updateuser)
              }}</v-col>
              <v-col class="pa-0 text-right">{{
                fullDateFormat(selectedEvent.updatetime)
              }}</v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="startEditing"> 編集 </v-btn>
        <v-btn color="warning" @click="deleteData"> 削除 </v-btn>
        <v-btn color="accent" @click="selectedOpen = false"> キャンセル </v-btn>
      </template>
    </custom-dialog>
    <custom-dialog v-model="editedOpen">
      <template v-slot:title>タグ情報</template>
      <template v-slot:body>
        <v-container>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="ismobile ? 12 : 4"
              v-show="!getDispStyle(config.dispStyle).useTitleLabel"
            >
              <p class="mb-0">タグ名称</p>
            </v-col>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon
                    ? 'mdi-message-outline'
                    : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.disp_name"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    タグ名称
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="ismobile ? 12 : 4"
              v-show="!getDispStyle(config.dispStyle).useTitleLabel"
            >
              <p class="mb-0">背景色</p>
            </v-col>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 11
                  : 7
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon
                    ? 'mdi-message-outline'
                    : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.back_color"
                readonly
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    背景色
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col :cols="1">
              <input
                class="form-control"
                type="color"
                v-model="editedEvent.back_color"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="ismobile ? 12 : 4"
              v-show="!getDispStyle(config.dispStyle).useTitleLabel"
            >
              <p class="mb-0">順序</p>
            </v-col>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon
                    ? 'mdi-message-outline'
                    : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.sort"
                hide-details="auto"
                type="number"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    表示優先度
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="endEdit">登録</v-btn>
        <v-btn color="accent" @click="cancelEdit"> キャンセル </v-btn>
      </template>
    </custom-dialog>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import fileOperation from '../../../utils/fileOperation';
import token from '../../../utils/token';
import CustomDialog from '../../components/CustomDialog.vue';
import inputStyleUtils from '../../../utils/inputStyleUtils';
import dateformat from '../../functions/DateFormat';

export default {
  components: {
    CustomDialog
  },
  data: () => ({
    name: 'fagDefinitionSettings',
    path: '/tagDefinitionSettings',
    config: {},
    dense: true,
    ismobile: false,
    headers: [],
    dispmode: 0,
    search: '',
    listitem: [],
    isError: {
      reapply: false,
      file: false,
      category: false,
      route: false,
      extra: false,
      extraText: ''
    },
    selectedOpen: false,
    selectedFullScreen: false,
    selectedEvent: {},
    editedOpen: false,
    editedFullScreen: false,
    editedEvent: {},

    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    disp_list: []
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      usersList: state => state.user.usersList,
      tagDefinitions: state => state.tagDefinitions.tagDefinitions,
      userConfig: state => state.userConfig.userconfig
    }),
    outsideLine: {
      get() {
        return `${this.outsideLineElms[0]}-${this.outsideLineElms[1]}-${this.outsideLineElms[2]}`;
      }
    },
    cellphoneNumber: {
      get() {
        return `${this.cellphoneNumberElms[0]}-${this.cellphoneNumberElms[1]}-${this.cellphoneNumberElms[2]}`;
      }
    }
  },
  mixins: [fileOperation, token, inputStyleUtils],
  methods: {
    ...mapActions([
      'fetchTagDefinition',
      'updateTagDefinition',
      'insertTagDefinition',
      'deleteTagDefinition',
      'fetchUserConfig'
    ]),
    init() {
      this.dispData();
    },
    getHeaders() {
      const disp_id = {
        text: 'ID',
        value: 'id'
      };
      const disp_name = {
        text: '名前',
        value: 'disp_name'
      };
      const back_color = {
        text: '表示例',
        value: 'back_color',
        align: 'center'
      };
      const sort = {
        text: '順序',
        value: 'sort',
        align: 'end',
        width: '80px'
      };
      const updateuser = {
        text: '更新者',
        value: 'updateuser',
        width: '120px'
      };
      const updatetime = {
        text: '更新日時',
        value: 'updatetime',
        align: 'end',
        width: '150px'
      };
      return [disp_id, disp_name, back_color, sort, updateuser, updatetime];
    },
    isMainPage() {
      return this.$route.path === '/' + this.path;
    },
    dispData() {
      const p = [];
      p.push(this.fetchTagDefinition());

      Promise.all(p).then(() => {
        this.listitem = this.tagDefinitions;
      });
    },
    showEvent(eventData) {
      const data = eventData;

      this.selectedOpen = true;
      this.selectedEvent = data;
      this.editedOpen = false;
      this.editedEvent = {};
    },
    startEdit() {
      const data = {
        id: -1,
        disp_name: '',
        fore_color: '#cccccc',
        back_color: '#696969',
        sort: 1,
        insertuser: this.getUserId(),
        inserttime: new Date(),
        updateuser: this.getUserId(),
        updatetime: new Date()
      };
      this.selectedOpen = false;
      this.selectedEvent = {};
      this.editedOpen = true;
      this.editedEvent = data;
    },
    startEditing() {
      const data = JSON.parse(JSON.stringify(this.selectedEvent));
      this.selectedOpen = false;
      this.selectedEvent = {};
      this.editedEvent = data;
      this.editedOpen = true;
    },
    inputCheck() {
      return true;
    },
    async endEdit() {
      //入力チェック
      if (!this.inputCheck()) return;
      try {
        const data = {
          disp_name: this.editedEvent.disp_name,
          fore_color: this.editedEvent.fore_color,
          back_color: this.editedEvent.back_color,
          sort: this.editedEvent.sort,
          insertuser: this.getUserId(),
          inserttime: new Date(),
          updateuser: this.getUserId(),
          updatetime: new Date()
        };
        //更新処理の場合
        if (this.editedEvent.id != -1) {
          data.id = this.editedEvent.id;
          data.insertuser = this.editedEvent.insertuser;
          data.inserttime = this.editedEvent.inserttime;

          await this.updateTagDefinition(data);
        } else {
          await this.insertTagDefinition(data);
        }
        this.dispData();
        this.cancelEdit();
      } catch (err) {
        console.log(err);
      }
    },
    cancelEdit() {
      this.selectedOpen = false;
      this.editedOpen = false;
    },
    async deleteData() {
      //削除確認
      if (!window.confirm(`このタグを削除してよろしいですか？`)) return;
      try {
        const data = this.selectedEvent;
        await this.deleteTagDefinition(data);
        this.dispData();
        this.cancelEdit();
      } catch (err) {
        console.log(err);
      }
    },
    getUserName(id) {
      if (!id) return '---';
      let item = this.usersList.find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    getDateFormat(date) {
      return dateformat.autoFormat(date);
    },
    fullDateFormat(date) {
      return dateformat.fullFormat(date);
    }
  }
};
</script>

<style lang="css">
.rowBackGround {
  color: darkgray;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
