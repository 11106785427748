import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from './pages/Login';
import Jointmat from './pages/Jointmat';
import NotFound from './pages/NotFound';
import Schedule from './pages/Schedule';
import Timeout from './pages/TimeOut';
import Workflow from './pages/Workflow';
import Workflow2 from './pages/Workflow2';
import Workflow3 from './pages/Workflow3';
import AdminSettings from './pages/admin/AdminSettings.vue';
import UserSettings from './pages/userSettings/UserSettings';
import Information from './pages/Information';
import Library from './pages/Library';
import TimeCard from './pages/TimeCard';
import News from './pages/News';
import ExternalView from './layout/ExternalView';
import Mail from './pages/Mail';

Vue.use(VueRouter);

const DEFAULT_TITLE = 'Salt';
const routes = [
  {
    path: '/',
    redirect: '/jointmat',
    meta: { title: DEFAULT_TITLE }
  },
  {
    path: '/login',
    component: Login,
    meta: { title: DEFAULT_TITLE + ' ログイン', requiresAuth: false }
  },
  {
    path: '/',
    component: ExternalView,
    meta: { title: DEFAULT_TITLE, requiresAuth: true },
    children: [
      {
        path: '/jointmat',
        component: Jointmat
      },
      {
        path: '/schedule',
        component: Schedule
      },
      {
        path: '/library',
        component: Library
      },
      {
        path: '/usersettings',
        component: UserSettings
      },
      { path: '/admin/settings', component: AdminSettings },
      {
        path: '/information',
        component: Information
      },
      {
        path: '/schedule',
        component: Schedule
      },
      {
        path: '/timecard',
        component: TimeCard
      },
      {
        path: '/workflow',
        component: Workflow
      },
      {
        path: '/workflow2',
        component: Workflow2
      },
      {
        path: '/workflow3',
        component: Workflow3
      },
      {
        path: '/news',
        component: News
      },
      {
        path: '/mail',
        component: Mail
      }
    ]
  },
  {
    path: '/time-out',
    name: 'Timeout',
    component: Timeout
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

/** 遷移時のトークンチェック */
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    // トークンが無い場合
    next({ path: '/login' });
    return;
  }

  if (to.path === '/login') {
    // ログイン画面に遷移時
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }
  next();
});

router.afterEach((to, from) => {
  console.log(from);
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;
