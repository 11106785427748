import http from '../../utils/httpClient';

const state = {
  dataBackUp: []
};

const mutations = {
  setDataBackup(state, dataBackUp) {
    state.dataBackUp = dataBackUp;
  }
};

const actions = {
  async startDataBackup(context, data) {
    return await http.post(`dataBackup/`, data);
  }

  /////////////////////////////////////////////////////////////
};

export default {
  state,
  mutations,
  actions
};
