
const directorySort = {
 directorysort(data) {
    return data.sort((a,b) => {
      const pathSplitA = a.path.split('/');
      const pathSplitB = b.path.split('/');
      let i = 1;
      while(i <= pathSplitA.length && i <= pathSplitB.length){
        if(pathSplitA[i] > pathSplitB[i]){
          return 1;
        }else if(pathSplitA[i] < pathSplitB[i]){
          return -1;
        }
        i++;
      }
      if(pathSplitA.length > pathSplitB.length){
        return 1;
      }
      return -1;
    });
  }
}

export default directorySort;