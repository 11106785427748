import http from '../../utils/httpClient';

const state = {
  librarydata: []
};

const mutations = {
  setLibrarydata(state, librarydata) {
    state.librarydata = librarydata;
  }
};

const actions = {
  async fetchLibraryData(context) {
    await http.get('librarydata').then(res => {
      context.commit('setLibrarydata', res.data);
    });
  },
  async insertLibraryData(context, data) {
    await http.post('librarydata/create', data);
  },
  async updateLibraryData(context, data) {
    return await http.put(`librarydata/${data.id}`, data);
  },
  async updateLibraryDataWithFile(context, data) {
    await http.post(`librarydata/update`, data);
  },
  async deleteLibraryData(context, data) {
    await http.post(`librarydata/delete`, data);
  }
};

export default {
  state,
  mutations,
  actions
};
