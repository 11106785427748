<template>
  <div>
    <p>タイムアウト</p>
    <router-link to="/login">ログイン画面へ</router-link>
  </div>
</template>

<script>
  export default {};
</script>
