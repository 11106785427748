import http from '../../utils/httpClient';

const state = {
  libraryDirectory: []
};

const mutations = {
  setlibraryDirectory(state, libraryDirectory) {
    state.libraryDirectory = libraryDirectory;
  }
};

const actions = {
  /** libraryDirectory全取得 */
  async fetchLibraryDirectory(context) {
    await http.get('library_directory').then(res => {
      context.commit('setlibraryDirectory', res.data);
    });
  },
  /** libraryDirectory登録 */
  async insertLibraryDirectory(context, data) {
    return await http.post('library_directory/create', data);
  },
  /** libraryDirectory更新 */
  async updateLibraryDirectory(context, data) {
    return await http.post(`library_directory/update`, data);
  },
  /** libraryDirectory削除 */
  async deleteLibraryDirectory(context, data) {
    await http.post(`library_directory/delete`, data);
  }
};

export default {
  state,
  mutations,
  actions
};
