import http from '../../utils/httpClient';

const state = {
  timecard: []
};

const mutations = {
  setUserMenu(state, usermenu) {
    state.usermenu = usermenu;
  }
};

const actions = {
  async fetchUserMenu(context, data) {
    await http.get(`usermenu/${data.user_id}`).then(res => {
      context.commit('setUserMenu', res.data);
    });
  },
  async upsertTimeCardData(context, data) {
    return await http.post(`usermenu/${data[0].user_id}`, data);
  }
};

export default {
  state,
  mutations,
  actions
};
