import http from '../../utils/httpClient';

const state = {
  mailaccount: [],
};

const mutations = {
  setMailAccount(state, data) {
    state.mailaccount = data;
  },
};

const actions = {
  async fetchMailAccount(context, user_id) {
    return await http.get(`mail_account_setting/${user_id}`).then(res => {
      context.commit('setMailAccount', res.data);
    });
  },
  async updateMailAccount(context, data) {
    return await http.put(`mail_account_setting/update/${data.id}`, data);
  },
  async insertMailAccount(context, data) {
    return await http.post('mail_account_setting/create', data);
  },
  async deleteMailAccount(context, id) {
    await http.delete(`mail_account_setting/${id}`);
  }
};

export default {
  state,
  mutations,
  actions
};
