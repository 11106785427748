import http from '../../utils/httpClient';

const state = {
  pagesetting: []
};

const mutations = {
  setPageSetting(state, data) {
    state.pagesetting = data;
  }
};

const actions = {
  async fetchPageSetting(context, user_id) {
    return await http.get(`page_setting/${user_id}`).then(res => {
      context.commit('setPageSetting', res.data);
    });
  },
  async updatePageSetting(context, data) {
    return await http.put(`page_setting/${data.id}`, data);
  },
  async insertPageSetting(context, data) {
    return await http.post('page_setting', data);
  },
  async deletePageSetting(context, id) {
    await http.delete(`page_setting/${id}`);
  }
};

export default {
  state,
  mutations,
  actions
};
