import http from '../../utils/httpClient';

const state = {
  workflow2: [],
  workflowMap: [],
};

const mutations = {
  setWorkflow(state, workflow) {
    state.workflow2 = workflow;
  },
  setWorkflowMap(state, workflowMap) {
    state.workflowMap = workflowMap;
  }};

const actions = {
  async fetchWorkflow(context, data) {
    await http.get('workflow', { params: data }).then(res => {
      context.commit('setWorkflow', res.data.workflow);
      context.commit('setWorkflowMap', res.data.workflow_map);
    });
  },
  async fetchWorkflowItem(context, data) {
    return await http.get(`workflow/item/${data.id}`);
  },
  async CreateWorkflowNewIndex(context, data) {
    return await http.post('workflow/createnewindex', data);
  },
  async updateWorkflow(context, data) {
    return await http.put(`workflow/${data.id}`, data);
  },
  async updateWorkflowWithFileData(context, data) {
    await http.post(`workflow/update`, data);
  },
  async insertWorkflow(context, data) {
    await http.post('workflow', data);
  },
  async insertApplyWorkflow(context, data) {
    await http.post('workflow/apply', data);
  },
  async insertReapplyWorkflow(context, data) {
    await http.post('workflow/reapply', data);
  },
  async deleteWorkflow(context, data) {
    await http.post(`workflow/delete`, data);
  }
  /////////////////////////////////////////////////////////////
};

export default {
  state,
  mutations,
  actions
};
